import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["accept", "disabled", "multiple"]
const _hoisted_2 = { key: 0 }

import { watch } from 'vue';
import useS3Storage from '@/composables/useS3Storage'

export type InputFileS3ModelValue = string[]

interface Props {
  modelValue?: InputFileS3ModelValue
  multiple?: boolean,
  accept?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'InputFileS3',
  props: {
    modelValue: {},
    multiple: { type: Boolean },
    accept: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {


const emit = __emit

const { result, isLoading, upload } = useS3Storage()

const handleChange = async (e: Event): Promise<void> => {
  const target = e.target as HTMLInputElement
  const files = target.files
  if (files && files.length) {
    await upload(files)
  }
}

watch([result], ([result]) => {
  emit('update:modelValue', result)
}, {immediate: true})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      accept: _ctx.accept,
      disabled: _unref(isLoading),
      multiple: _ctx.multiple,
      type: "file",
      onChange: handleChange
    }, null, 40, _hoisted_1),
    (_unref(isLoading))
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Uploading files..."))
      : _createCommentVNode("", true)
  ]))
}
}

})