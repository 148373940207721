import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';

type CreateComplainData = {
  name?: string;
  email?: string;
  phone_number: string;
  parlor: number;
  description: string;
}

const createComplain = async (data: CreateComplainData): Promise<void> => {
  await axios.post(`${APIURL}/v2/roulettes/complains/`, data)
}

export {
  createComplain
}