export default {
  candidate_form: {
    interested: 'O jakou pozici máte zájem?',
    select_vacancy: 'Vyberte pozici',
    first_name: '*Jméno',
    last_name: '*Příjmení',
    phone: 'Telefonní číslo',
    email: 'E-mail',
    about_you: 'O vás (předchozí pracovní zkušenosti, úspěchy, dovednosti atd.) EN/UK',
    social_media_link: '*Odkaz na sociální sítě (Instagram/Facebook)',
    language_skill: 'Jakými jazyky mluvíte a jaká je vaše úroveň?',
    select_country: 'Vyberte zemi',
    select_city: 'Vyberte město',
    desired_salary: 'Jaký plat požadujete nebo %',
    why_we: 'Proč chcete pracovat s námi?',
    desired_work_place: 'Požadované místo práce, umístění studia',
    relocation_question: 'Jste ochotni cestovat na dlouhodobé pracovní cesty nebo se přestěhovat do jiných měst či zemí na delší dobu (více než 3 měsíce)?',
    your_services: 'Vaše služby',
    your_styles: 'Vaše styly',
    portfolio: 'Vaše portfolio (alespoň 5 fotografií!)',
    study_place: 'Kde jste studovali?',
    hygiene_certificates: 'Máte hygienické certifikáty / zdravotní knížku?',
    upload_cv: 'Vaše CV',
    thank_you: 'Děkujeme!',
    success_message: 'Obdrželi jsme vaši žádost o spolupráci. Budeme vás kontaktovat, jakmile budeme mít vhodnou nabídku.',
    file_size_error: 'Celková velikost souborů nesmí překročit 25 MB'
  },
  veancoins: {
    summ: 'Součet bonusů',
    customer_bonus: 'Bonus zákazníka',
    vip_gift: 'Dárek za VIP',
    vip_price: 'Minimální částka VIP',
    cash_back_sells: 'Cashback za prodej',
    cash_back_certificate: 'Cashback za certifikát',
    cash_back_student: 'Cashback za školení',
    cash_back_service: 'Cashback za službu',
    cash_back_money: 'Cashback za dobití zůstatku',
  },
  support: {
    problem_situation_link: 'Mám problém po sezení',
    other_problem_link: 'Mám jiný problém',
    problem_situation: {
      header: 'Problém po sezení',
      form: {
        session: 'Sezení',
        created_by_comment: 'Popište problém',
      },
    },
    complain: {
      header: 'Stížnosti a návrhy',
      form: {
        name: 'Jméno',
        phone_number: 'Telefonní číslo',
        email: 'E-Mail',
        parlor: 'Salon',
        description: 'Popište problém',
        feedback: {
          overall_quality: 'Jak hodnotíte celkovou kvalitu služeb v našem salonu?',
          professional_skills: 'Byli jste spokojeni s odbornými dovednostmi mistra?',
          staff_politeness: 'Jak zdvořilý a pozorný byl personál salonu?',
          waiting_time: 'Byli jste spokojeni s čekací dobou na svou službu?',
          staff_professionalism: 'Jak hodnotíte profesionalitu našeho personálu?',
          salon_cleanliness: 'Jak hodnotíte čistotu a pohodlí v našem salonu?',
          service_value: 'Myslíte si, že cena služeb odpovídá jejich kvalitě?',
          recommend: 'Doporučili byste náš salon svým přátelům a známým?',
        },
      },
    },
  },
  create_credit: {
    credit_target: 'Na co je úvěr',
    type_education: 'Typ vzdělání',
    type_service: 'Typ služby',
    credit_type: 'Typ úvěru',
    how_to_contact: 'Jak vás kontaktovat',
    note: 'Komentář',
  },
  items_view: {
    header: 'Produkty',
    filter_form: {
      name_placeholder: 'Název',
      category_placeholder: 'Kategorie',
      parlor_placeholder: 'Salon',
      all_categories: 'Všechny kategorie',
      all_parlors: 'Všechny salony',
    },
    load_more_button: 'Načíst více',
    not_found_message: 'Nenašlo se nic',
  },
  item_view: {
    error_message: 'Chyba při načítání dat',
    available_in_parlor: 'K dispozici ve vašem salonu',
    not_available_in_parlor: 'Není k dispozici ve vašem salonu',
    similar_items_label: 'Podobné produkty',
    log_in_to_buy: 'Přihlaste se k nákupu',
  },
  item_simple_card: {
    studio_price_label: 'Cena v ateliéru',
    shop_price_label: 'Cena v obchodě',
  },
  student_candidate: {
    form: {
      who_studing: 'Student nebo pronájem místa',
      phone_number: 'Telefonní číslo',
      first_name: 'Jméno',
      last_name: 'Příjmení',
      social_link: 'Odkaz na jakoukoli sociální síť',
      parlors: 'Studio',
      couch: 'Tattoo křeslo',
      find_out: 'Jak jste se o nás dozvěděli?',
      start_date: 'Požadované datum začátku',
      types: 'Typy sezení',
      work_permit_document: 'Pracovní povolení',
      sanitary_book: 'Sanitární kniha',
      identity_proof: 'Důkaz identity',
      work_photos: 'Fotografie prací',
      end_date: 'Požadované datum konce',
      confirmations: 'Potvrzuji, že',
      know_buy_material: 'Vím, že mohu koupit materiály ve studiu',
      customer_will_complete_agreement: 'Každý můj klient podepíše smlouvu',
      can_show_documents: 'Mohu poskytnout originální dokumenty ve studiu, pokud je to nutné',
      has_country_permission: 'Mám povolení k pobytu v zemi',
      has_san_book: 'Mám sanitární knihu',
      has_passport: 'Mám doklad totožnosti (ID)',
      conditions: 'Seznámil jsem se s podmínkami pronájmu',
    },
  },
  user: {
    referral_links: {
      booking: 'Rezervace sezení',
      student_candidate: 'Pronájem místa',
      store: 'Obchod zákazníka',
      candidates_form: 'Pozvat zaměstnance',
    },
  },
  products: {
    want_add: 'Chci přidat vybrané pečující produkty k ceně sezení',
    no_warranty: '*bez této možnosti vám nemůžeme zaručit 100% úspěšné hojení',
    button_add: 'Přidat',
    souvenir: 'Suvenýry',
    pay_vc: 'Zaplaťte VEANCOINS po sezení',
  },
  healing_questionary: {
    composable_table: {
      heading: 'Použité spotřební materiály',
      name: 'Název',
      count: 'Množství',
    },
  },
  booking: {
    more_interest: 'Ještě více zajímavostí',
    active_promo: 'Aktuální akce',
    cashback:
      'Cashback\n' +
      '          <span class="text-warning">%{bonus}%</span>\n' +
      '          za <span class="text-success">online</span> rezervaci',
    fast_booking: 'Rychlá rezervace',
    develop_sketch: 'Vývoj skici',
    fast_booking_warning:
      'Rezervace není konečná, náš manažer vás bude kontaktovat pro potvrzení času a data sezení',
    phone_annotate:
      'Aby náš prodejní tým mohl rychleji reagovat, uveďte prosím své místní kontaktní číslo. Děkujeme!',
    increased_risk: 'Pozor! Vysoce riziková relace',
    increased_risk_extended: 'Vybraný typ sezení a místo na těle mají zvýšené riziko',
    approximate_price: 'Přibližná cena',
    price_warning: 'Nejsou zahrnuty další položky',
    type: {
      info: 'Pohodlný formulář pro rezervaci sezení se všemi potřebnými informacemi',
      booking_button: 'REZERVOVAT SEZENÍ',
      booking_button_promo: 'S 15% CASHBACKEM VE VEAN COINS',
      unsure: 'Stále si nejste jistí výběrem? Pomůžeme vám!',
      consultant_help: 'Naši konzultanti vám rádi pomohou',
      consultation_button: 'KONZULTACE',
      simplified:
        'Rezervujte si sezení rychle a pohodlně! Vyberte datum, čas a službu během pár kliknutí',
    },
    color: {
      black: 'Černá',
      colored: 'Barevná',
    },
    controls: {
      next: 'DALŠÍ',
      back: 'Zpět',
      no_select: 'Nevybráno',
    },
    place_service: {
      select_studio: 'Vyberte studio',
      service: 'Služba',
      size: 'Velikost',
      location: 'Umístění',
    },
    customer_info: {
      booked_from: 'Jak jste si rezervovali schůzku?',
      login_prompt: 'Pro pokračování se prosím přihlaste na svůj účet',
      login_button: 'Přihlásit se',
      black_white_colored: 'Černobílé nebo barevné?',
      select_style: 'Vyberte styl',
      tell_more: 'Řekněte nám více o vašich preferencích tetování a sdílejte příklady (reference)',
      login_promo: 'Při přihlášení Cashback 10%',
      wish: 'Přání',
    },
    date_time: {
      choose_time: 'Vyberte čas návštěvy',
    },
    master_card: {
      master_unavailable: 'Mistr není v tuto dobu dostupný',
      master_guest_warning: 'POZOR! Mistr je host',
      rank: 'Hodnost:',
      works_count: 'Počet prací:',
      book_now: 'Zarezervovat nyní',
      select_another_time: 'Vybrat jiný čas',
    },
    success: {
      session: 'SEZENÍ',
      become_mystery_shopper: 'Staňte se tajným zákazníkem',
      add_to_calendar: 'Přidat událost do kalendáře',
      call_on_day: '*administrátor salonu vás v den sezení zavolá',
      return_to_menu: 'Vrátit se do menu',
      recommended_products: 'Doporučené produkty pro hojení',
    },
    black_friday: '50% sleva při povinné záloze',
  },
  tips: {
    placeholder: 'Vyberte, jak rozdělit spropitné',
    divide_by_all: 'Rovnoměrně',
    administrator: 'Administrátorovi',
    parlor: 'Salonu',
    master: 'Mistrovi',
  },
  new_landing: {
    count_block: {
      countries: 'Počet zemí',
      studios: 'Studii',
      awards: 'Ocenění',
      masters: 'Mistrů',
    },
    header_banner: {
      label_1: 'Neděláme z komára velblouda',
      label_2: 'děláme tetování!',
      book_now: 'Rezervace',
      consultation: 'Konzultace',
      prices: 'Ceny',
      top_works: 'Top práce',
      advantages: 'Výhody',
      change_parlor: 'Změnit salón',
    },
    course_card: {
      theory_part: 'Teoretická část:',
      practice_part: 'Praktická část:',
      advance: 'Další výhody:',
    },
    price_block: {
      actions: {
        book_now: 'Rezervace',
        calculate_cost: 'Vyhodnocení ceny',
      },
    },
    helpful_links: {
      for_masters: 'Pro mistry',
      tattoo_news: 'Tetovací novinky',
      tattoo_news_description: 'Buďte v obraze s nejdůležitějšími novinkami ze světa tetování',
      vean_tattoo_school: 'VEAN TATTOO SCHOOL',
      vean_tattoo_school_description:
        'Naši mistři jsou připraveni sdílet znalosti, tajemství, techniky',
      rent_space: 'Pronájem prostoru',
      rent_space_description:
        'Zjistěte o možnostech spolupráce při pronájmu pracovního místa ve studiích VEAN',
      tattoo_promotions: 'Propagace',
      tattoo_certificates: 'Dárkové poukazy',
      consumables: 'Spotřební materiál',
      equipment: 'Vybavení',
    },
    top_works: {
      title: 'Top díla',
      from_the_world: 'Z celého světa',
    },
    why_vean: {
      title: 'Proč VEAN',
      individual_approach: 'Individuální přístup',
      trust: 'Důvěra',
      safety_guarantee: 'Záruka bezpečnosti',
      convenient_location: 'Výhodná poloha',
      visited_studio: 'Lidí navštívilo toto studio',
    },
  },
  mystery_shopper: {
    title: 'Tajný nákupčí',
    description:
      'Tajný nákupčí je osoba, která kontroluje fungování obchodu pod záminkou obyčejného zákazníka. Hodnotí úroveň služeb v obchodě.',
    offer:
      'Pokud se rozhodnete stát se tajným nákupčím pro tuto relaci, darujeme vám mince jako dárek.',
    instructionTitle: 'Návod',
    instructionDetails:
      'Během relace budete muset hodnotit obchod a personál podle různých kritérií. Po relaci obdržíte také SMS s odkazem na dotazník, který budete muset vyplnit (pouze čestně!).',
    question: 'Chcete se stát tajným nákupčím?',
    yesButton: 'Ano',
    noButton: 'Ne, děkuji',
    successMessage: 'Budete muset hodnotit studio podle následujících kritérií:',
    criteria1: 'Telefonní a sociální média konzultace',
    criteria2: 'Konzultace v obchodě při příchodu',
    criteria3: 'Cenová politika obchodu',
    criteria4: 'Exteriér: Fasáda obchodu, cedule, umístění atd.',
    criteria5: 'Interiér obchodu, atmosféra',
    criteria6: 'Rozsah produktů, suvenýrů a léčení (výkladní skříň)',
    criteria7: 'Atmosféra: TV, hudba, personál',
    criteria8: 'Dostupnost občerstvení, zábavy, časopisů a katalogů se skicami',
    criteria9: 'Komunikace s mistrem',
    criteria10: 'Komunikace s administrátorem',
  },
  cookies: {
    text: `Používáme soubory cookie ke zlepšení vašeho prohlížení, 
      zobrazení personalizované reklamy nebo obsahu 
      a analýze našeho provozu. Kliknutím na „Přijmout“ 
      souhlasíte s používáním souborů cookie.`,
    confirm: 'Přijmout',
    more: 'Více informací',
    manage: 'Spravovat',
    config: {
      necessary: 'Nezbytné',
      preferences: 'Preferenční',
      statistics: 'Statistické',
      marketing: 'Marketingové',
    },
  },
  header_menu: {
    logout: 'Výstup',
    main: 'Domů',
    about_us: 'O nás',
    discount_page: 'Slevy',
    loyalty_page: 'Věrnostni program',
    ai_page_link: 'Generovat náčrt',
    fonts_page_link: 'Tattoo Fonts',
    quality_certificate: 'Certifikáty kvality',
    confidence: 'Zásady ochrany osobních údajů',
    complaints: 'Stížnosti a návrhy',
    public_offer_link: 'Veřejná nabídka',
    work: 'Chci pracovat ve VEAN',
    dockuments_page_link: 'Dokumenty',
    login: 'Přihlásit se',
    account: 'Osobní kancelář',
    studying: 'Chci se učit!',
    buy_certificate: 'Acheter un certificat',
    feedback: 'Zpětná vazba',
    loan: 'Plaťte na splátky!',
    services: 'Služby',
    parlor_social_links: 'Odkazy na sociální sítě a kontaktní informace pro salon',
    global_social_links: 'Globální odkazy na sociální sítě a kontaktní informace',
  },
  post: {
    welcome: 'Dobrý den. Byli jste u nás na sezení',
    no_answer_questions: 'Neodpověděli jste na následující otázky:',
    point: 'Ohodnoťte prosím kvalitu služeb a získejte VeanCoins!',
    send: 'Odeslat',
    error: 'Došlo k chybě!',
    not_found: 'Sezení nenalezeno!',
    not_call: 'Nemusíte mi volat zpět',
    thank: 'Děkujeme moc za vaše hodnocení!',
    bot: 'Za 21 dní vám z neznámého čísla zavolá náš bot, aby ověřil informace o službě a hojení.',
    certificate: 'Byl vám vygenerován promoční certifikát na %{gift_price} VeanCoins.',
    social_networks: 'Zanechte nám prosím recenzi na sociálních sítích:',
    month: 'Využijte jej během měsíce a nezapomeňte na něj upozornit správce!',
    dissatisfied: 'Nejste spokojeni s poskytnutou službou? Vaši žádost zpracujeme co nejdříve.',
    help_us_improve:
      'Pomozte nám zlepšit se odpovědí na otázky a získejte <span class="text-warning">{bonusAmount}</span> VEANCOINS!',
    session_price: 'Zaplaceno za sezení',
    session_additional: 'Zaplaceno za doplňkové nákupy',
    session_tips: 'Spropitné',
    session_total: 'Celkem',
    loyalty_help: 'Registrace do věrnostního programu je k dispozici na následující stránce. Připojte se, abyste využili speciálních nabídek a systému sbírání bonusů.',
    scores: {
      variants: {
        great: 'Vynikající',
        good: 'Dobré',
        satisfactorily: 'Uspokojivé',
        bad: 'Špatné',
        fast: 'Rychle',
        orally: 'Ústně',
        in_writing: 'Písemně',
        orally_in_writing: 'Ústně i písemně',
        yes: 'Ano',
        no: 'Ne',
        didnt_listen: 'Neslyšel/a jsem',
        not_now: 'Ne teď',
        book_by_operator: 'Rezervujte přes operátora',
        book_by_yourself: 'Rezervujte si sami s bonusy',
      },
      administrator:
        'Jak hodnotíte konzultaci administrátora studia ohledně služeb, mistrů a péče?',
      recommendations: 'Byly vám poskytnuty jasné doporučení ohledně péče?',
      master_contact: 'Poskytl vám mistr své kontaktní údaje?',
      holl: 'Jak hodnotíte pohodlí na recepci (prostředí, čistota, pohodlí)?',
      price: 'Jak hodnotíte ceny služeb ve studiu?',
      callback: 'Jak rychle jste dostali odpověď na žádost o rezervaci?',
      master: 'Jak hodnotíte mistra ve studiu?',
      care: 'Byly vám nabídnuty profesionální přípravky na péči nebo ozdoby?',
      buy_other: 'Zakoupili jste ozdoby nebo přípravky na péči?',
      need_contact: 'Přejete si, abychom vás kontaktovali?',
      loyalty_program: 'Účastníte se již věrnostního programu?',
      sketch: 'Bylo mi nabídnuto objednat individuální návrh/projekt:',
      souvenir: 'Bylo mi nabídnuto zakoupit suvenýry nebo reklamní předměty:',
      repeat_record: 'Chcete si rezervovat další sezení?',
      price_correct: 'Zaplatili jste uvedenou celkovou částku?',
      price_correct_amount: 'Zadejte částku, kterou jste zaplatili',
      tips: 'Dali jste spropitné?',
      studio: 'Jak byste ohodnotili práci studia?',
      master_note: 'Popište problém',
      record_date: 'Datum, kdy vám bude pohodlné být kontaktováni',
      record_time: 'Čas, kdy vám bude pohodlné být kontaktováni',
      record_comment: 'Váš komentář k zápisu',
    },
    scores_mystery_shopper: {
      instagram: 'Hodnocení našeho INSTAGRAMU',
      site: 'Hodnocení webu: vean-tattoo.%{locale}',
      phone_consultation: 'Telefonická konzultace a na sociálních sítích',
      consultation: 'Konzultace ve studiu po příchodu',
      price: 'Váš názor na cenovou politiku studia',
      exterior: 'Exteriér: fasáda studia, nápisy, lokalita atd.',
      interior: 'Interiér studia, prostředí',
      assortment: 'Sortiment produktů (suvenýry, přípravky na hojení)',
      atmosphere: 'Atmosféra: TV, hudba, kolektiv',
      leisure: 'Občerstvení, volný čas, časopisy a katalogy návrhů',
      master: 'Komunikace s mistrem',
      admin: 'Komunikace s administrátorem',
      master_one_place: 'Pracuje mistr pouze v tomto studiu? (ano/ne)',
      master_contact: 'Poskytl mistr kontaktní údaje na vyžádání? (ano/ne)',
      care: 'Byly vám nabídnuty přípravky na hojení, suvenýry? Dostal/a jste malý dárek (magnetku, samolepku nebo certifikát)? (ano/ne)',
      conclusion: 'Závěrečné hodnocení auditu',
    },
    inputs: {
      cost: 'Uveďte částku, kterou jste zaplatili za službu',
      cost_other: 'Kolik jste utratili za ozdoby nebo přípravky na péči?',
      review: 'Máte návrhy na zlepšení studia?',
      photo: 'Fotografie salónu',
    },
    inputs_mystery_shopper: {
      recommendation: 'Vaše doporučení pro nás',
      why_vean: 'Proč jste si vybrali VEAN?',
    },
  },
  header: {
    online_appointment: 'Online rezervace',
    post_session: 'Kontrola kvality',
    get_price: 'Konzultace',
    consultation: '(Konzultace)',
    order_consultation: 'Objednat konzultaci',
  },
  index: {
    parlor: 'Studio',
    master: 'Specialista',
    session_type: 'Služba',
    session_location: 'Místo služby',
    datetime: 'Datum a čas',
    create_appointment: 'Objednat se',
    bonus: '* BONUS %{customer_bonus} VeanCoins ZA SAMOREGISTRACI',
    sketch: 'Návrh (volitelné)',
  },
  session_types: {
    tattoo: 'Tetování',
    tatouage: 'Permanentní make-up',
    piercing: 'Piercing',
    stroke: 'Odstranění',
    training: 'Kurzy',
    training_tattoo: 'Kurzy tetování',
    training_piercing: 'Kurzy piercingu',
    training_tattouage: 'Kurzy permanentního make-upu',
    training_udder: 'Kurzy odstranění',
  },
  price_form: {
    client_name: 'Jméno',
    client_phone: 'Telefon',
    client_comm: 'Vaše město',
    sketch_desc: 'Přidat náčrt (volitelné)',
    sketch_button: 'Vyberte soubor',
    send: 'Poslat',
    or: 'Nebo',
    country: 'Země',
    city: 'Město',
    comment: 'Komentář',
    val_error: 'Zkontrolujte zadané údaje!',
    ths_message: 'Děkuji! Brzy Vás kontaktujeme!',
    go_back: 'Zpět',
    file_size: 'Velikost souboru nesmí být větší než 5 MB!',
    apr_time: 'Approximate session time',
  },
  selectors: {
    parlor: {
      placeholder: 'Hledat podle názvu salonu...',
    },
    master: {
      placeholder: 'Hledat podle jména, služby...',
    },
    select_type: {
      select: 'Vybrat',
      next: 'Dále',
      no_price: 'Dohodou',
      ad: 'od',
      da: 'do',
      selected: 'selected',
      info: 'Cena se může měnit',
    },
    datetime: {
      select_date: 'Vyberte termín, o který máte zájem',
      no_active_times: 'Na toto datum nejsou volná místa. Vyberte si prosím jiné!',
    },
  },
  verify: {
    verify_account: 'Ověřit účet',
    type: 'Způsob ověření',
    check_code: 'Kontrola kódu',
    status: 'Stav',
    input_phone: 'Vložte telefonní číslo',
    email_help: 'Můžete také použít e-mail k ověření',
    sms_sent: 'Kód vám byl zaslán prostřednictvím SMS, zadejte jej níže',
    resend_sms_after: 'Opětovné odeslání dostupné za %{seconds} sekund',
    resend_sms: 'Poslat znovu',
    buttons: {
      back: 'Zpět',
      next: 'Dál',
      create_appointment: 'Přihlásit se',
    },
  },
  success: {
    h1: 'Děkuji! Záznam byl úspěšně vytvořen!',
    h2: 'Po schválení registrace administrátorem vám bude zaslána SMS.',
    mystery: 'Stát se Mystery Shopperem',
  },
  about: {
    header: 'Informace o Vás',
    birth_info:
      'Poskytnutím Vašeho data narození ujišťujeme se, že Váš věk je vhodní pro tetování či piercing, také je to potřebné pro slevu k narozeninám :)',
    inputs: {
      name: 'Jméno a příjmení',
      find_out: 'Jak jste se dozvěděli',
      birth_date: 'Datum narození',
    },
    buttons: {
      skip: 'Vynechat',
      back: 'Zpět',
      next: 'Dál',
    },
  },
  footer: {
    header: 'Sleduj nás na sociálních sítích aby ti neutekly žádné novinky',
    site_navigation: 'Navigace',
    interest: 'Zajímavé',
    other: 'Ostatní',
  },
  subscribe_modal: {
    header: 'Přihlásit se k odběru novinek za bonus',
    submit: 'Odeslat',
    wrong_data: 'Zkontrolujte správnost zadaných údajů!',
    wrong_email: 'Nesprávně zadaný e-mail!',
    thanks: 'Děkujeme za odběr!',
    error: 'Došlo k chybě!',
  },
  chat_bot: {
    call: 'Zavolat',
    online_record: 'Online-objednání',
    online_consultation: 'Online-konzultace',
    need_help: 'Potřebujete pomoci se skici?',
    order_sketch: 'Objednání skice',
    dress_sketch: 'Zkouška skice',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Problémové situace',
      id: 'Číslo',
      status: 'Stav',
    },
    reset_password: {
      title: 'Zapomněli jste heslo',
      submit: 'odeslat',
      forgot_password: 'Zapomněli jste heslo',
      phone: 'Zadejte telefonní číslo',
      success_message: 'Do vaší pošty/telefonu bylo odesláno dočasné heslo',
    },
    registration: {
      name: 'Jméno',
      surname: 'Příjmení',
      registered: 'Registrace',
      congratulations: 'Gratulujeme!',
      successfully_registered: 'Úspěšně jste se zaregistrovali!',
      log_in: 'Přihlásit se',
    },
    options: {
      no_interest_in_promotions: 'Nemám zájem o propagace a bonusy',
      dislike_frequent_sms: 'Nemám rád/a časté SMS zprávy',
      already_have_tattoo: 'Mám již tetování a v nejbližší době nemám v plánu dělat další',
      other: 'Jiné',
    },
    login_page: {
      log_in_page: 'Přihlášení do osobního účtu',
      number_missing: 'Nemáte číslo?',
      register: 'Registrovat se!',
      steps: {
        sign_up: 'Zaregistrujte se do věrnostního programu nebo PŘIHLASTE SE',
        check_coins: 'Zkontrolujte své VeanCoin zaregistrováním se do věrnostního programu',
        get_coins: 'Získejte své VeanCoins na konci každé relace',
        accumulate: 'Sbírejte a hromadte VeanCoin ve svém profilu',
        book_session: 'Zarezervujte si sezení a přijďte do Vean na tetování',
        exchange_coins: 'Vyměňte VeanCoins a získejte slevy',
      },
    },
    find_customer: {
      continue: 'Pokračovat',
      not_found: 'Zákazník nenalezen!',
      select_type: 'Vyberte typ přihlášení',
      no_password: 'Není nastaveno heslo!',
      found: 'Nalezeno!',
      check: 'Kontrola...',
      invalid_phone_or_password: 'Neplatné telefonní číslo nebo heslo',
      error_login: 'Chyba při přihlášení',
      phone: 'Telefon',
      temporary_password_message: 'Používáte dočasné heslo',
      permanent_password_message: 'Používáte trvalé heslo',
    },
    product_card: {
      availability: 'Dostupnost ve studiích:',
      unavailable: 'Není k dispozici',
    },
    store_body: {
      full_points_products: 'Produkty, které lze zakoupit pomocí VeanCoins',
      half_points_products: 'Produkty, které lze zakoupit s 50% slevou',
      no_products: 'Produkty nejsou k dispozici',
    },
    description_block: {
      loyalty_system: 'Věrnostní systém VEAN',
      show_more: 'Ukázat víc',
    },
    profile_card: {
      my_sketches: 'Moje náčrtky',
      share_link: 'Sdílejte tento odkaz',
      tips: 'Spropitné',
      send_certificate: 'Odeslat certifikát',
      vip: 'VIP',
      not_vip: 'Stát se VIP',
      veancoins: 'VeanCoins',
      balance_buy: 'Peníze',
      active_certificates: 'Aktivované mince',
      balance: 'Zůstatek',
      sessions: 'Sezení',
      points_alltime: 'Celkem získané mince',
      available_certificates: 'Dostupné certifikáty',
      certificates: 'Peněžní certifikáty',
      bought_certificates: 'Aktivované VeanCoins',
      certificate_number: 'Číslo certifikátu',
      available_for: 'Platný do:',
      no_active_certificates: 'Žádné peněžní certifikáty',
      no_active_bought_certificates: 'Žádné aktivní VeanCoins',
      coming_sessions: 'Nadcházející sezení',
      complete_agreement: 'Vyplnit dohodu',
      date: 'Datum:',
      you_was: 'Byli jste na %{sessions} sezeních',
      verify_account_status: 'Stav účtu',
      security_percent: 'Bezpečnost:',
      edit: 'Editace',
      not_email: 'Chcete-li odeslat certifikát, musíte vyplnit e-mail',
      exist_application: 'existuje aplikace',
      need_exist_application: 'žádná aplikace',
      need_confirm_account: 'Potřeba potvrdit účet',
      verified: 'Ověřeno',
      choose_payment_method: 'Vybrat způsob platby',
      parlor: 'Salon',
      sign_up_online: 'Přihlásit se na sezení',
      mystery_shopper: 'Tajný nákupčí',
      sign_up_secret_buyer: 'Zaregistrujte se u nás na seanci a staňte se tajným nákupčím',
      pay: 'Zaplatit',
      prepayment: 'Záloha',
      questionnaire: 'Dotazník',
      agreement: 'Dohoda',
      fill_required_information: 'Je třeba vyplnit informace',
      buy: 'Doplňte zůstatek',
      archive: 'Archiv',
      support_service: 'Zákaznická podpora',
      balance_buy_history: 'Historie zakoupených VeanCoins',
      balance_history: 'Historie získaných VeanCoins',
      buy_history: 'Historie nákupů',
      сost: 'Náklady',
      payment_link: 'Platební odkaz',
      attributes: 'Atributy',
      invite_friend: 'Pozvat přítele',
      phone_or_email: 'Zadejte telefonní číslo nebo email',
      report_problem: 'Nahlásit problém',
      note: 'Napište komentář',
      tooltip_coins:
        'musíte aktivovat své nahromaděné mince pro vybranou službu nebo pro nákup zboží, aby nedošlo k jejich ztrátě během jednoho roku',
      tooltip_certificate:
        'počet vašich zakoupených nebo darovaných peněžních certifikátů, které lze použít během 2 měsíců',
      tooltip_сonfirmed: 'V úpravách změňte «ФИО или username» na «Jméno a Příjmení»',
      tooltip_missing:
        'Přidejte informace o sobě (jméno a příjmení, datum narození, odkaz na vaše sociální sítě, jak jste se o nás dozvěděli, telefonní číslo a e-mail pro potvrzení identity)',
      tooltip_verified:
        'Musíte potvrdit svůj účet pomocí telefonního čísla nebo e-mailu, aby ochránili vaše získané a nahromaděné VeanCoins.',
      tooltip_vip:
        'VIP client is a status that can be obtained by any client who spends more than %{amount} on services. VIP status provides certain benefits to its owner, such as fast priority booking for sessions out of turn, access to unique goods and services. Upon reaching VIP status, the client immediately receives %{cashback} VeanCoins credited to their account.',
      earn_veancoins: 'Vydělej veancoins',
    },
    profile_edit: {
      additional_information: 'Další informace o VeanCoins',
      parlors: 'Salon (který je bližší nebo hlavní)',
      client_card: 'Karta klienta',
      full_name: 'Celé jméno nebo uživatelské jméno',
      birth_date: 'Datum narození (pro gratulace)',
      social_media_link: 'Odkaz na sociální média',
      find_out: 'Jak jste se dozvěděli',
      or: 'nebo',
      phone_placeholder: 'Telefon',
      save: 'Uložit',
      password_tooltip:
        'Vytvořte heslo, abyste v budoucnu mohli přistupovat k svému profilu bez pomoci SMS nebo e-mailů. Tím také chráníte svá osobní data.',
      password: 'Heslo',
      repeat_password: 'Opakujte heslo',
      email_taken: 'Tento e-mail je již obsazen!',
      phone_taken: 'Toto telefonní číslo je již obsazeno!',
      simple_password: 'Příliš jednoduché heslo!',
      invalid_password: 'Neplatné heslo!',
      password_for_application: 'Heslo pro aplikaci (nepovinné)',
      congratulate: 'Gratulujeme!',
      successfully_registered: 'Úspěšně zaregistrováno!',
      need_confirm_account: 'Je třeba ověřit účet',
      yes: 'Ano',
      no: 'Ne',
      phone_confirmed: 'Telefon potvrzen',
      email_confirmed: 'E-mail potvrzen',
      change_password: 'Změnit heslo',
      old_password: 'Staré heslo',
      new_password: 'Nové heslo',
      confirm_password: 'Opakovat heslo',
      invalid_phone_or_password: 'Neplatné telefonní číslo nebo heslo',
      error_login: 'Chyba při přihlášení',
      years_old: 'Věk',
      up_18_years_old: '16-21',
      for18_24: '21-27',
      for25: '27-35',
      for35_45: '35-45',
      up_45_years_old: '45+',
      gender: 'Pohlaví',
      man: 'Muž',
      woman: 'Žena',
      gender_hidden: 'Preferuji neuvádět',
      language: 'Jazyk',
      preferred_style_status: 'Preferované styly',
      classic: 'Klasika / Realismus',
      freak: 'Freak / Trash / Anime',
      fashion: 'Móda / Umění / Minimalismus',
      rock: 'Rock / Tvrdý',
      pop: 'Pop / Masa',
      accommodation_status: 'Status ubytování',
      local: 'Místní',
      tourist: 'Turista',
      guest: 'Host',
      lifestyle_status: 'Životní styl',
      extreme: 'Extrémní',
      dance: 'Tanec',
      anime: 'Anime',
      game: 'Hra',
      music: 'Hudba',
      lgbt: 'LGBT',
      art: 'Umění / Moderna',
      family: 'Rodina / Láska',
      beauty: 'Krása',
      sexuality: 'Sexualita',
      masculinity: 'Mužnost',
      tattoo_count_status: 'Počet tetování',
      pierce_count_status: 'Počet piercingů',
      one: 'Jeden',
      two: 'Dva nebo více',
      inc: 'Velmi mnoho',
      change_information: 'Uložit informace',
    },
    verification: {
      sms: 'Potvrdit pomocí SMS',
      email: 'Potvrdit pomocí e-mailu',
      google: 'Ověřit přes Google',
      send_code: 'Poslat kód',
      code: 'Ověřovací kód',
    },
    loyalty: {
      submit_button: 'Odeslat',
      bonus_instruction:
        'Vyberte jednu z následujících možností, přidejte důkazy (screenshoty, fotografie atd.) a získejte bonus ve výši',
      bonus_amount: 'VeanCoins',
      review_option_1: 'Napsat podrobnou recenzi v profilu mistra na webu',
      review_option_2: 'Ohodnotit studio nebo mistra na webu',
      review_option_3: 'Ohodnotit studio a napsat recenzi na mapě',
      review_option_4: 'Sdílet naše studio na sociálních sítích',
      return:
        'Pokud fotka, kterou jste poslali, nepatří do žádné z kategorií, pro které udělujeme bonusy, budou zrušeny získané bonusy při nákupu dárkového certifikátu.',
      evidence_label: 'Důkazy',
    },
    buy_certificate: {
      payment_type_title: 'Typ nákupu',
      payment_type_subtitle: 'Co byste chtěli udělat',
      buy_balance: 'Nakoupit kredit',
      buy_certificate: 'Koupit certifikát',
      amount_title: 'Částka',
      amount_subtitle: 'Zadejte částku',
      payment_method_title: 'Způsob platby',
      payment_method_subtitle: 'Vyberte způsob platby',
      payment_title: 'Platba',
      forming_payment: 'Formování platby...',
      pay: 'Zaplatit',
      continue: 'Pokračovat',
      back: 'Zpět',
      submit_to_email: 'Odeslat certifikát na e-mail',
      get_vean_card: 'Získejte kartu VEAN ve studiu',
      vean_card_description:
        'VEAN CARD je vaše karta, na které se automaticky aktivují certifikáty VEAN TATTOO, což zajišťuje bezpečnost a pohodlí. Všechny vaše dárky a slevy máte vždy po ruce a používání karty je co nejjednodušší a nejvýhodnější. Získejte svou kartu ve studiích VEAN TATTOO a objevte všechny výhody již dnes!',
      total_balance: 'Váhy',
      certificate: 'Certifikát',
      customer_balance: 'Zůstatek zákazníka',
      certificate_type: 'Typ certifikátu',
      promotion_certificate: 'Akční certifikát',
      buy_for_myself: 'Koupit pro sebe',
      promotion_certificate_message:
        'Fyzické certifikáty budou vydávány do 31. prosince 2024. Od 1. ledna 2025 budou certifikáty převedeny na body a zobrazeny v osobním účtu uživatele.',
    },
  },
  parlor_page: {
    header: {
      parlors_count: 'Více než 160 salonů',
      clients_count: 'Více než 1 500 000 klientů',
      largest_company: 'Největší síť tetovacích studií na světě',
    },
    master_block: {
      our_masters: 'Naši umělci',
    },
    master_card: {
      works_count: 'Počet prací:',
    },
    no_admin_block: {
      leisure: 'Volný čas',
      session_record: 'Objednání na sezení',
      about_company: 'O společnosti',
      care: 'Péče',
      quality_certificates: 'Certifikáty kvality',
      public_buyout_offer: 'Veřejná nabídka',
      complaints_offers: 'Stížnosti a návrhy',
      loyalty_system: 'Věrnostní systém',
      discount_page: 'Slevy',
    },
    peculiarities_block: {
      about_studio: 'O tomto studiu',
      clients: 'Klienti',
      sessions: 'Sezení',
      studio_hours: 'Pracovní doba:',
      studio_phone: 'Telefon studia:',
      wifi: 'Wi-fi:',
      password: 'Heslo:',
      from_to: 'až %{time_start} do %{time_end}',
    },
    photos_block: {
      photos: 'Foto salonu',
    },
    top_works: 'Práce',
    care_important: 'To nejdůležitější v piercingu a tetování je hojení a péče.',
    cabinet: 'Osobní skříňka',
    complete_agreemnent: 'Vyplňte smlouvu',
    is_blanket: 'Je zde deka, abyste se mohli zahřát',
    is_water: 'Jsou k dispozici horké nápoje',
    is_hot_drinks: 'Jsou tu sladkosti',
    is_treats: 'Jsou k dispozici studené nápoje',
  },
  promotion: {
    submit: 'Odeslat',
    warning: 'Pozor! Zkontrolujte správnost zadaných údajů!',
    name: 'Jméno / Přezdívka',
    required: 'Toto pole je povinné!',
    max_value: 'Nesmí být více než 100 znaků!',
    phone: 'Telefon',
    social_link: 'Odkaz na sociální síť',
    wrong_format: 'Nesprávný formát!',
    terms: 'Oznamuji, že jsem se seznámil s politikou ochrany osobních údajů',
    know_bonus: 'Jsem si vědom toho, že mi budou přiděleny bonusové body',
    error: 'Došlo k chybě',
    thanks: 'Děkujeme!',
    bonus: 'Bylo vám přiděleno %{gift_price} bodů',
    do_not_disturb: 'Nerušit (vypnout SMS a hovory od VeanTattoo)',
    get_promo: 'Chci dostávat informace o slevách a akcích',
    first_time_veancoins_notification:
      'Pro použití VeanCoins poprvé je nutné kontaktovat správce studia. VeanCoins a Peníze mají různá pravidla použití',
  },
  another_studio_drawler: {
    another: 'Další studia',
  },
  landing: {
    best_studio: 'Nejlepší tetovací studia tvého města',
    vean_wide: 'SÍŤ VEAN TATTOO',
    studio_count: 'Více než 160 tetovacích studií',
    quality: 'Garance kvality a bezpečí',
    get_price: 'ZJISTIT CENU',
    contact_us: 'Kontaktujte nás',
    advantage: 'Výhody',
    top_works: 'Top prací',
    prices: 'Ceny', //долджен быиь
    studio_photo: 'Foto studia',
    prices_services: 'Ceny a služby',
    about: 'O nás',
    loyalty_system: 'Program loajality',
    why_vean: 'Proč VEAN',
    visit_count: 'Toto studio navštívilo ... lidí', //todo: сделаьб человечески
    description:
      'Síť tetovacích studií VeaAn Tattoo je k dnešnímu dni největší na světě: máme více než 160 studií ve 14 zemích Evropy. Pozvedli jsme tuto sféru na zcela novou, vyšší úroveň standardů. Specializace sítě je dostatečně široká, nicméně naší hlavní oblastí je tetovací a piercingový průmysl. Klíčovými principy společnosti VeaAn Tattoo je špičková odbornost a dostupnost: usilujeme o to, aby se každý klient cítil komfortně v každé fázi procesu, proto jsou naši specialisté připraveni na veškeré vaše dotazy on-line. Reputace a orientace na klienta: každý klient představuje možnost vytvořit unikátní umělecké dílo na jeho kůži. Ceníme si vašeho času a snažíme se o to, abyste ho strávili v našem studiu s dobrou náladou a fantastickým tetováním. Staráme se o reputaci a ceníme každého klienta naší sítě. Hodnota a dostupnost: Nabízíme ideální poměr ceny a kvality. Naše služby jsou dostupné široké veřejnosti, ale přitom tak nečiníme na úkor kvality našich služeb. Certifikované a kvalitní vybavení: naše materiály odpovídají vysokým standardům bezpečnosti a kvality. Garantujeme, že naši klienti dostávají jen to nejlepší. Vysoce kvalifikovaní specialisti a široký výběr služeb: ve studiích VeAn Tattoo pracují pouze diplomovaní specialisté, kteří jsou ceněni a respektování po celém světě. Nabízíme všechny existující styly a techniky tetování, zdarma vypracujeme individuální návrh podle vašich požadavků. Sledujte všechny naše oficiální stránky na sociálních sítích. Díky tomu bude mít přehled o speciálních nabídkách, akcích, soutěžích, pracích tatérů, posledních událostech a novinkách společnosti VeAn Tattoo.',
    advantages: {},
    our_masters: 'NAŠI MISTŘI', //doljen bit
    from: 'od',
    no_price: 'Dohodou',
    form: {
      get_price_consultation: 'Zjistit cenu / konzultace',
      name: 'Jméno',
      phone: 'Telefon',
      place: 'Vaše město',
      subscribe_news: 'Odběr novinek za vyhodné bonusy',
    },
  },
  form_messages: {
    submit: 'Odeslat',
    warning: 'Pozor! Zkontrolujte správnost uvedených údajů!',
    required: 'Povinné pole!',
    length: 'Ne více než %{length} písmen!',
    file_length: 'Ne více než %{length} souborů!',
    wrong_format: 'Špatný formát!',
    error: 'Došlo k chybě',
    thanks: 'Děkujeme!',
    required_label: 'Povinné',
    not_required_label: 'Nepovinné',
  },
  training: {
    training: 'ŠKOLENÍ',
    tattoo_school: 'ŠKOLA TETOVÁNÍ',
    biggest_school: 'největší síť tetovacích studií na světě',
    ne_otkladivaj: 'Neodkládej<br/>sebe na později',
    learn: 'Nauč se tetovat<br/>a staň se top mistrem',
    reviews: 'Recenze absolventů',
    record: 'Objednat se',
    video_text: 'Jak pomůžete,<br/>čemu naučíte?',
    ded: {
      consultation: 'konzultace',
      get_more: 'Zjistěte více informací',
    },
    header_bar: {
      main: 'Hlavní',
      get: 'Co získáte',
      program: 'Program kurzu',
      about: 'O nás',
      reviews: 'Recenze',
    },
    our_course: {
      head: 'Naše<br/>kurzy',
      description_1: 'to je místo, kde vždy získáte odpověď na otázku a naučíte se něčemu novému',
      description_2: 'A i když nemáte umělecké vzdělání - výsledek se nedá dlouho čekat',
      label: '[ u nás je teplo, útulno a vzrušující ]',
      description_3:
        'Milujeme naše studenty a snažíme se udělat vše pro to, aby byl proces učení co nejpohodlnější',
    },
    banner: {
      we_teach: 'Naučíme Tě Tetovat',
      good_make: 'UMÍME TETOVAT SKVĚLE',
      our_masters: 'NAŠI MISTŘI JSOU PŘIPRAVENI SDÍLET ZNALOSTI, TAJEMSTVÍ, TECHNIKY',
      learn_too: 'A naučíme i tebe',
    },
    modal: {
      thanks: 'Děkujeme!',
      error: 'Došlo k chybě!',
      complete: 'Vyplňte formulář a my vám zavoláme',
      full_name: 'Celé jméno',
      phone: 'Telefonní číslo',
      submit: 'Odeslat',
      cancel: 'Zrušit',
    },
    paragraph: {
      header_1: 'Naučíme',
      content_1: 'Neděláme výuku hromadně',
      header_2: 'umění kreslit',
      content_2: 'Všemu naučíme - neznání uměleckého vzdělání není problémem',
      header_3: 'Certifikát',
      content_3:
        'Po dokončení kurzu obdržíte certifikát mezinárodního standardu. Schváleno Global Tattoo ACC',
      header_4: 'Zaměstnáme',
      content_4:
        'OMG, to myslíte vážně? Ano, nejlepší absolventi se mohou stát součástí našeho týmu a pracovat kdekoliv v Evropě',
      header_5: 'Smlouva',
      content_5: 'Nepodnikáme na "čestné slovo". Vše je oficiální a pod smlouvou',
      no_sleep: {
        header: 'ne prospěte',
        rect_1: 'Jak?',
        rect_2: 'ŽIVOT',
        main_label: 'Začněte se učit hned teď',
        label_1: '1000+ absolventů',
        label_2: '100% dokončilo školení',
        label_3: '80% se umístilo v práci',
        label_4: '300+ partnerů v tetovacím průmyslu',
      },
    },
    principies: {
      head: 'Záruka',
      label_1: '1. Kultura',
      label_2: '2. Kvalita',
      label_3: '3. Metody',
      heading: 'Vše je postaveno na principech',
      description_1:
        'Před 11 lety jsme se rozhodli začít rozvíjet kulturu tetování, ne jako všichni, po svém - na základě znalostí a zkušeností',
      description_2:
        'Dnes jsme vyškolili 1000+ skvělých tatérů. 80% se stalo součástí našeho týmu. S jistotou můžeme říci, že se umění tetování může naučit kdokoli',
      description_3:
        'Jde o metodu, kterou používáme, kterou neustále zdokonalujeme společně s týmem, umělci a našimi absolventy, kteří pravidelně poskytují zpětnou vazbu',
    },
    packages: {
      head: 'Kurzovní programy',
      from: 'od',
      plus: 'Plusy',
    },
    facilities: {
      head: 'Možnosti a výhody',
      subhead: 'Vyberte si naše kurzy a pocítíte kouzlo tetovacího průmyslu',
      card: {
        head_1: 'Každý náš absolvent obdrží certifikát mezinárodního vzoru',
        label_1: '- váš lístek do světa možností',
        head_2: 'Doporučení a podpora od skupiny profesionálních mistrů',
        label_2: 'Váš úspěch je naši hrdostí!',
        head_3: 'Komunita tetování, získávejte rady a podporu od našich expertů',
        label_3: 'Vaš nový život ve světě tetování začíná právě teď!',
      },
    },
    review: {
      head_1: 'Náhodné práce mistrů',
      head_2: 'Recenze',
      comment_1:
        'Školení tetování v této škole předčilo všechna má očekávání! Učitelé jsou profesionálové ve svém oboru, každá fáze výuky je důkladně probrána, atmosféra je skvělá. Nyní mohu s jistotou realizovat své kreativní nápady na kůži klientů!',
      comment_2:
        'Děkuji škole za tak kvalitní výuku tetování! Bylo to trochu děsivé vrhnout se do tohoto oboru, ale díky pozorným a trpělivým učitelům jsem se cítil jistě. Nyní mé práce získávají uznání a obdiv!',
      comment_3:
        'Byla to neuvěřitelná cesta do světa tetování! Škola poskytuje veškeré potřebné znalosti a dovednosti, aby se z vás stal skutečný profesionál. Jsem vděčný za podporu a inspiraci, kterou jsem zde obdržel',
    },
  },
  questionary: {
    first: {
      heading: 'SOUHLAS S PROCEDURAMI VE STUDIU TETOVÁNÍ VEAN',
      textbox:
        'Pokud je klient mladší 18 let, souhlas musí být vyplněn rodiči nebo zákonnými zástupci (V Britské Kolumbii, Kanada, je za nezletilého považován člověk mladší 19 let. Pokud vám je méně než 19 let, vyberte prosím DRUHOU možnost)',
      has_18: 'ANO, Je mi 18 let nebo více',
      no_18: 'NE, Jsem mladší 18 let',
    },
    second: {
      warning: 'Pozor! V den sezení je nutné přijít s opatrovníky!',
      name: 'Celé jméno',
      birthdate: 'Datum narození',
      address: 'Adresa trvalého bydliště',
      email: 'E-mail',
      sex: 'Pohlaví',
      heading: 'PROVÁDĚNÁ PROCEDURA',
      man: 'Muž',
      woman: 'Žena',
      other: 'Nespecifikováno',
      parent_data: 'Data jednoho z rodičů:',
      child_data: 'Data neplnoletého:',
      child_field_placeholder: 'Syn, dcera, atd.',
      child_field_label: 'Pro koho se provádí postup',
      confirm:
        'Já, jako zákonný zástupce nezletilého, uděluji svůj souhlas s provedením následujícího postupu svému dítěti.',
    },
    third: {
      heading: 'VŠE, CO POTŘEBUJETE VĚDĚT O DOBĚ HOJENÍ:',
    },
    fourth: {
      nothing: 'Žádná z výše uvedených',
      has_diseases: 'Máte nějaké nemoci?',
      has_contraindications: 'Máte nějaké kontraindikace?',
      heading1:
        'POKUD MÁTE NĚKTERÉ Z NÁSLEDUJÍCÍCH ONEMOCNĚNÍ, MUSÍME TO VĚDĚT, PROSÍM, OZNAČTE PŘÍSLUŠNÉ BODY:',
      heading2: 'POKUD JSOU KONTRAINDIKACE, POSKYTNĚTE PROSÍM PŘÍSLUŠNÉ INFORMACE:',
    },
    fifth: {
      heading: 'ZAKÁZÁNO PO PROCEDUŘE:',
    },
    sixth: {
      heading: 'KDE JSTE SE O NÁS DOZVĚDĚLI:',
      label:
        'Po 21 dnech vás může kontaktovat jeden z našich zaměstnanců v rámci kontroly kvality poskytovaných služeb. Kromě toho po sezení obdržíte SMS oznámení a je možné, že náš automatizovaný robot provede telefonát. Ve svém osobním účtu se můžete odhlásit z notifikačních služeb prostřednictvím telefonátů a SMS a také můžete odstranit všechna svá data a fotografie po 21 dnech od data služby.',
    },
    seventh: {
      heading: 'PROHLÁŠENÍ A SOUHLAS',
    },
    signature: {
      heading:
        'Já, podepsaný (%{name}), tímto souhlasím s dnešní procedurou s mou účastí\n' +
        '%{session_type} na mém těle a souhlasím s podmínkami obsaženými v tomto dokumentu.',
    },
    next: 'Další',
    submit: 'Odeslat',
    no_responsibility: 'Studio nenese odpovědnost za toto sezení',
    acquaint: 'Seznámil jsem se s poskytnutými informacemi',
    acquaint_2:
      'Chápu, že Vean Tattoo nenese odpovědnost za proces hojení.\n' +
      'V případě opravy výše uvedeného tetování nebo piercingu bude cena opravy činit 30 % z ceny samotné služby.\n' +
      'Pokud nejste spokojeni s výsledkem opravy, v tomto případě se peníze za službu nevracejí, protože výše zmíněné oblasti nejsou pro provádění samotné služby příznivé.',
  },
  choices: {
    problem_situation: {
      no_solution: 'Bez řešení',
      in_check: 'Ve zkoušení',
      problem_solved: 'Problém vyřešen',
      wait: 'Čekání',
      small: 'Malá situace',
      unanswered_check: 'Kontrola bez odpovědi',
    },
  },
  master_page: {
    card: {
      active: 'Aktivní',
      not_active: 'Neaktivní',
      master_since: 'Mistr VEAN od',
      services: 'Služby',
      styles: 'Styly',
      book: 'Rezervovat',
    },
    information: {
      top_works: 'Nejlepší práce',
      awards_and_diplomas: 'Ceny a diplomy',
    },
  },
  stripe_warning:
    'Pozor! Minimální částka platby pomocí stripe je 20 €. Pokud je vyžadována menší částka, obraťte se na studio pro podrobnosti',
  check_in: {
    rate: {
      upload_photo: 'Nahrajte fotografii své práce',
      upload_photo_desc:
        'Můžete pořídit krásnou fotografii své práce pro naše sociální sítě a webové stránky. Administrátor také pořizuje fotografii pro kontrolu kvality a zajištění provedené práce. Vždy můžete odmítnout zveřejnění a sdílení fotografie, a vaše data budou po uzdravení zcela vymazána.',
      bad_comment: 'Prosím vysvětlete, proč jste dali nízké hodnocení',
      review: 'Zanechte recenzi!',
      review_desc: 'Budeme velmi vděční, pokud zanecháte recenzi. Na vašem názoru nám záleží!',
      cashback: 'x2 cashback na váš účet v aplikaci',
      send_invoice: 'Poslat fakturu na e-mail',
      influencer: 'Máte 2000+ sledujících na sociálních sítích?',
    },
    welcome_message: 'PŘEJEME PŘÍJEMNOU SEANCI!',
    session_started: 'VAŠE SEANCI ZAČALA',
    next_steps: 'Po dokončení seance budou k dispozici další kroky',
    buy_healing_product: 'DOPORUČUJEME KOUPIT LÉČIVÝ PRODUKT',
    skip: 'Přeskočit',
    leave_review: 'Prosím, zanechte recenzi',
    your_impression: 'Jaké jsou vaše celkové dojmy?',
    add_photo: 'Přidejte fotografii práce a získejte další 1 Vean Coin',
    send: 'Odeslat',
    payment_success: 'Platba byla úspěšná',
    get_invoice: 'Chcete získat fakturu?',
    invite_friend: 'Pozvat přítele',
    recommend_us: 'Doporučte nás svým přátelům a vydělávejte společně s nimi',
    thank_employees: 'Také doporučujeme poděkovat našim zaměstnancům',
    you_can_tip:
      'Vážený %{name}, můžete také poděkovat našim zaměstnancům určitou částkou spropitného',
    own_amount: 'Vlastní částka',
    who_to_tip: 'Komu by mělo být přiděleno spropitné?',
    payment_type: 'Typ platby',
    continue: 'Pokračovat',
    summary: 'Souhrn',
    healing_agent: 'Léčivý prostředek',
    tips: 'Spropitné',
    session_payment: 'Platba za seanci',
    total: 'Celkem',
    waiting: 'Čekání...',
    available_funds: 'Dostupné prostředky',
    refill_balance: 'Doplnit zůstatek',
    go_to_payment: 'Přejít k platbě',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Spropitné',
    prepayment: 'Záloha',
    have_questions: 'Máte otázky?',
    title: 'Seance',
    selectStatus: 'Vyberte stav',
    selectParlor: 'Vyberte salon',
    loading: 'Načítání...',
    fromDate: 'Od data',
    toDate: 'Do data',
    search: 'Hledat',
    all: 'Všechny',
    status: {
      100: 'Čekající',
      200: 'Nový',
      300: 'Probíhá',
      400: 'Dokončeno',
      500: 'Zrušeno',
      600: 'Čeká na platbu',
    },
    details: 'Detaily sezení',
  },
  master: {
    rank: 'Hodnost',
    works_count: 'Počet prací',
    address: 'Adresa',
  },

  modalPhone: {
    title: 'Pozor!',
    info: 'Vážený zákazníku, pro vaši bezpečnost a efektivní práci s naším servisem je povinné přidání všech údajů. Pomůže nám to včas reagovat na důležité otázky a zajistit vaši ochranu.',
    reminder: 'Připomeň mi později',
    go_to_settings: 'Přejít na nastavení',
    roughly_price: 'Přibližná cena závisí na přesnosti vámi zadaných údajů',
    success_message: 'Děkujeme vám!',
  },
  studyingRegistration: {
    become_student: 'Staňte se studentem',
    step_1: 'Obecné údaje',
    step_2: 'Osobní informace',
    step_3: 'Podrobnosti o studiu',
    city: 'Město',
    city_placeholder: 'Zadejte vaše město',
    name: 'Jméno',
    name_placeholder: 'Zadejte vaše jméno',
    last_name: 'Příjmení',
    last_name_placeholder: 'Zadejte vaše příjmení',
    date_of_birth: 'Datum narození',
    date_of_birth_placeholder: 'Zadejte vaše datum narození',
    phone: 'Telefonní číslo',
    email: 'E-mail (nepovinné)',
    email_placeholder: 'Zadejte váš e-mail',
    social_media: 'Sociální média',
    social_media_placeholder: 'Odkaz na váš profil',
    referral_source: 'Jak jste se o nás dozvěděli?',
    referral_source_placeholder: 'Vyberte zdroj',
    study_type: 'Typ studia',
    study_type_placeholder: 'Vyberte typ studia',
    start_date: 'Přibližné datum začátku',
    start_date_help: 'Zadejte přibližné datum začátku',
    hours_number: 'Počet hodin',
    practice_number: 'Počet praxí',
    details: 'Detaily',
    education_price: 'Cena školení',
    moto: 'Metoda výuky ověřená desetiletími',
    description: 'Popis',
    material: 'Materiál',
    duplicating_phone_number: "Student s takovým telefonním číslem je registrován"
  },
  studying_main: {
    become_student: 'Staňte se studentem!',
    consultation: 'Konzultace',
    want_to_study: 'Chci studovat!',
    consult_with_our_advisor: 'Nevíte, kde začít? Kontaktujte našeho poradce!',
  },
  errorTicket: {
    error_description: 'Nahlaste problém aplikace a my jej rychle opravíme',
    error_button: 'Nahlaste nefunkčnost aplikace',
    problem_situation_description: 'Vyskytl se problém po sezení? Informujte nás',
    problem_session_button: 'Nahlásit problém po sezení',
    problem_session: 'Nahlásit problém po sezení',
    sidebar_title: 'Nahlásit problém',
    main_title: 'Vytvoření oznámení o chybě',
    basic_info:
      'Při sestavování ticketu je důležité poskytnout veškeré potřebné informace pro rychlé a efektivní zpracování vaší žádosti. Zde je příkladná struktura zprávy, která vám může pomoci:',
    ticket_title: 'Název ticketu:',
    short_description: "Krátký popis problému (například 'Problémy s vytvářením relací')",
    first_title: 'Popis problému',
    detailed_description: 'Podrobný popis:',
    problem_description: 'Popište podstatu problému, co se přesně děje a v čem spočívá závada.',
    reproduction_steps: 'Kroky k reprodukci problému:',
    reproduction_steps_description: 'Vyjmenujte kroky, které vedou k problému.',
    expected_result: 'Očekávaný výsledek:',
    expected_result_description: 'Co jste očekávali získat nebo vidět?',
    actual_result: 'Skutečný výsledek:',
    actual_result_description: 'Co se stalo místo očekávaného výsledku?',
    important_data: 'Důležitá data',
    links: 'Odkazy:',
    links_description: 'Uveďte odkazy na problémové stránky nebo dokumenty.',
    photos_and_screenshots: 'Fotografie a snímky obrazovky:',
    photos_and_screenshots_description:
      'Připojte obrázky, které mohou pomoci vizualizovat problém.',
    session_numbers: 'Čísla relací:',
    session_numbers_description: 'Uveďte čísla relací, během kterých problém nastal.',
    additional_info: 'Další informace:',
    additional_info_description: 'Zahrňte další informace, které mohou být užitečné.',
    priority_and_urgency: 'Priorita a naléhavost:',
    priority_and_urgency_description:
      'Pokud problém významně ovlivňuje vaši práci, uveďte to zde a požádejte o vysokou prioritu.',
    issue_title: 'Název',
    issue_title_placeholder: 'Zadejte krátký popis problému',
    description: 'Popis',
    description_placeholder: 'Podrobněji popište problém',
    files: 'Soubory',
    drag_and_drop: 'Přetáhněte soubor sem nebo vyberte',
    uploaded_files: 'Nahrané soubory',
    create_button: 'Vytvořit',
    problem_type: 'Typ problému',
    select_problem_type: 'Vyberte typ problému',
    technical: 'Technický',
    non_technical: 'Netechnický',
    loading: 'Načítání...',
    main_page: 'Přejít na hlavní stránku',
    checkbox: 'Musíte potvrdit souhlas se všemi body.',
  },
  multistep: {
    basic_info: 'Základní údaje',
    type: 'Typ',
    summary: 'Souhrn',
    details_rent: 'Pronájem místa',
    confirmations: 'Dohody',
    open_google: 'Otevřít v Google Maps',
  },
  check_in_view: {
    important: 'Důležité',
    log_in_state_greet: 'Přihlaste se ke svému účtu a sledujte své bonusy a slevy.',
    log_in: 'Přihlásit se',
    download_app_greet: 'Stáhněte si naši aplikaci a získejte bonus',
    download: 'Stáhnout',
    welcome: 'Vítejte',
    studio_info: 'Informace o studiu',
    recommended_products: 'Doporučené produkty',
  },
  toast: {
    certificate_success: 'Certifikát byl odeslán na Vaši e-mailovou adresu',
  },
  tattoo_removal: {
    title: 'LASER / ZESVĚTLENÍ',
    registration_description: 'Vyberte si vhodné datum a čas pro objednání během několika kliknutí',
    registration_button: 'Rychlá registrace na zesvětlovací sezení',
    consultation_description: 'Chcete objasnit detaily? Rádi vám pomůžeme!',
    consultation_button: 'Konzultace',
    header_nav_title: 'Odstranění tetování',
    choose_service: 'Typ služby',
    choose_service_placeholder: 'Vyberte typ služby',
    paste_tattoo: 'Připojte fotografii odstranění / zesvětlení (nepovinné)',
    choose_size: 'Velikost',
    choose_size_placeholder: 'Vyberte velikost',
  },
  success_form: {
    go_personal_acc: 'Přejděte na svůj účet',
    dont_have_acc: 'Nemáte účet?',
    sign_up: 'Zaregistrujte se!',
  },
  loyalty_card: {
    client_card: 'Karta klienta',
    card_number: 'Číslo karty',
    card_photo: 'Fotografie karty',
  },
  discount: {
    first_session: 'sleva na první sezení',
    second_session: 'sleva na druhé sezení',
  },
  days: {
    MON: 'PO',
    TUE: 'ÚT',
    WED: 'ST',
    THU: 'ČT',
    FRI: 'PÁ',
    SAT: 'SO',
    SUN: 'NE',
  },
  calculate_price: {
    label: 'Zjistit cenu',
    color_options: {
      black_and_white: 'Černobílé',
      colored: 'Barevné',
    },
    additional: 'Další',
    select_location: 'Vyberte lokaci',
    select_style: 'Vyberte styl',
    select_size: 'Vyberte velikost',
    select_colored: 'Barva',
    select_parlor: 'Vyberte salon',
    select_service: 'Nabízené služby',
    cost: 'Cena',
    from: 'Od',
    to: 'Do',
    book: 'Rezervovat schůzku',
    no_info: 'Žádné informace',
  },
};
