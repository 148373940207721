import { useToast } from 'maz-ui';
import { getAxiosErrors } from '@/utils/axios';
import { AxiosError } from 'axios';

export default () => {
  const { toast: baseToast } = useToast();


  const axiosErrorToast = (e: any) => baseToast.error(getAxiosErrors(e as AxiosError), {timeout: 10000});

  const successToast = (msg: string = 'Success!') => baseToast.success(msg);

  return {
    successToast,
    errorToast: axiosErrorToast,
    baseToast,
  };
}