export default {
  candidate_form: {
    interested: 'Kāda vakance jūs interesē?',
    select_vacancy: 'Izvēlieties vakanci',
    first_name: '*Vārds',
    last_name: '*Uzvārds',
    phone: 'Tālruņa numurs',
    email: 'E-pasts',
    about_you: 'Par jums (iepriekšējā darba pieredze, sasniegumi, prasmes u.c.) EN/UK',
    social_media_link: '*Sociālo tīklu saite (Instagram/Facebook)',
    language_skill: 'Kādās valodās jūs runājat un kādā līmenī?',
    select_country: 'Izvēlieties valsti',
    select_city: 'Izvēlieties pilsētu',
    desired_salary: 'Kādu algu jūs vēlaties vai %',
    why_we: 'Kāpēc vēlaties strādāt ar mums?',
    desired_work_place: 'Vēlamā darba vieta, studijas atrašanās vieta',
    relocation_question: 'Vai esat gatavs ilgstošiem komandējumiem vai pārcelšanai uz citu pilsētu/valsti uz vairāk nekā 3 mēnešiem?',
    your_services: 'Jūsu pakalpojumi',
    your_styles: 'Jūsu stili',
    portfolio: 'Jūsu portfelis (vismaz 5 fotogrāfijas!)',
    study_place: 'Kur jūs mācījāties?',
    hygiene_certificates: 'Vai jums ir higiēnas sertifikāti / veselības grāmatiņa?',
    upload_cv: 'Jūsu CV',
    thank_you: 'Paldies!',
    success_message: 'Mēs esam saņēmuši jūsu pieteikumu. Sazināsimies ar jums, tiklīdz būs pieejams labs piedāvājums.',
    file_size_error: 'Kopējais failu lielums nedrīkst pārsniegt 25 MB.'
  },
  veancoins: {
    summ: 'Bonusu summa',
    customer_bonus: 'Klienta bonuss',
    vip_gift: 'VIP dāvana',
    vip_price: 'Minimālā VIP summa',
    cash_back_sells: 'Naudas atmaksa par pārdošanu',
    cash_back_certificate: 'Naudas atmaksa par sertifikātu',
    cash_back_student: 'Naudas atmaksa par apmācību',
    cash_back_service: 'Naudas atmaksa par pakalpojumu',
    cash_back_money: 'Naudas atmaksa par bilances papildināšanu',
  },
  support: {
    problem_situation_link: 'Man ir problēma pēc sesijas',
    other_problem_link: 'Man ir cita problēma',
    problem_situation: {
      header: 'Problēma pēc sesijas',
      form: {
        session: 'Sesija',
        created_by_comment: 'Aprakstiet problēmu',
      },
    },
    complain: {
      header: 'Sūdzības un ierosinājumi',
      form: {
        name: 'Vārds',
        phone_number: 'Tālruņa numurs',
        email: 'E-Mail',
        parlor: 'Salons',
        description: 'Aprakstiet problēmu',
        feedback: {
          overall_quality: 'Kā jūs vērtējat kopējo pakalpojumu kvalitāti mūsu salonā?',
          professional_skills: 'Vai bijāt apmierināts ar meistara profesionālajām prasmēm?',
          staff_politeness: 'Cik pieklājīgs un uzmanīgs bija salona personāls?',
          waiting_time: 'Vai bijāt apmierināts ar gaidīšanas laiku pakalpojumam?',
          staff_professionalism: 'Kā jūs vērtējat mūsu personāla profesionalitāti?',
          salon_cleanliness: 'Kā jūs vērtējat tīrību un komfortu mūsu salonā?',
          service_value: 'Vai jūs domājat, ka pakalpojumu izmaksas atbilst to kvalitātei?',
          recommend: 'Vai jūs ieteiktu mūsu salonu saviem draugiem un paziņām?',
        },
      },
    },
  },
  create_credit: {
    credit_target: 'Kam nepieciešams kredīts',
    type_education: 'Izglītības veids',
    type_service: 'Pakalpojuma veids',
    credit_type: 'Kredīta veids',
    how_to_contact: 'Kā ar jums sazināties',
    note: 'Komentārs',
  },
  items_view: {
    header: 'Produkti',
    filter_form: {
      name_placeholder: 'Nosaukums',
      category_placeholder: 'Kategorija',
      parlor_placeholder: 'Salons',
      all_categories: 'Visas kategorijas',
      all_parlors: 'Visi saloni',
    },
    load_more_button: 'Ielādēt vairāk',
    not_found_message: 'Nekas nav atrasts',
  },
  item_view: {
    error_message: 'Kļūda datu ielādēšanā',
    available_in_parlor: 'Pieejams jūsu salonā',
    not_available_in_parlor: 'Nav pieejams jūsu salonā',
    similar_items_label: 'Līdzīgi produkti',
    log_in_to_buy: 'Pieslēgties, lai pirktu',
  },
  item_simple_card: {
    studio_price_label: 'Studijas cena',
    shop_price_label: 'Veikala cena',
  },
  student_candidate: {
    form: {
      who_studing: 'Students vai vietas īre',
      phone_number: 'Telefona numurs',
      first_name: 'Vārds',
      last_name: 'Uzvārds',
      social_link: 'Saite uz sociālo tīklu',
      parlors: 'Studija',
      couch: 'Tetovēšanas krēsls',
      find_out: 'Kā jūs uzzinājāt par mums?',
      start_date: 'Vēlamais sākuma datums',
      types: 'Sesiju veidi',
      work_permit_document: 'Darba atļaujas dokuments',
      sanitary_book: 'Sanitārā grāmatiņa',
      identity_proof: 'Identitātes apliecinājums',
      work_photos: 'Darba fotogrāfijas',
      end_date: 'Vēlamais beigu datums',
      confirmations: 'Es apstiprinu, ka',
      know_buy_material: 'Esmu informēts, ka varu iegādāties materiālus studijā',
      customer_will_complete_agreement: 'Katrs mans klients parakstīs līgumu',
      can_show_documents: 'Vajadzības gadījumā varu uzrādīt oriģinālos dokumentus studijā',
      has_country_permission: 'Man ir uzturēšanās atļauja valstī',
      has_san_book: 'Man ir sanitārā grāmatiņa',
      has_passport: 'Man ir identitātes apliecība (ID)',
      conditions: 'Es esmu iepazinies ar īres nosacījumiem',
    },
  },
  user: {
    referral_links: {
      booking: 'Sesijas rezervācija',
      student_candidate: 'Vietas īre',
      store: 'Klienta veikals',
      candidates_form: 'Ielūgt darbinieku',
    },
  },
  products: {
    want_add: 'Vēlos pievienot izvēlētos kopšanas produktus sesijas izmaksām',
    no_warranty: '*bez šīs opcijas mēs nevaram garantēt 100% veiksmīgu dzīšanu',
    button_add: 'Pievienot',
    souvenir: 'Suvenīri',
    pay_vc: 'Apmaksājiet VEANCOINS pēc sesijas',
  },
  healing_questionary: {
    composable_table: {
      heading: 'Izmantotie materiāli',
      name: 'Nosaukums',
      count: 'Daudzums',
    },
  },
  booking: {
    more_interest: 'Vēl vairāk interesantu lietu',
    active_promo: 'Aktuālās akcijas',
    cashback:
      'Cashback\n' +
      '          <span class="text-warning">%{bonus}%</span>\n' +
      '          par <span class="text-success">tiešsaistes</span> rezervāciju',
    fast_booking: 'Ātra rezervācija',
    develop_sketch: 'Skices izstrāde',
    fast_booking_warning:
      'Rezervācija nav galīga, mūsu menedžeris sazināsies ar jums, lai apstiprinātu sesijas laiku un datumu',
    phone_annotate:
      'Lai palīdzētu mūsu pārdošanas komandai ātrāk reaģēt, lūdzu, norādiet savu vietējo kontakttālruni. Paldies!',
    increased_risk: 'Uzmanību! Augsta riska sesija',
    increased_risk_extended:
      'Izvēlētais sesijas veids un vieta uz ķermeņa ir ar paaugstinātu risku',
    approximate_price: 'Aptuvenā cena',
    price_warning: 'Papildu preces nav iekļautas',
    type: {
      info: 'Ērta pieteikuma forma sesijai ar visu nepieciešamo informāciju',
      booking_button: 'PIETEIKTIES SESIJAI',
      booking_button_promo: 'AR 15% NAUDAS ATMETU VEAN COINS',
      unsure: ' Vēl neesat pārliecināts par izvēli? Mēs jums palīdzēsim!',
      consultant_help: 'Mūsu konsultanti ar prieku jums palīdzēs',
      consultation_button: 'KONSULTĀCIJA',
      simplified:
        'Rezervējiet savu seansu ātri un ērti! Izvēlieties datumu, laiku un pakalpojumu ar pāris klikšķiem',
    },
    color: {
      black: 'Melns',
      colored: 'Krāsains',
    },
    controls: {
      next: 'TĀLĀK',
      back: 'Atpakaļ',
      no_select: 'Nav izvēlēts',
    },
    place_service: {
      select_studio: 'Izvēlieties studiju',
      service: 'Pakalpojums',
      size: 'Izmērs',
      location: 'Atrašanās vieta',
    },
    customer_info: {
      booked_from: 'Kā jūs pierakstījāties uz seansu?',
      login_prompt: 'Lai turpinātu, lūdzu, piesakieties savā kontā',
      login_button: 'Pieslēgties',
      black_white_colored: 'Melnbalts vai krāsains?',
      select_style: 'Izvēlēties stilu',
      tell_more:
        'Pastāstiet mums vairāk par savām tetovējuma priekšrocībām un dalieties piemēros (referencēs)',
      login_promo: 'Pieslēdzoties 10% atlaide',
      wish: 'Vēlējums',
    },
    date_time: {
      choose_time: 'Izvēlēties apmeklējuma laiku',
    },
    master_card: {
      master_unavailable: 'Meistars šajā laikā nav pieejams',
      master_guest_warning: 'UZMANĪBU! Meistars ir viesis',
      rank: 'Rangs:',
      works_count: 'Darbu skaits:',
      book_now: 'Rezervēt tagad',
      select_another_time: 'Izvēlēties citu laiku',
    },
    success: {
      session: 'SESIJA',
      become_mystery_shopper: 'Kļūt par noslēpumaino pircēju',
      add_to_calendar: 'Pievienot notikumu kalendāram',
      call_on_day: '*salona administrators jums zvanīs sesijas dienā',
      return_to_menu: 'Atgriezties izvēlnē',
      recommended_products: 'Ieteicamie produkti dzīšanai',
    },
    black_friday: '50% atlaide ar obligātu depozītu',
  },
  tips: {
    placeholder: 'Izvēlieties, kā sadalīt dzeramnaudu',
    divide_by_all: 'Vienādi',
    administrator: 'Administratoram',
    parlor: 'Salonam',
    master: 'Meistaram',
  },
  new_landing: {
    count_block: {
      countries: 'Valstu skaits',
      studios: 'Studijas',
      awards: 'Apbalvojumi',
      masters: 'Meistari',
    },
    header_banner: {
      label_1: 'Mēs no mušas nepūšam ziloni',
      label_2: 'mēs veidojam tetovējumus!',
      book_now: 'Pierakstīties tagad',
      consultation: 'Konsultācija',
      prices: 'Cenas',
      top_works: 'Top darbi',
      advantages: 'Priekšrocības',
      change_parlor: 'Mainīt salonu',
    },
    course_card: {
      theory_part: 'Teorētiskā daļa:',
      practice_part: 'Praktiskā daļa:',
      advance: 'Papildu priekšrocības:',
    },
    helpful_links: {
      for_masters: 'Meistariem',
      tattoo_news: 'Tetovējumu ziņas',
      tattoo_news_description: 'Esi kursā par svarīgākajiem notikumiem tetovējumu pasaulē',
      vean_tattoo_school: 'VEAN TATTOO SCHOOL',
      vean_tattoo_school_description:
        'Mūsu meistari ir gatavi dalīties ar zināšanām, noslēpumiem, tehnikām',
      rent_space: 'Telpas noma',
      rent_space_description: 'Uzziniet par sadarbības iespējām, īrējot darba vietu VEAN studijās',
      tattoo_promotions: 'Aktualios akcijos',
      tattoo_certificates: 'Dāvanu sertifikāti',
      consumables: 'Izmantojamie materiāli',
      equipment: 'Aprīkojums',
    },
    price_block: {
      title: 'Cenas',
      loyalty_program: {
        title: 'Lojalitātes programma',
      },
      actions: {
        book_now: 'Rezervēt tagad',
        calculate_cost: 'Aprēķināt izmaksas',
      },
      // другие вложенные переводы...
    },
    top_works: {
      title: 'Labākie darbi',
      from_the_world: 'no visas pasaules',
    },
    why_vean: {
      title: 'Kāpēc VEAN',
      individual_approach: 'Individuāla pieeja',
      trust: 'Uzticība',
      safety_guarantee: 'Drošības garantija',
      convenient_location: 'Ērta atrašanās vieta',
      visited_studio: 'Cilvēki apmeklēja šo studiju',
    },
  },
  mystery_shopper: {
    title: 'Noslēpumainais pircējs',
    description:
      'Noslēpumainais pircējs ir persona, kas pārbauda salonu darbību, izliekoties par parastu klientu. Viņš vērtē pakalpojuma līmeni salonā.',
    offer: 'Ja jūs izlemsit kļūt par noslēpumaino pircēju šajā sesijā, mēs dāvināsim jums monētas.',
    instructionTitle: 'Norādījumi',
    instructionDetails:
      'Sesijas laikā jums būs jānovērtē salons un personāls dažādos kritērijos. Pēc sesijas jums saņems SMS ar saiti uz aptaujas anketu, kuru jāaizpilda godīgi.',
    question: 'Vai jūs vēlaties kļūt par noslēpumaino pircēju?',
    yesButton: 'Jā',
    noButton: 'Nē, paldies',
    successMessage: 'Jums būs jānovērtē studija šādos kritērijos:',
    criteria1: 'Konsultācija pa telefonu un sociālajos tīklos',
    criteria2: 'Konsultācija studijā, kad iegriežaties',
    criteria3: 'Studijas cenpolitika',
    criteria4: 'Ārpuse: Studijas fasāde, izkārtnes, atrašanās vieta u.c.',
    criteria5: 'Studijas interjers, iekārtojums',
    criteria6: 'Preču, suvenīru un dziedināšanas līdzekļu sortiments (izstāde)',
    criteria7: 'Atmosfēra: TV, mūzika, personāls',
    criteria8: 'Uzkodu pieejamība, izklaide, žurnāli un katalogi ar skicēm',
    criteria9: 'Saskarsme ar meistaru',
    criteria10: 'Saskarsme ar administratoru',
  },
  cookies: {
    text: `Mēs izmantojam sīkdatnes, lai uzlabotu jūsu pārlūkošanas pieredzi, 
      rādītu personalizētas reklāmas vai saturu 
      un analizētu mūsu datplūsmu. Noklikšķinot uz „Pieņemt“, 
      jūs piekrītat sīkdatņu izmantošanai.`,
    confirm: 'Pieņemt',
    more: 'Uzzināt vairāk',
    manage: 'Pārvaldīt',
    config: {
      necessary: 'Nepieciešamas',
      preferences: 'Izvēles',
      statistics: 'Statistiskās',
      marketing: 'Mārketinga',
    },
  },
  header_menu: {
    logout: 'Izeja',
    main: 'Sākums',
    about_us: 'Par mums',
    discount_page: 'Atlaides',
    loyalty_page: 'Lojalitātes programma',
    ai_page_link: 'Ģenerēt skici',
    fonts_page_link: 'Tatū burti',
    quality_certificate: 'Kvalitātes sertifikāti',
    complaints: 'Sūdzības un priekšlikumi',
    public_offer_link: 'Publiskā piedāvājums',
    confidence: 'Privātuma politika',
    work: 'Vēlos strādāt VEAN',
    dockuments_page_link: 'Dokumenti',
    login: 'Pieteikties',
    account: 'Personīgais konts',
    studying: 'Es gribu mācīties!',
    buy_certificate: 'Nopirkt sertifikātu',
    feedback: 'Atsauksmes',
    loan: 'Maksā pa daļām!',
    services: 'Pakalpojumi',
    parlor_social_links: 'Salona sociālie saites un kontaktinformācija',
    global_social_links: 'Globālie sociālie saites un kontaktinformācija',
  },
  post: {
    welcome: 'Sveiki. Jūs bijāt pie mums sesijā',
    no_answer_questions: 'Jūs neatbildējāt uz šiem jautājumiem:',
    point: 'Lūdzu, novērtējiet pakalpojuma kvalitāti un saņemiet VeanCoin!',
    send: 'Sūtīt',
    error: 'Radās kļūda!',
    not_found: 'Sesija netika atrasta!',
    not_call: 'Man nav nepieciešams atzvanīt',
    thank: 'Liels paldies par novērtējumu!',
    bot: 'Pēc 21 dienas mūsu bots no nezināma numura jums piezvanīs, lai apstiprinātu informāciju par sniegto pakalpojumu un dziedēšanu.',
    certificate: 'Jums ir izveidots akcijas sertifikāts %{gift_price} VeanCoin vērtībā',
    social_networks: 'Lūdzu, atstājiet atsauksmi par mums sociālajos tīklos:',
    month: 'Izmantojiet to mēneša laikā, neaizmirstiet atgādināt administratoram!',
    dissatisfied:
      'Neesat apmierināts ar sniegto pakalpojumu? Mēs pēc iespējas ātrāk apstrādāsim jūsu pieprasījumu.',
    help_us_improve:
      'Palīdziet mums kļūt labākiem, atbildot uz jautājumiem un saņemiet <span class="text-warning">{bonusAmount}</span> VEANCOINS!',
    session_price: 'Samaksāts par sesiju',
    session_additional: 'Samaksāts par papildu pirkumiem',
    session_tips: 'Dzeramnauda',
    session_total: 'Kopā',
    loyalty_help: 'Reģistrācija lojalitātes programmā ir pieejama nākamajā lapā. Pievienojieties, lai izmantotu īpašos piedāvājumus un bonusu uzkrāšanas sistēmu.',
    scores: {
      variants: {
        great: 'Lieliski',
        good: 'Labi',
        satisfactorily: 'Apmierinoši',
        bad: 'Slikti',
        fast: 'Ātri',
        orally: 'Mutiski',
        in_writing: 'Rakstiski',
        orally_in_writing: 'Mutiski un rakstiski',
        yes: 'Jā',
        no: 'Nē',
        didnt_listen: 'Nedzirdēju',
        not_now: 'Ne tagad',
        book_by_operator: 'Rezervēt caur operatoru',
        book_by_yourself: 'Rezervēt pašam ar bonusiem',
      },
      administrator:
        'Kā jūs vērtējat studijas administratora konsultāciju par pakalpojumiem, meistariem un aprūpi?',
      recommendations: 'Vai jums tika sniegti skaidri aprūpes ieteikumi?',
      master_contact: 'Vai meistars sniedza jums savus kontaktus?',
      holl: 'Kā jūs vērtējat gaidīšanas komfortu reģistratūrā (apkārtne, tīrība, ērtības)?',
      price: 'Kā jūs vērtējat pakalpojumu cenu studijā?',
      callback: 'Cik ātri saņēmāt atbildi no studijas, reģistrējoties uz sesiju?',
      master: 'Kā jūs vērtējat meistaru studijā?',
      care: 'Vai jums tika piedāvāts iegādāties profesionālus aprūpes produktus vai rotaslietas?',
      buy_other: 'Vai iegādājāties rotaslietas vai aprūpes produktus?',
      need_contact: 'Vai vēlaties, lai mēs sazinātos ar jums?',
      loyalty_program: 'Vai jau piedalāties lojalitātes programmā?',
      sketch: 'Vai jums tika piedāvāts pasūtīt individuālu zīmējumu/projektu?',
      souvenir: 'Vai jums tika piedāvāts iegādāties suvenīrus vai preces ar logotipu?',
      repeat_record: 'Vai vēlaties pierakstīties vēlreiz?',
      price_correct: 'Vai jūs samaksājāt norādīto kopējo summu?',
      price_correct_amount: 'Ievadiet summu, kuru samaksājāt',
      tips: 'Vai atstājāt dzeramnaudu?',
      studio: 'Kā jūs vērtējat studijas darbu?',
      master_note: 'Aprakstiet problēmu',
      record_date: 'Datums, kad būs ērti sazināties',
      record_time: 'Laiks, kad būs ērti sazināties',
      record_comment: 'Jūsu komentārs rezervācijai',
    },
    scores_mystery_shopper: {
      instagram: 'Mūsu INSTAGRAM novērtējums',
      site: 'Mājaslapas novērtējums: vean-tattoo.%{locale}',
      phone_consultation: 'Telefoniskā konsultācija un sociālie tīkli',
      consultation: 'Konsultācija studijā, ierodoties',
      price: 'Jūsu viedoklis par studijas cenu politiku',
      exterior: 'Ārējais izskats: studijas fasāde, zīmes, atrašanās vieta utt.',
      interior: 'Studijas iekšējais interjers, iekārtojums',
      assortment: 'Suvenīru un dziedēšanas produktu sortiments',
      atmosphere: 'Atmosfēra: TV, mūzika, komanda',
      leisure: 'Uzkodas, atpūta, žurnāli un zīmējumu katalogi',
      master: 'Komunikācija ar meistaru',
      admin: 'Komunikācija ar administratoru',
      master_one_place: 'Meistars strādā tikai šajā studijā? (jā/nē)',
      master_contact: 'Vai pieprasījāt personiskos kontaktus no meistara? (jā/nē)',
      care: 'Vai jums tika piedāvāts iegādāties dziedēšanas produktus un suvenīrus? Vai saņēmāt nelielu dāvanu (magnēts, uzlīme vai 10 % atlaide nākamajai pakalpojuma reizē)? (jā/nē)',
      conclusion: 'Pārbaudes galīgais novērtējums',
    },
    inputs: {
      cost: 'Norādiet summu, kuru samaksājāt par pakalpojumu',
      cost_other: 'Cik iztērējāt rotaslietām vai aprūpes produktiem?',
      review: 'Vai jums ir priekšlikumi studijas uzlabošanai?',
      photo: 'Studijas fotogrāfija',
    },
    inputs_mystery_shopper: {
      recommendation: 'Jūsu ieteikumi mums',
      why_vean: 'Kāpēc izvēlējāties VEAN?',
    },
  },
  header: {
    online_appointment: 'Tiešsaistes ieraksts',
    post_session: 'Kvalitātes novērtējums',
    get_price: 'Uzzināt cenu',
    consultation: '(Konsultācija)',
    order_consultation: 'Pasūtīt konsultāciju',
  },
  index: {
    parlor: 'Studija',
    master: 'Meistars',
    session_type: 'Pakalpojuma tips',
    session_location: 'Pakalpojuma vieta',
    datetime: 'Datums un laiks',
    create_appointment: 'Pieteikties',
    bonus: '* %{customer_bonus} VeanCoins BONUS par patstāvīgu ierakstu',
    sketch: 'Sīktēls (nav obligāti)',
  },
  session_types: {
    tattoo: 'Tetovējums',
    tatouage: 'Permanents',
    piercing: 'Pīrsings',
    stroke: 'Dzēšana',
    training: 'Apmācība',
    training_tattoo: 'Tetovēšanas apmācība',
    training_piercing: 'Pīrsingu apmācība',
    training_tattouage: 'Tatūažas apmācība',
    training_udder: 'Noņemšanas apmācība',
  },
  price_form: {
    client_name: 'Nosaukums',
    client_phone: 'Tālrunis',
    client_comm: 'Jūsu pakalpojums',
    sketch_desc: 'Pievienojiet sīktēlu (* nav obligāti)',
    sketch_button: 'Atlasīt failu',
    send: 'Sūtīt',
    or: 'Vai',
    country: 'Valsts',
    city: 'Pilsēta',
    comment: 'Komentārs',
    val_error: 'Pārbaudiet ievadīto',
    ths_message: 'Paldies! Tuvākajā laikā ar jums sazināsies',
    go_back: 'Atpakaļ',
    file_size: 'Faila lielumam jābūt ne lielākam par 5 MB',
    apr_time: 'Approximate session time',
  },
  selectors: {
    salons: {
      placeholder: 'Meklēt pēc studijas nosaukuma',
    },
    vednis: {
      placeholder: 'Meklēt pēc vārda un pakalpojuma',
    },
    select_type: {
      select: 'Izvēlēties',
      next: 'Tālāk',
      no_price: 'Līgumu',
      ad: 'No',
      da: 'Līdz',
      selected: 'izvēlēts',
      info: 'Cena var būt atkarīga no izmēra un atrašanās vietas',
    },
    datetime: {
      select_date: 'Atlasiet datumu',
      no_active_times: 'Atlasītajā datumā vairs nav brīvu laika lodziņu. Izvēlieties citu, lūdzu',
    },
  },
  verify: {
    verify_account: 'Verificēt kontu',
    type: 'Verifikācijas metode',
    check_code: 'Pārbaudīt kodu',
    status: 'Statuss',
    input_phone: 'Ievadiet savu tālruņa numuru',
    email_help: 'Jūs arī varat izmantot e-pastu verifikācijai',
    sms_sent: 'Jums ir nosūtīts SMS kods, ievadiet to zemāk',
    resend_sms_after: 'Atkārtota iesniegšana ir pieejama pēc %{seconds} sekundēm',
    resend_sms: 'Sūtīt vēlreiz',
    buttons: {
      Back: 'Atpakaļ',
      Next: 'Tālāk',
      create_appointment: 'Pieteikties',
    },
  },
  success: {
    h1: 'Paldies! Šis ieraksts ir veiksmīgi izveidots',
    h2: 'Pēc ieraksta apstiprināšanas administrators Jums nosūtīs īsziņu',
    mystery: 'Kļūt par slepeno pircēju',
  },
  about: {
    header: 'Informācija par jums',
    birth_info:
      'Norādot dzimšanas datumu, mēs pārliecināmies, ka jums ir pieļaujamais vecums, kā arī tas vajadzīgs dzimšanas dienas atlaidei',
    inputs: {
      name: 'Vārds un uzvārds',
      find_out: 'Kā uzzināju',
      birth_date: 'Dzimšanas datums',
    },
    buttons: {
      skip: 'Izlaist',
      back: 'Atpakaļ',
      next: 'Tālāk',
    },
  },
  footer: {
    header: 'Parakstoties uz mūsu sociālajiem tīkliem, lai būtu lietas kursā par visām ziņām',
    site_navigation: 'Navigācija',
    interest: 'Interesanti',
    other: 'Citi',
  },
  subscribe_modal: {
    header: 'Abonējiet ziņas par bonusu',
    submit: 'Nosūtīt',
    wrong_data: 'Pārbaudiet ievadīto datu pareizību!',
    wrong_email: 'Nepareizi ievadīta e-pasta adrese!',
    thanks: 'Paldies par abonēšanu!',
    error: 'Radās kļūda!',
  },
  chat_bot: {
    call: 'Piezvanīt',
    online_record: 'Tiešsaistes pieraksts',
    online_consultation: 'Tiešsaistes konsultācija',
    need_help: 'Vai jums ir nepieciešama palīdzība ar skici?',
    order_sketch: 'Pasūtīt skici',
    dress_sketch: 'Pamēģiniet skici',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Problēmu situācijas',
      id: 'Numurs',
      status: 'Statuss',
    },
    reset_password: {
      title: 'Aizmirsi paroli',
      submit: 'sūtīt',
      forgot_password: 'Aizmirsi paroli',
      phone: 'Ievadiet telefona numuru',
      success_message: 'Pagaidu parole tika nosūtīta uz jūsu e-pastu/telefonu',
    },
    registration: {
      name: 'Vārds',
      surname: 'Uzvārds',
      registered: 'Reģistrācija',
      congratulations: 'Apsveicam!',
      successfully_registered: 'Jūs veiksmīgi reģistrējāties!',
      log_in: 'Ielogoties',
    },
    login_page: {
      log_in_page: 'Pieteikties savā personīgajā kontā',
      number_missing: 'Jums nav numura?',
      register: 'Reģistrējieties!',
      steps: {
        sign_up: 'Reģistrējieties lojalitātes programmā vai PIETEIKTIES',
        check_coins: 'Pārbaudiet savus VeanCoin, reģistrējoties lojalitātes programmā',
        get_coins: 'Saņemiet savus VeanCoin katras sesijas beigās',
        accumulate: 'Vāciet un krājiet VeanCoin savā profilā',
        book_session: 'Rezervējiet sesiju un nāciet uz Vean, lai iegūtu savu tetovējumu',
        exchange_coins: 'Apmainiet VeanCoin un saņemiet atlaides',
      },
    },
    find_customer: {
      continue: 'Turpināt',
      not_found: 'Klients nav atrasts!',
      select_type: 'Izvēlieties pieteikšanās veidu',
      no_password: 'Jūs neesat iestatījis paroli!',
      found: 'Atrasts!',
      check: 'Pārbaude...',
      invalid_phone_or_password: 'Nederīgs telefona numurs vai parole',
      error_login: 'Ielogojoties radās kļūda',
      phone: 'Tālrunis',
      temporary_password_message: 'Jūs izmantojat pagaidu paroli',
      permanent_password_message: 'Jūs izmantojat pastāvīgu paroli',
    },
    product_card: {
      availability: 'Наличие в студиях:',
      unavailable: 'Nav pieejams',
    },
    store_body: {
      full_points_products: 'Товары, которые можно купить за VeanCoins',
      half_points_products: 'Товары, которые можно купить со скидкой 50%',
      no_products: 'Нет товаров',
    },
    description_block: {
      loyalty_system: 'Система лояльности VEAN',
      show_more: 'Parādīt vairāk',
    },
    profile_card: {
      my_sketches: 'Mani skices',
      share_link: 'Piedalieties šim saitam',
      tips: 'Dzeramnauda',
      send_certificate: 'Sūtīt sertifikātu',
      vip: 'VIP',
      not_vip: 'Kļūt par VIP',
      veancoins: 'VeanCoins',
      balance_buy: 'Nauda',
      active_certificates: 'Aktivizētas monētas',
      balance: 'Bilance',
      sessions: 'Sesijas',
      points_alltime: 'Visu laiku nopelnītas monētas',
      available_certificates: 'Pieejamie sertifikāti',
      certificates: 'Naudas sertifikāti',
      bought_certificates: 'Aktivizētas VeanCoins',
      certificate_number: 'Sertifikāta numurs',
      available_for: 'Derīgs līdz:',
      no_active_certificates: 'Nav naudas sertifikātu',
      no_active_bought_certificates: 'Nav aktīvu VeanCoins',
      coming_sessions: 'Gaidāmās sesijas',
      complete_agreement: 'Aizpildīt līgumu',
      date: 'Datums:',
      you_was: 'Jūs bijāt %{sessions} sesijās',
      edit: 'Rediģēšana',
      verify_account_status: 'Konta statuss',
      security_percent: 'Drošība:',
      not_email: 'lai nosūtītu sertifikātu, jāaizpilda E-pasts',
      exist_application: 'ir lietotne',
      need_exist_application: 'nav lietotnes',
      need_confirm_account: 'Nepieciešams apstiprināt kontu',
      verified: 'Apstiprināts',
      choose_payment_method: 'Izvēlieties maksājuma veidu',
      parlor: 'Salons',
      sign_up_online: 'Pieteikties sesijai',
      mystery_shopper: 'Noslēpumainais pircējs',
      sign_up_secret_buyer:
        'Reģistrējieties pie mums uz sesiju un kļūstiet par noslēpumaino pircēju',
      pay: 'Maksāt',
      prepayment: 'Priekšapmaksa',
      questionnaire: 'Anketa',
      agreement: 'Līgums',
      fill_required_information: 'Jāaizpilda informācija',
      buy: 'Papildināt bilanci',
      archive: 'Arhīvs',
      support_service: 'Klientu atbalsts',
      balance_buy_history: 'Pirkto punktu VeanCoins',
      balance_history: 'Iegūto punktu VeanCoins',
      buy_history: 'Pirkumu vēsture',
      сost: 'Izmaksas',
      payment_link: 'Maksājuma saite',
      attributes: 'Atribūti',
      invite_friend: 'Aicināt draugu',
      phone_or_email: 'Ievadiet tālruņa numuru vai e-pasta adresi',
      report_problem: 'Ziņot par problēmu',
      note: 'Ievadiet piezīmi',
      tooltip_coins:
        'jums ir jāaktivizē savas uzkrātās monētas izvēlētajam pakalpojumam vai preču iegādei, lai izvairītos no to izdegšanas viena gada laikā',
      tooltip_certificate:
        'jūsu nopirktu vai dāvinātu naudas sertifikātu skaits, ko var izmantot divu mēnešu laikā',
      tooltip_сonfirmed: 'Rediģējot mainiet «ФИО или username» uz «Vārds un Uzvārds»',
      tooltip_missing:
        'Pievienojiet par sevi informāciju (vārds un uzvārds, dzimšanas datums, saite uz jūsu sociālajiem tīkliem, kā uzzinājāt par mums, telefona numurs un e-pasts identitātes apstiprināšanai)',
      tooltip_verified:
        'Jums ir jāapstiprina savs konts ar telefona numuru vai e-pastu, lai aizsargātu jūsu iegūtās un uzkrātās VeanCoins.',
      tooltip_vip:
        'VIP client is a status that can be obtained by any client who spends more than %{amount} on services. VIP status provides certain benefits to its owner, such as fast priority booking for sessions out of turn, access to unique goods and services. Upon reaching VIP status, the client immediately receives %{cashback} VeanCoins credited to their account.',
      earn_veancoins: 'Nopelni veancoins',
    },
    profile_edit: {
      additional_information: 'Papildus informācija par VeanCoins',
      parlors: 'Salons (kas ir tuvāk vai galvenais)',
      client_card: 'Klienta karte',
      full_name: 'Vārds un uzvārds vai lietotājvārds',
      birth_date: 'Dzimšanas datums (apsveikumam)',
      social_media_link: 'Saite uz sociālajiem tīkliem',
      find_out: 'Kā jūs uzzinājāt',
      or: 'vai',
      phone_placeholder: 'Tālrunis',
      save: 'Saglabāt',
      password_tooltip:
        'Izveidojiet paroli, lai nākotnē varētu piekļūt savam profilam bez SMS un e-pasta palīdzības. Tas arī aizsargā jūsu personīgos datus.',
      password: 'Parole',
      repeat_password: 'Atkārtot paroli',
      email_taken: 'Šis e-pasta adrese jau ir aizņemta!',
      phone_taken: 'Šis tālruņa numurs jau ir aizņemts!',
      simple_password: 'Pārāk vienkārša parole!',
      invalid_password: 'Nepareiza parole!',
      password_for_application: 'Parole lietojumprogrammai (nav obligāta)',
      congratulate: 'Apsveicam!',
      successfully_registered: 'Veiksmīgi reģistrēts!',
      need_confirm_account: 'Nepieciešama konta apstiprināšana',
      yes: 'Jā',
      no: 'Nē',
      phone_confirmed: 'Tālrunis apstiprināts',
      email_confirmed: 'E-pasts apstiprināts',
      change_password: 'Mainīt paroli',
      old_password: 'Vecā parole',
      new_password: 'Jaunā parole',
      confirm_password: 'Atkārtojiet paroli',
      invalid_phone_or_password: 'Nederīgs telefona numurs vai parole',
      error_login: 'Ielogojoties radās kļūda',
      years_old: 'Vecums',
      up_18_years_old: '16-21',
      for18_24: '21-27',
      for25: '27-35',
      for35_45: '35-45',
      up_45_years_old: '45+',
      gender: 'Dzimums',
      man: 'Vīrietis',
      woman: 'Sieviete',
      gender_hidden: 'Vēlos to neuzrādīt',
      language: 'Valoda',
      preferred_style_status: 'Izlases stili',
      classic: 'Klasika / Realisms',
      freak: 'Friki / Trešais / Anime',
      fashion: 'Modes / Māksla / Minimalisms',
      rock: 'Rokmūzika / Smags metāls',
      pop: 'Popmūzika / Masa',
      accommodation_status: 'Dzīvesvieta',
      local: 'Vietējais',
      tourist: 'Tūrists',
      guest: 'Viesis',
      lifestyle_status: 'Dzīvesveids',
      extreme: 'Ekstrēms',
      dance: 'Deja',
      anime: 'Anime',
      game: 'Spēle',
      music: 'Mūzika',
      lgbt: 'LGBT',
      art: 'Māksla / Modernitāte',
      family: 'Ģimene / Mīlestība',
      beauty: 'Skaistums',
      sexuality: 'Seksualitāte',
      masculinity: 'Vīrišķība',
      tattoo_count_status: 'Tatū mākslas skaits',
      pierce_count_status: 'Piercingu skaits',
      one: 'Viena',
      two: 'Divas vai vairāk',
      inc: 'Ļoti daudz',
      change_information: 'Saglabāt informāciju',
    },
    verification: {
      sms: 'Apstiprināt caur SMS',
      email: 'Apstiprināt caur E-pasts',
      google: 'Pārbaudīt caur Google',
      send_code: 'Sūtīt kodu',
      code: 'Verifikācijas kods',
    },
    loyalty: {
      submit_button: 'Nosūtīt',
      bonus_instruction:
        'Izpildiet vienu no uzskaitītajiem punktiem, pievienojiet pierādījumus (ekrānšāviņus, foto utt.) un saņemiet bonusu',
      bonus_amount: 'VeanCoins!',
      review_option_1: 'Atstājiet detalizētu atsauksmi meistara profilā vietnē',
      review_option_2: 'Novērtējiet studiju vai meistaru vietnē',
      review_option_3: 'Novērtējiet studiju un atstājiet atsauksmi kartē',
      review_option_4: 'Dalieties ar mūsu studiju sociālajos tīklos',
      return:
        'Ja jūsu iesūtītā bilde nepieder nevienai no kategorijām, par kurām piešķiram bonusus, tad saņemtie bonusi tiks atcelti, iegādājoties dāvanu sertifikātu.',
      evidence_label: 'Pierādījumi',
    },
    buy_certificate: {
      payment_type_title: 'Pirkuma veids',
      payment_type_subtitle: 'Ko jūs vēlaties darīt',
      buy_balance: 'Papildināt bilanci',
      buy_certificate: 'Pirkt sertifikātu',
      amount_title: 'Summa',
      amount_subtitle: 'Ievadiet summu',
      payment_method_title: 'Maksājuma metode',
      payment_method_subtitle: 'Izvēlieties maksājuma metodi',
      payment_title: 'Maksājums',
      forming_payment: 'Maksājuma veidošana...',
      pay: 'Maksāt',
      continue: 'Turpināt',
      back: 'Atpakaļ',
      submit_to_email: 'Nosūtīt sertifikātu uz e-pastu',
      get_vean_card: 'Saņemiet VEAN karti studijā',
      vean_card_description:
        'VEAN CARD ir jūsu karte, kurā VEAN TATTOO sertifikāti tiek automātiski aktivizēti, nodrošinot drošību un ērtumu. Visas jūsu dāvanas un atlaides vienmēr ir pieejamas, un kartes lietošana ir maksimāli vienkārša un izdevīga. Saņemiet savu karti VEAN TATTOO studijās un atklājiet visas priekšrocības jau šodien!',
      total_balance: 'Līdzsvars',
      certificate: 'Sertifikāts',
      customer_balance: 'Klienta atlikums',
      certificate_type: 'Sertifikāta veids',
      promotion_certificate: 'Akcijas sertifikāts',
      buy_for_myself: 'Pirkt sev',
      promotion_certificate_message:
        'Fiziskie sertifikāti tiks izsniegti līdz 2024. gada 31. decembrim. Sākot ar 2025. gada 1. janvāri, sertifikāti tiks pārvērsti punktos un parādīti lietotāja personīgajā kontā.',
    },
  },
  parlor_page: {
    header: {
      parlors_count: 'Vairāk nekā 160 saloni',
      clients_count: 'Vairāk nekā 1 500 000 klienti',
      largest_company: 'Lielākā tetovējumu studiju tīkls pasaulē',
    },
    master_block: {
      our_masters: 'Наши мастера',
    },
    master_card: {
      works_count: 'Количество работ: ',
    },
    no_admin_block: {
      leisure: 'Brīvais laiks',
      session_record: 'Запись на сеанс',
      about_company: 'О компании',
      care: 'Уход',
      quality_certificates: 'Сертификаты качества',
      public_buyout_offer: 'Публичная оферта',
      complaints_offers: 'Жалобы и предложения',
      loyalty_system: 'Система лояльности',
      discount_page: 'Atlaides',
    },
    peculiarities_block: {
      about_studio: 'Об этой студии',
      clients: 'Клиенты',
      sessions: 'Сеансы',
      studio_hours: 'Время работы: ',
      studio_phone: 'Номер студии: ',
      wifi: 'Wi-fi: ',
      password: 'Пароль: ',
      from_to: 'no %{time_start} līdz %{time_end}',
    },
    photos_block: {
      photos: 'Фото салона',
    },
    top_works: 'Darbi',
    care_important: 'Svarīgākais pīrsingā un tetovēšanā – tā ir dzīšana un kopšana.',
    cabinet: 'Personīgā kabinets',
    complete_agreemnent: 'Aizpildiet vienošanos',
    is_blanket: 'Ir sega, ar kuru var sasildīties',
    is_water: 'Ir karstie dzērieni',
    is_hot_drinks: 'Ir saldumi',
    is_treats: 'Ir auksti dzērieni',
  },
  promotion: {
    submit: 'Nosūtīt',
    warning: 'Uzmanību! Pārbaudiet ievadīto datu pareizību!',
    name: 'Vārds / Niks',
    required: 'Šis lauks ir obligāts!',
    max_value: 'Ne vairāk kā 100 rakstzīmes!',
    phone: 'Telefons',
    social_link: 'Saite uz sociālo tīklu',
    wrong_format: 'Nepareizs formāts!',
    terms: 'Es esmu iepazinies ar personas datu apstrādes politiku',
    know_bonus: 'Man ir zināms, ka man tiks piešķirti bonusa punkti',
    error: 'Notikusi kļūda',
    thanks: 'Paldies!',
    bonus: 'Jums ir piešķirti %{gift_price} punkti',
    do_not_disturb: 'Nesatraukties (atslēgt SMS un zvanus no VeanTattoo)',
    get_promo: 'Es vēlos saņemt informāciju par atlaides un akcijām',
    first_time_veancoins_notification:
      'Lai pirmo reizi izmantotu VeanCoins, jāsazinās ar studijas administratoru. VeanCoins un nauda ir atšķirīgas lietošanas noteikumi',
  },
  another_studio_drawler: {
    another: 'Citas studijas',
  },
  landing: {
    best_studio: 'Labākā tavas pilsētas tetovēšanas studija',
    vean_wide: 'VEAN TATTOO TĪKLS',
    studio_count: 'Vairāk kā 160 tetovēšanas studijas',
    quality: 'Kvalitātes un drošības garantija',
    get_price: 'UZZINĀT CENU',
    contact_us: 'Sazināties ar mums',
    advantage: 'Priekšrocības',
    top_works: 'Darbu tops',
    prices: 'Cenas', //долджен быиь
    studio_photo: 'Studijas fotogrāfija',
    prices_services: 'Cenas un pakalpojumi',
    about: 'Par mums',
    loyalty_system: 'Lojalitātes programma', //должен быть
    why_vean: 'Kāpēc VEAN',
    visit_count: 'Cik cilvēki apmeklēja šo studiju',
    description:
      'VeAn Tattoo tetovēšanas studiju tīkls pašlaik ir lielākais pasaulē: mums ir vairāk kā 160 saloni 14 Eiropas valstīs. Mēs panācām to, ka šai nišai ir pilnībā jauns, augstāks standartu līmenis. Tīkla specializācija ir pietiekami plaša, taču pamata virziens ir tetovēšana un pīrsinga industrija. Svarīgākie kompānijas VeAn Tattoo principi: Eksperti un pieejamība: Mēs tiecamies uz to, lai katrs klients justos ērti katrā procesa posmā, tāpēc mūsu speciālisti ir gatavi atbildēt uz visiem jūsu jautājumiem tiešsaistē. Reputācija un orientācija uz klientu: Katrs klients – tā ir iespēja radīt unikālu mākslas darbu uz ādas. Mēs novērtējam jūsu laiku un tiecamies pie tām, lai klients aizietu no mūsu studijas pacilātā noskaņojumā un ar neticamu tetovējumu. Mēs rūpējamies par reputāciju un novērtējam katru tīkla klientu. Vērtīgums un pieejamība: Mēs piedāvājam ideālu cenas un kvalitātes attiecību. Mūsu pakalpojumu ir pieejami plašai auditorijai, turklāt, no tā necieš kvalitāte. Sertificēts un kvalitatīvs aprīkojums: Mūsu materiāli atbilst augstākajiem drošības un kvalitātes standartiem. Mēs garantējam, ka mūsu klienti saņem tikai labāko. Augsti kvalificēti speciālisti un plaša pakalpojumu izvēle: VeAn Tattoo studijās strādā tikai diplomēti meistari, kurus augsti novērtē un ciena visā pasaulē. Mēs piedāvājam visus pastāvošos stilus un tetovēšanas tehnikas, bezmaksas individuālas skices izstrādāšanu saskaņā ar jūsu prasībām. Mēs piedāvājam arī sekot visām mūsu oficiālajām lapām sociālajos tīklos. Tas ļaus jums uzzināt par speciālajiem piedāvājumiem, akcijām, konkursiem, meistaru darbiem, pēdējiem jaunumiem un kompānijas VeAn Tattoo atjauninājumiem.',
    advantages: {},
    our_masters: 'Mūsu meistari', //doljen bit
    from: 'No',
    no_price: 'Līgumu',
    form: {
      get_price_consultation: 'Uzzināt cenu / Konsultācija',
      name: 'Nosaukums',
      phone: 'Tālrunis',
      place: 'Pilsēta',
      subscribe_news: 'Pierakstīties uz jaunumiem par bonusu',
    },
  },
  form_messages: {
    submit: 'Nosūtīt',
    warning: 'Uzmanību! Pārbaudiet ievadīto datu pareizību!',
    required: 'Tas ir obligāts lauks!',
    length: 'Ne vairāk kā %{length} burti!',
    file_length: 'Ne vairāk kā %{length} faili!',
    wrong_format: 'Nepareizs formāts!',
    error: 'Radās kļūda',
    thanks: 'Paldies!',
    required_label: 'Obligāti',
    not_required_label: 'Nav obligāti',
  },
  training: {
    training: 'APMĀCĪBA',
    tattoo_school: 'TETOVĒJUMU SKOLA',
    biggest_school: 'lielākais tetovējumu salonu tīkls pasaulē',
    ne_otkladivaj: 'Neatliec<br/>sevi uz vēlāku laiku',
    learn: 'Iemācies veidot tetovējumus<br/>un kļūsti par augstākās klases meistaru',
    reviews: 'Absolventu atsauksmes',
    record: 'Pieteikties',
    video_text: 'Kā jūs varat palīdzēt,<br/>ko jūs mācīsiet?',
    ded: {
      consultation: 'konsultācija',
      get_more: 'Uzziniet vairāk informācijas',
    },
    header_bar: {
      main: 'Galvenā',
      get: 'Ko jūs iegūsiet',
      program: 'Kursa programma',
      about: 'Par mums',
      reviews: 'Atsauksmes',
    },
    our_course: {
      head: 'Mūsu<br/>kursi',
      description_1:
        'šī ir vieta, kur vienmēr saņemsiet atbildi uz jautājumu un mācīsieties kaut ko jaunu',
      description_2:
        'Un pat ja jums nav mākslinieciskās izglītības - rezultāts neliks sevi ilgi gaidīt',
      label: '[ pie mums ir silti, mājīgi un aizraujoši ]',
      description_3:
        'Mēs mīlam mūsu studentus un cenšamies izdarīt visu iespējamo, lai mācīšanās process būtu pēc iespējas ērtāks',
    },
    banner: {
      we_teach: 'Mēs tev iemācīsim veikt tetovējumus',
      good_make: 'MĒS VEIDOJAM LIELISKUS TETOVĒJUMUS',
      our_masters: 'MŪSU MEISTARI IR GATAVI DALĪTIES AR ZINĀŠANĀM, NOSLĒPUMIEM, TEHNIKĀM',
      learn_too: 'Un mēs iemācīsim arī tevi',
    },
    modal: {
      thanks: 'Paldies!',
      error: 'Radās kļūda!',
      complete: 'Aizpildiet formu un mēs jums zvanīsim',
      full_name: 'Pilnais vārds',
      phone: 'Telefona numurs',
      submit: 'Iesniegt',
      cancel: 'Atcelt',
    },
    paragraph: {
      header_1: 'Individuālas apmācības',
      content_1: 'Mēs neveicam masveida apmācību',
      header_2: 'spēja zīmēt',
      content_2:
        'Mēs jums visu iemācīsim/nav nepieciešama mākslinieciskā izglītība, tas nav problēma',
      header_3: 'Sertifikāts',
      content_3:
        'Pēc kursu pabeigšanas jūs saņemsiet sertifikātu ar starptautiskiem standartiem. Apstiprināts no Global Tattoo ACC',
      header_4: 'Nodarbinātība',
      content_4:
        'OMG, vai jūs nopietni? Jā, labākie absolventi var kļūt par mūsu komandas daļu un strādāt jebkurā Eiropas vietā',
      header_5: 'Līgums',
      content_5: 'Mēs nestrādājam "uz goda vārda". Viss ir oficiāli un līguma veidā',
      no_sleep: {
        header: 'neizgulēt',
        rect_1: 'Kā?',
        rect_2: 'DZĪVE',
        main_label: 'Sāciet mācīties tūlīt',
        label_1: '1000+ absolventu',
        label_2: '100% pabeidza apmācību',
        label_3: '80% tika nodarbināti',
        label_4: '300+ partneri tetovējumu nozarē',
      },
    },
    principies: {
      head: 'Garantijas',
      label_1: '1. Kultūra',
      label_2: '2. Kvalitāte',
      label_3: '3. Metodes',
      heading: 'Viss ir balstīts uz principiem',
      description_1:
        'Pirms 11 gadiem mēs nolēmām attīstīt tetovējumu nozares kultūru, ne tāpat kā visi, bet savā veidā - balstoties uz zināšanām un pieredzi',
      description_2:
        'Šodien mēs esam apmācījuši 1000+ izcilu tetovējumu meistarus. 80% kļuva par mūsu komandas daļu. Mēs varam droši teikt, ka ikviens var iemācīties tetovējuma mākslu',
      description_3:
        'Viss ir atkarīgs no metodikas, ko mēs izmantojam, ko nepārtraukti uzlabojam kopā ar komandu, māksliniekiem un mūsu absolventiem, kuri regulāri sniedz atsauksmes',
    },
    packages: {
      head: 'Kursu programmas',
      from: 'no',
      plus: 'Priekšrocības',
    },
    facilities: {
      head: 'Iespējas un priekšrocības',
      subhead: 'Izvēlieties mūsu kursus un izjūtiet tetovējumu industrijas burvību',
      card: {
        head_1: 'Katrs mūsu absolvents saņem sertifikātu ar starptautiskiem standartiem',
        label_1: '- jūsu biļete uz iespēju pasauli',
        head_2: 'Ieteikums un atbalsts no profesionālu meistaru grupas',
        label_2: 'Jūsu panākumi ir mūsu lepnums!',
        head_3: 'Tetovējumu kopiena, saņemiet padomus un atbalstu no mūsu ekspertiem',
        label_3: 'Jūsu jaunā dzīve tetovējumu pasaulē sākas tagad!',
      },
    },
    review: {
      head_1: 'Meistaru gadījumu izpēte',
      head_2: 'Atsauksmes',
      comment_1:
        'Tetovējumu apmācība šajā skolā pārsniedza visas manas gaidas! Skolotāji ir savas jomas profesionāļi, katrs apmācības posms tiek rūpīgi apspriests, atmosfēra ir lieliska. Tagad es varu ar pārliecību realizēt savas radošās idejas uz klientu ādas!',
      comment_2:
        'Paldies skolai par tik kvalitatīvu tetovējumu apmācību! Sākumā bija nedaudz biedējoši sākt ar to, bet pateicoties uzmanīgiem un pacietīgiem skolotājiem, es jutos pārliecināts. Tagad mani darbi saņem atzinību un apbrīnu!',
      comment_3:
        'Tas bija neticami ceļojums uz tetovējumu pasauli! Skola sniedz visas nepieciešamās zināšanas un prasmes, lai kļūtu par īstu profesionāli. Esmu pateicīgs par atbalstu un iedvesmu, ko šeit esmu saņēmis',
    },
  },
  questionary: {
    first: {
      heading: 'PIEZĪME PAR PROCEDŪRU VEAN TATTOO STUDIO',
      textbox:
        'Ja klientam ir mazāk par 18 gadiem, piekrišanu jāaizpilda vecākiem vai likumīgiem aizbildņiem (Britu Kolumbijā, Kanādā, par nepilngadīgu tiek uzskatīta persona, kas jaunāka par 19 gadiem. Ja jums ir mazāk par 19 gadiem, lūdzu, izvēlieties OTRO iespēju)',
      has_18: 'JĀ, man ir 18 vai vairāk gadu',
      no_18: 'NĒ, man ir mazāk par 18 gadiem',
    },
    second: {
      warning: 'Uzmanību! Sesijas dienā ir nepieciešams ierasties ar aizbildņiem!',
      name: 'Pilns vārds',
      birthdate: 'Dzimšanas datums',
      address: 'Dzīvesvieta',
      email: 'E-pasts',
      sex: 'Dzimums',
      heading: 'VEICAMĀ PROCEDŪRA',
      man: 'Vīrietis',
      woman: 'Sieviete',
      other: 'Nav norādīts',
      parent_data: 'Dati par vienu no vecākiem:',
      child_data: 'Dati par VIENNOLEĶA personu:',
      child_field_placeholder: 'Dēls, meita, utt.',
      child_field_label: 'Kam tiek veikts process',
      confirm:
        'Es, esot vieno no nepilngadīgā bērna likumīgais pārstāvis, piekrītu turpmākai procedūrai, ko veic manam bērnam.',
    },
    third: {
      heading: 'VISS, KO JŪS VAJAG ZINĀT PAR ĀRSTĒŠANAS PERIODU:',
    },
    fourth: {
      nothing: 'Neviens no iepriekš minētajiem',
      has_diseases: 'Vai jums ir kādas slimības?',
      has_contraindications: 'Vai jums ir kādas kontrindikācijas?',
      heading1:
        'JA JUMS IR KĀDAS NO SEKOJOŠAJĀM SĀPĒM, MUMS JĀZINA, LŪDZU, ATZĪMĒJIE ATBILDES PUNKTI:',
      heading2: 'JA IR KONTRINDIKĀCIJAS, LŪDZU, SNIEGT ATBILSTOŠO INFORMĀCIJU:',
    },
    fifth: {
      heading: 'AIZLIEGTS PĒC PROCEDŪRAS:',
    },
    sixth: {
      heading: 'KUR JŪS PAR MUMS UZZINĀJĀT:',
      label:
        'Pēc 21 dienas ar jums var sazināties viens no mūsu darbiniekiem, lai veiktu sniegto pakalpojumu kvalitātes pārbaudi. Turklāt pēc sesijas jūs saņemsiet SMS paziņojumus, un ir iespējams, ka mūsu automatizētais robots veiks zvanu. Savā personīgajā kontā jūs varat atteikties no paziņošanas pakalpojumiem pa zvanus un SMS, kā arī varat dzēst visus savus datus un fotoattēlus pēc 21 dienas kopš pakalpojuma datuma.',
    },
    seventh: {
      heading: 'IZVĒRTĒJUMS UN PIEKRIŠANA',
    },
    signature: {
      heading:
        'Es, zemakrakstītais (%{name}), ar šo piekrītu šodienas procedūrai ar manu dalību\n' +
        '%{session_type} uz manu ķermeni un piekrītu nosacījumiem, kas minēti šajā dokumentā.',
    },
    next: 'Nākamais',
    submit: 'Iesniegt',
    no_responsibility: 'Studija neuzņemas atbildību par šo sesiju',
    acquaint: 'Es esmu iepazinies ar man sniegto informāciju',
    acquaint_2:
      'Es saprotu, ka Vean Tattoo nav atbildīgs par dziedināšanas procesu.\n' +
      'Gadījumā, ja tiek veikta iepriekš minētā tetovējuma vai pīrsinga korekcija, korekcijas izmaksas būs 30% no pašas pakalpojuma cenas.\n' +
      'Ja jūs neapmierina korekcijas rezultāts, šajā gadījumā līdzekļi par pakalpojumu netiek atgriezti, jo iepriekš minētās zonas nav labvēlīgas pašam pakalpojumam.',
  },
  choices: {
    problem_situation: {
      no_solution: 'Nav risinājuma',
      in_check: 'Pārbaudē',
      problem_solved: 'Problēma atrisināta',
      wait: 'Gaidīšana',
      small: 'Neliela situācija',
      unanswered_check: 'Pārbaude bez atbildes',
    },
  },
  master_page: {
    card: {
      active: 'Aktīvs',
      not_active: 'Neaktīvs',
      master_since: 'Meistars VEAN kopš',
      services: 'Pakalpojumi',
      styles: 'Stili',
      book: 'Rezervēt',
    },
    information: {
      top_works: 'Labākie darbi',
      awards_and_diplomas: 'Balvas un diplomi',
    },
  },
  stripe_warning:
    'Uzmanību! Minimālā maksājuma summa, izmantojot stripe, ir 20 €. Ja nepieciešama mazāka summa, lūdzu, sazinieties ar studiju, lai saņemtu informāciju',
  check_in: {
    rate: {
      upload_photo: 'Augšupielādējiet sava darba fotoattēlu',
      upload_photo_desc:
        'Jūs varat uzņemt skaistu sava darba fotoattēlu mūsu sociālajiem tīkliem un vietnei. Administrators arī uzņem fotoattēlu kvalitātes kontrolei un veikto darbu garantijai. Jūs vienmēr varat atteikties no attēla publicēšanas un kopīgošanas, un jūsu dati tiks pilnībā izdzēsti pēc dziedināšanas.',
      bad_comment: 'Lūdzu, paskaidrojiet, kāpēc jūs devāt zemu vērtējumu',
      review: 'Atstājiet atsauksmi!',
      review_desc:
        'Mēs būsim ļoti pateicīgi, ja atstāsiet atsauksmi. Jūsu viedoklis mums ir svarīgs!',
      cashback: 'x2 atmaksa uz jūsu kontu lietotnē',
      send_invoice: 'Nosūtīt rēķinu uz e-pastu',
      influencer: 'Vai jums ir vairāk nekā 2000 sekotāju sociālajos tīklos?',
    },
    welcome_message: 'VĒLAM JUMS PATĪKAMU SESIJU!',
    session_started: 'JŪSU SESIJA IR SĀKUSIES',
    next_steps: 'Pēc sesijas pabeigšanas jums būs pieejami citi soļi',
    buy_healing_product: 'IESAKĀM IEGĀDĀTIES ĀRSTĒŠANAS LĪDZEKLI',
    skip: 'Izlaist',
    leave_review: 'Lūdzu, atstājiet atsauksmi',
    your_impression: 'Kādi ir jūsu vispārējie iespaidi?',
    add_photo: 'Pievienojiet darba fotoattēlu, lai iegūtu papildu 1 Vean Coin',
    send: 'Sūtīt',
    payment_success: 'Maksājums bija veiksmīgs',
    get_invoice: 'Vai vēlaties saņemt rēķinu?',
    invite_friend: 'Uzaicināt draugu',
    recommend_us: 'Iesakiet mūs saviem draugiem un pelniet kopā ar viņiem',
    thank_employees: 'Mēs arī iesakām pateikties mūsu darbiniekiem',
    you_can_tip:
      'Dārgais %{name}, jūs varat arī pateikties mūsu darbiniekiem ar noteiktu summu dzeramnaudas',
    own_amount: 'Pašu summa',
    who_to_tip: 'Kam vajadzētu piešķirt dzeramnaudu?',
    payment_type: 'Maksājuma veids',
    continue: 'Turpināt',
    summary: 'Kopsavilkums',
    healing_agent: 'Ārstniecības līdzeklis',
    tips: 'Dzeramnauda',
    session_payment: 'Sesijas maksājums',
    total: 'Kopā',
    waiting: 'Gaidīšana...',
    available_funds: 'Pieejamie līdzekļi',
    refill_balance: 'Papildināt bilanci',
    go_to_payment: 'Doties uz maksājumu',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Dzeramnauda',
    prepayment: 'Avanss',
    have_questions: 'Jautājumi?',
    title: 'Sesijas',
    selectStatus: 'Izvēlieties statusu',
    selectParlor: 'Izvēlieties salonu',
    loading: 'Ielāde...',
    fromDate: 'No datuma',
    toDate: 'Līdz datumam',
    search: 'Meklēt',
    all: 'Visi',
    status: {
      100: 'Gaidīšana',
      200: 'Jauns',
      300: 'Procesā',
      400: 'Pabeigts',
      500: 'Atcelts',
      600: 'Gaidām maksājumu',
    },
    details: 'Sesijas detaļas',
  },
  master: {
    rank: 'Rangs',
    works_count: 'Darbu skaits',
    address: 'Adrese',
  },
  modalPhone: {
    title: 'Uzmanību!',
    info: 'Cienījamais klients, jūsu drošībai un efektīvai mūsu pakalpojuma izmantošanai ir obligāti jāievada visi dati. Tas palīdzēs mums savlaicīgi reaģēt uz svarīgiem jautājumiem un nodrošināt jūsu aizsardzību.',
    reminder: 'Atgādini man vēlāk',
    go_to_settings: 'Iet uz iestatījumiem',
    roughly_price: 'Aptuvenā cena ir atkarīga no jūsu norādīto datu precizitātes',
    success_message: 'Paldies!',
  },
  studyingRegistration: {
    become_student: 'Kļūsti par studentu',
    step_1: 'Vispārīgi dati',
    step_2: 'Personas informācija',
    step_3: 'Studiju detaļas',
    city: 'Pilsēta',
    city_placeholder: 'Ievadiet savu pilsētu',
    name: 'Vārds',
    name_placeholder: 'Ievadiet savu vārdu',
    last_name: 'Uzvārds',
    last_name_placeholder: 'Ievadiet savu uzvārdu',
    date_of_birth: 'Dzimšanas datums',
    date_of_birth_placeholder: 'Ievadiet savu dzimšanas datumu',
    phone: 'Tālruņa numurs',
    email: 'E-pasts (nav obligāti)',
    email_placeholder: 'Ievadiet savu e-pastu',
    social_media: 'Sociālie tīkli',
    social_media_placeholder: 'Saite uz jūsu profilu',
    referral_source: 'Kā uzzinājāt par mums?',
    referral_source_placeholder: 'Izvēlieties avotu',
    study_type: 'Mācību veids',
    study_type_placeholder: 'Izvēlieties mācību veidu',
    start_date: 'Paredzamā uzsākšanas datums',
    start_date_help: 'Ievadiet paredzamo uzsākšanas datumu',
    hours_number: 'Stundas skaits',
    practice_number: 'Praktiku skaits',
    details: 'Detaļas',
    education_price: 'Izglītības cena',
    moto: 'Mācību metode, kas pārbaudīta gadu desmitos',
    description: 'Apraksts',
    material: 'Materiāls',
    duplicating_phone_number: "Students ar šo tālruņa numuru ir reģistrēts"
  },
  studying_main: {
    become_student: 'Kļūsti par studentu!',
    consultation: 'Konsultācija',
    want_to_study: 'Es gribu mācīties!',
    consult_with_our_advisor: 'Nezini, ar ko sākt? Sazinieties ar mūsu konsultantu!',
  },
  errorTicket: {
    error_description: 'Ziņojiet par lietotnes problēmu, un mēs to ātri novērsīsim',
    error_button: 'Ziņojiet par lietotnes nepareizu darbību',
    problem_situation_description: 'Vai pēc sesijas radās problēma? Paziņojiet mums',
    problem_session_button: 'Ziņot par problēmu pēc sesijas',
    problem_session: 'Ziņot par problēmu pēc sesijas',
    sidebar_title: 'Ziņot par problēmu',
    main_title: 'Kļūdu ziņojuma izveide',
    basic_info:
      'Izveidojot biļeti, ir svarīgi sniegt visu nepieciešamo informāciju, lai ātri un efektīvi apstrādātu jūsu pieprasījumu. Šeit ir parauga ziņojuma struktūra, kas var palīdzēt:',
    ticket_title: 'Biļetes nosaukums:',
    short_description: "Problēmas īss apraksts (piemēram, 'Problēmas ar sesiju izveidi')",
    first_title: 'Problēmas apraksts',
    detailed_description: 'Sīkāks apraksts:',
    problem_description: 'Aprakstiet problēmas būtību, kas tieši notiek un kāda ir problēma.',
    reproduction_steps: 'Problēmas reproducēšanas soļi:',
    reproduction_steps_description: 'Uzskaitiet soļus, kas noved pie problēmas.',
    expected_result: 'Gaidāms rezultāts:',
    expected_result_description: 'Ko jūs gaidījāt saņemt vai redzēt?',
    actual_result: 'Faktiskais rezultāts:',
    actual_result_description: 'Kas notika, nevis gaidītais rezultāts?',
    important_data: 'Svarīgi dati',
    links: 'Saites:',
    links_description: 'Norādiet saites uz problēmatiskajām lapām vai dokumentiem.',
    photos_and_screenshots: 'Fotogrāfijas un ekrānuzņēmumi:',
    photos_and_screenshots_description:
      'Pievienojiet attēlus, kas var palīdzēt vizualizēt problēmu.',
    session_numbers: 'Sesiju numuri:',
    session_numbers_description: 'Norādiet sesiju numurus, kurās radās problēma.',
    additional_info: 'Papildu informācija:',
    additional_info_description: 'Iekļaujiet papildu informāciju, kas varētu būt noderīga.',
    priority_and_urgency: 'Prioritāte un steidzamība:',
    priority_and_urgency_description:
      'Ja problēma būtiski ietekmē jūsu darbu, norādiet to šeit, lūdzot augstu prioritāti.',
    issue_title: 'Nosaukums',
    issue_title_placeholder: 'Ievadiet īsu problēmas aprakstu',
    description: 'Apraksts',
    description_placeholder: 'Sīkāk aprakstiet problēmu',
    files: 'Faili',
    drag_and_drop: 'Noberiet failu šeit vai izvēlieties',
    uploaded_files: 'Augšupielādētie faili',
    create_button: 'Izveidot',
    problem_type: 'Problēmas veids',
    select_problem_type: 'Izvēlieties problēmas veidu',
    technical: 'Tehnisks',
    non_technical: 'Netehnisks',
    loading: 'Ielādē...',
    main_page: 'Iet uz galveno lapu',
    checkbox: 'Jums jāapstiprina piekrišana visiem punktiem.',
  },
  multistep: {
    basic_info: 'Pamatdati',
    type: 'Tips',
    summary: 'Kopsavilkums',
    details_rent: 'Nomāšanas detaļas',
    confirmations: 'Apstiprinājumi',
    open_google: 'Atvērt Google Maps',
  },
  check_in_view: {
    important: 'Svarīgi',
    log_in_state_greet: 'Pierakstieties savā kontā, lai sekotu līdzi saviem bonusiem un atlaidēm.',
    log_in: 'Pieteikties',
    download_app_greet: 'Lejupielādējiet mūsu lietotni un saņemiet bonusu',
    download: 'Lejupielādēt',
    welcome: 'Laipni lūdzam',
    studio_info: 'Studijas informācija',
    recommended_products: 'Ieteicamie produkti',
  },
  toast: {
    certificate_success: 'Jūsu sertifikāts ir nosūtīts uz jūsu e-pastu',
  },
  tattoo_removal: {
    title: 'LASERS / BALINĀŠANA',
    registration_description: 'Izvēlieties ērtu datumu un laiku pierakstam dažu klikšķu laikā',
    registration_button: 'Ātra reģistrācija balināšanas sesijai',
    consultation_description: 'Vai vēlaties precizēt detaļas? Mēs labprāt palīdzēsim!',
    consultation_button: 'Konsultācija',
    header_nav_title: 'Tattoo balināšana',
    choose_service: 'Pakalpojuma veids',
    choose_service_placeholder: 'Izvēlieties pakalpojuma veidu',
    paste_tattoo: 'Pievienojiet fotogrāfiju par noņemšanu / balināšanu (nav obligāti)',
    choose_size: 'Izmērs',
    choose_size_placeholder: 'Izvēlieties izmēru',
  },
  success_form: {
    go_personal_acc: 'Iet uz savu personīgo kontu',
    dont_have_acc: 'Nav konta?',
    sign_up: 'Reģistrējieties!',
  },
  loyalty_card: {
    client_card: 'Klienta karte',
    card_number: 'Kartes numurs',
    card_photo: 'Kartes foto',
  },
  discount: {
    first_session: 'atlaide pirmajai sesijai',
    second_session: 'atlaide otrajai sesijai',
  },
  days: {
    MON: 'P',
    TUE: 'O',
    WED: 'T',
    THU: 'C',
    FRI: 'P',
    SAT: 'S',
    SUN: 'S',
  },
  calculate_price: {
    label: 'Lai uzzinātu cenu',
    color_options: {
      black_and_white: 'Melns un balts',
      colored: 'Krāsains',
    },
    additional: 'Papildu',
    select_location: 'Izvēlieties atrašanās vietu',
    select_style: 'Izvēlieties stilu',
    select_size: 'Izvēlieties izmēru',
    select_colored: 'Krāsa',
    select_parlor: 'Izvēlieties salonu',
    select_service: 'Izvēlieties pakalpojumu',
    cost: 'Cena',
    from: 'No',
    to: 'Līdz',
    book: 'Rezervēt tikšanos',
    no_info: 'Nav informācijas',
  },
};
