import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';

type TCreateCartData = {
  customer: number,
  item: number,
  quantity: number,
  referral_user?: number
}
const createCart = async (data: TCreateCartData) => {
  const response = await axios.post(`${APIURL}/v1/customer_cart/`, data);
  return response.data;
};

const createCartBulk = async (data: TCreateCartData[]) => {
  const response = await axios.post(`${APIURL}/v1/customer-cart/bulk/`, data);
  return response.data;
};


type TEditCart = {
  quantity: number,
  status?: 'canceled',
}
const editCart = async (
  id: number, // Cart id
  data: TEditCart,
) => {
  const response = await axios.post(`${APIURL}/v1/customer_cart/${id}/`, data);
  return response.data;
};

export {
  createCartBulk,
  createCart,
  editCart,
};