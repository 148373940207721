import { plugin, defaultConfig, createInput } from '@formkit/vue';
import FormKitSignature from '@/components/FormKit/FormKitSignature.vue';
import FormkitPhone from '@/components/FormKit/FormKitPhone.vue';
import FormKitRate from '@/components/FormKit/FormKitRate.vue';
import FormKitToggleButton from '@/components/FormKit/FormKitToggleButton.vue';
import i18n from '@/locales/index';
import { ru, en, uk, pl, es, cs, de, nl, hu, bg, sk } from '@formkit/i18n';
import { createMultiStepPlugin } from '@formkit/addons';
// @ts-ignore
import { generateClasses } from '@formkit/themes';
import { customerApi } from '@/api/customer';
import { FormKitNode } from '@formkit/core';
import { FormKitValidationRule } from '@formkit/validation';
import { FormKitFile } from '@formkit/inputs';
import parsePhoneNumber from 'libphonenumber-js'
import FormKitFileS3 from '@/components/FormKit/FormKitFileS3.vue';

const maxSize: FormKitValidationRule = (node: FormKitNode, mbSize = 5) => {
  const bMaxSize = mbSize * 1024 * 1024;

  const fNode = node as FormKitNode<FormKitFile[]>;

  const filesSizes = fNode.value.reduce((acc, el) => acc + (el?.file?.size || 0), 0);

  return filesSizes < bMaxSize;
};

const uniqueCustomerEmail: FormKitValidationRule = async (node: FormKitNode, excludeId = null) => {
  const { count } = await customerApi.customer.getCustomersCount({
    email: String(node.value),
    exclude_id: excludeId,
  });
  return !count;
};

const uniqueCustomerPhoneNumber: FormKitValidationRule = async (node: FormKitNode, excludeId = null) => {
  const { count } = await customerApi.customer.getCustomersCount({
    phone_number: String(node.value),
    exclude_id: excludeId,
  });
  return !count;
};

const validatePromocode: FormKitValidationRule = async (node: FormKitNode) => {
  const value = node.value as string;
  try {
    await customerApi.promocode.validatePromoCode(value);
    return true;
  } catch {
    return false;
  }
};

const phone: FormKitValidationRule = (node: FormKitNode) => {
  const phoneNumber = parsePhoneNumber((node.value as string) || '')
  return !!phoneNumber?.isValid()
}

uniqueCustomerEmail.debounce = 500;
uniqueCustomerPhoneNumber.debounce = 500;

const formKitConfig = defaultConfig({
  // @ts-ignore
  plugins: [createMultiStepPlugin()],
  rules: { uniqueCustomerEmail, uniqueCustomerPhoneNumber, maxSize, validatePromocode, phone },
  inputs: {
    signature: createInput(FormKitSignature),
    phoneNumber: createInput(FormkitPhone),
    tgb: createInput(FormKitToggleButton, {
      props: ['options'],
    }),
    rate: createInput(FormKitRate, {
      props: ['count', 'icon', 'iconActive'],
    }),
    fileS3: createInput(FormKitFileS3)
  },
  config: {
    classes: generateClasses({
      rate: {
        inner: 'formkit-inner-rate',
      },
      tgb: {
        inner: 'formkit-inner-rate',
      },
    }),
  },
  locales: { ru, en, uk, pl, es, cs, de, nl, hu, bg, sk },
  locale: i18n.global.locale.value,
});
export { plugin, formKitConfig };
