import { AxiosError } from 'axios';

const extractPrimitiveValues = (data: any): string[] => {
  if (data === null || typeof data !== "object") {
    return [String(data)];
  }

  return Object.values(data).flatMap(extractPrimitiveValues);
};


const getAxiosErrors = (e: AxiosError): string => {
  //@ts-ignore
  const msg = e.response?.data?.message || 'Undefined error: '
  //@ts-ignore
  const errors = extractPrimitiveValues(e.response?.data?.errors ?? e.response.data ?? '')
  const returnMessage = `${msg}\n${errors.join('\n')}`
  console.log(returnMessage)
  return returnMessage
}

export {getAxiosErrors}