import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';

const getParlors = () => {}


type getNearbyParlorListParams = {
  lat: number;
  lng: number;
  radius?: number;
}

const getNearbyParlorList = async (params: getNearbyParlorListParams): Promise<{id: number, name: string, distance: number}[]> => {
  const response = await axios.get(`${APIURL}/v2/core/parlors/nearby/`, {params})
  return response.data
}


export {
  getParlors,
  getNearbyParlorList,
}