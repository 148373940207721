export default {
  candidate_form: {
    interested: 'O akú pracovnú pozíciu máte záujem?',
    select_vacancy: 'Vyberte si pracovnú pozíciu',
    first_name: '*Meno',
    last_name: '*Priezvisko',
    phone: 'Telefónne číslo',
    email: 'E-mail',
    about_you: 'O vás (predchádzajúce pracovné skúsenosti, dosiahnuté úspechy, zručnosti atď.) EN/UK',
    social_media_link: '*Odkaz na sociálne siete (Instagram/Facebook)',
    language_skill: 'Akými jazykmi hovoríte a na akej úrovni?',
    select_country: 'Vyberte krajinu',
    select_city: 'Vyberte mesto',
    desired_salary: 'Aký plat alebo % si želáte?',
    why_we: 'Prečo chcete pracovať s nami?',
    desired_work_place: 'Požadované miesto práce, umiestnenie štúdia',
    relocation_question: 'Ste ochotný ísť na dlhšie služobné cesty alebo sa presťahovať do iných miest/krajín na dlhšie obdobie (viac ako 3 mesiace)?',
    your_services: 'Vaše služby',
    your_styles: 'Vaše štýly',
    portfolio: 'Vaše portfólio (minimálne 5 fotografií!)',
    study_place: 'Kde ste študovali?',
    hygiene_certificates: 'Máte hygienické certifikáty / zdravotnú knižku?',
    upload_cv: 'Váš životopis',
    thank_you: 'Ďakujeme!',
    success_message: 'Dostali sme vašu žiadosť. Budeme vás kontaktovať, akonáhle budeme mať vhodnú ponuku.',
    file_size_error: 'Celková veľkosť súborov nesmie presiahnuť 25 MB.'
  },
  veancoins: {
    summ: 'Suma bonusov',
    customer_bonus: 'Bonus zákazníka',
    vip_gift: 'VIP darček',
    vip_price: 'Minimálna VIP suma',
    cash_back_sells: 'Cashback za predaj',
    cash_back_certificate: 'Cashback za certifikát',
    cash_back_student: 'Cashback za školenie',
    cash_back_service: 'Cashback za službu',
    cash_back_money: 'Cashback za dobitie zostatku',
  },
  support: {
    problem_situation_link: 'Mám problém po sedení',
    other_problem_link: 'Mám iný problém',
    problem_situation: {
      header: 'Problém po sedení',
      form: {
        session: 'Sedenie',
        created_by_comment: 'Opíšte problém',
      },
    },
    complain: {
      header: 'Sťažnosti a návrhy',
      form: {
        name: 'Meno',
        phone_number: 'Telefónne číslo',
        email: 'E-Mail',
        parlor: 'Salón',
        description: 'Opíšte problém',
        feedback: {
          overall_quality: 'Ako hodnotíte celkovú kvalitu služieb v našom salóne?',
          professional_skills: 'Boli ste spokojní s odbornými zručnosťami majstra?',
          staff_politeness: 'Ako zdvorilý a pozorný bol personál salónu?',
          waiting_time: 'Boli ste spokojní s čakacou dobou na svoju službu?',
          staff_professionalism: 'Ako hodnotíte profesionalitu nášho personálu?',
          salon_cleanliness: 'Ako hodnotíte čistotu a pohodlie v našom salóne?',
          service_value: 'Myslíte si, že cena služieb zodpovedá ich kvalite?',
          recommend: 'Odporučili by ste náš salón svojim priateľom a známym?',
        },
      },
    },
  },
  create_credit: {
    credit_target: 'Na čo je úver',
    type_education: 'Typ vzdelania',
    type_service: 'Typ služby',
    credit_type: 'Typ úveru',
    how_to_contact: 'Ako vás kontaktovať',
    note: 'Komentár',
  },
  items_view: {
    header: 'Produkty',
    filter_form: {
      name_placeholder: 'Názov',
      category_placeholder: 'Kategória',
      parlor_placeholder: 'Salón',
      all_categories: 'Všetky kategórie',
      all_parlors: 'Všetky salóny',
    },
    load_more_button: 'Načítať viac',
    not_found_message: 'Nič sa nenašlo',
  },
  item_view: {
    error_message: 'Chyba pri načítaní údajov',
    available_in_parlor: 'Dostupné vo vašom salóne',
    not_available_in_parlor: 'Nie je k dispozícii vo vašom salóne',
    similar_items_label: 'Podobné produkty',
    log_in_to_buy: 'Prihlásiť sa na nákup',
  },
  item_simple_card: {
    studio_price_label: 'Cena v štúdiu',
    shop_price_label: 'Cena v obchode',
  },
  student_candidate: {
    form: {
      who_studing: 'Študent alebo prenájom miesta',
      phone_number: 'Telefónne číslo',
      first_name: 'Krstné meno',
      last_name: 'Priezvisko',
      social_link: 'Odkaz na sociálnu sieť',
      parlors: 'Štúdio',
      couch: 'Tetovacie kreslo',
      find_out: 'Ako ste sa o nás dozvedeli?',
      start_date: 'Želaný dátum začiatku',
      types: 'Typy sedení',
      work_permit_document: 'Dokument o pracovnom povolení',
      sanitary_book: 'Zdravotná kniha',
      identity_proof: 'Doklad totožnosti',
      work_photos: 'Fotografie prác',
      end_date: 'Želaný dátum ukončenia',
      confirmations: 'Potvrdzujem, že',
      know_buy_material: 'Viem, že si môžem kúpiť materiály v štúdiu',
      customer_will_complete_agreement: 'Každý môj klient podpíše dohodu',
      can_show_documents: 'Môžem v štúdiu poskytnúť originálne dokumenty, ak je to potrebné',
      has_country_permission: 'Mám povolenie na pobyt v krajine',
      has_san_book: 'Mám zdravotnú knihu',
      has_passport: 'Mám doklad totožnosti (ID)',
      conditions: 'Oboznámil som sa s podmienkami prenájmu',
    },
  },
  user: {
    referral_links: {
      booking: 'Rezervácia sedení',
      student_candidate: 'Prenájom miesta',
      store: 'Klientsky obchod',
      candidates_form: 'Pozvať zamestnanca',
    },
  },
  products: {
    want_add: 'Chcem pridať vybrané produkty starostlivosti k cene sedenia',
    no_warranty: '*bez tejto možnosti nemôžeme zaručiť 100% úspešné hojenie',
    button_add: 'Pridať',
    souvenir: 'Suveníry',
    pay_vc: 'Zaplatiť VEANCOINS po sedení',
  },
  healing_questionary: {
    composable_table: {
      heading: 'Použité spotrebné materiály',
      name: 'Názov',
      count: 'Množstvo',
    },
  },
  booking: {
    more_interest: 'Ešte viac zaujímavostí',
    active_promo: 'Aktuálne akcie',
    cashback:
      'Cashback\n' +
      '          <span class="text-warning">%{bonus}%</span>\n' +
      '          za <span class="text-success">online</span> rezerváciu',
    fast_booking: 'Jednoduchá rezervácia',
    develop_sketch: 'Vývoj náčrtu',
    fast_booking_warning:
      'Rezervácia nie je konečná, náš manažér vás bude kontaktovať, aby potvrdil čas a dátum sedenia',
    phone_annotate:
      'Aby náš predajný tím mohol reagovať rýchlejšie, prosím, poskytnite svoje miestne kontaktné číslo. Ďakujeme!',
    increased_risk: 'Pozor! Sedenie s vysokým rizikom',
    increased_risk_extended: 'Vybraný typ sedenia a miesto na tele majú zvýšené riziko',
    price_warning: 'Dodatočné produkty nie sú zahrnuté',
    approximate_price: 'Približná cena',
    type: {
      info: 'Pohodlný formulár na rezerváciu sedenia so všetkými potrebnými informáciami',
      booking_button: 'REZERVOVAŤ SEDENIE',
      booking_button_promo: 'S 15% CASHBACKOM VO VEAN COINS',
      unsure: 'Ešte ste sa nerozhodli? Radi vám poradíme!',
      consultant_help: 'Naši konzultanti vám radi pomôžu',
      consultation_button: 'KONZULTÁCIA',
      simplified:
        'Rezervujte si sedenie rýchlo a pohodlne! Vyberte si dátum, čas a službu na pár kliknutí',
    },
    color: {
      black: 'Čierna',
      colored: 'Farebná',
    },
    controls: {
      next: 'ĎALEJ',
      back: 'Späť',
      no_select: 'Nevybrané',
    },
    place_service: {
      select_studio: 'Vyberte štúdio',
      service: 'Služba',
      size: 'Veľkosť',
      location: 'Umiestnenie',
    },
    customer_info: {
      booked_from: 'Ako ste si rezervovali termín?',
      login_prompt: 'Pre pokračovanie sa prihláste do účtu',
      login_button: 'Prihlásiť sa',
      black_white_colored: 'Čierna a biela alebo farebná?',
      select_style: 'Vyberte štýl',
      tell_more:
        'Povedzte nám viac o vašich želaniach týkajúcich sa tetovania a podeľte sa s nami o príklady tetovaní (referencie)',
      login_promo: 'Pri prihlásení Cashback 10%',
      wish: 'Želanie',
    },
    date_time: {
      choose_time: 'Vyberte čas návštevy',
    },
    master_card: {
      master_unavailable: 'Majster nie je dostupný v tomto čase',
      master_guest_warning: 'POZOR! Hosťujúci majster',
      rank: 'Hodnotenie:',
      works_count: 'Počet prác:',
      book_now: 'Rezervovať',
      select_another_time: 'Vyberte iný čas',
    },
    success: {
      session: 'SEDENIE',
      become_mystery_shopper: 'Stať sa tajným nakupujúcim',
      add_to_calendar: 'Pridať udalosť do kalendára',
      call_on_day: '*v deň sedenia vám zavolá administrátor štúdia',
      return_to_menu: 'Vrátiť sa do menu',
      recommended_products: 'Odporúčané produkty na hojenie',
    },
    black_friday: '50% zľava pri povinnom vklade',
  },
  tips: {
    placeholder: 'Vyberte, ako rozdeliť sprepitné',
    divide_by_all: 'Rovnomerne',
    administrator: 'Administrátorovi',
    parlor: 'Štúdiu',
    master: 'Majstrovi',
  },
  new_landing: {
    count_block: {
      countries: 'Počet krajín',
      studios: 'Štúdiá',
      awards: 'Ocenenia',
      masters: 'Majstri',
    },
    header_banner: {
      label_1: 'Nerobíme z komára somára',
      label_2: 'robíme tetovania!',
      book_now: 'Rezervovať',
      consultation: 'Konzultácia',
      prices: 'Cenník',
      top_works: 'Top práce',
      advantages: 'Výhody',
      change_parlor: 'Vyberte mesto',
    },
    course_card: {
      theory_part: 'Teoretická časť:',
      practice_part: 'Praktická časť:',
      advance: 'Dodatočné výhody:',
    },
    helpful_links: {
      for_masters: 'Pre majstrov',
      tattoo_news: 'Novinky zo sveta tetovania',
      tattoo_news_description: 'Buďte informovaní o najdôležitejších udalostiach zo sveta tetovaní',
      vean_tattoo_school: 'VEAN TATTOO ŠKOLA',
      vean_tattoo_school_description:
        'Naši majstri sú pripravení zdieľať svoje znalosti, tajomstvá, techniky',
      rent_space: 'Prenájom miesta',
      rent_space_description:
        'Zistite o možnostiach spolupráce na prenájom pracovného miesta v štúdiách VEAN',
      tattoo_promotions: 'Akcie a promo',
      tattoo_certificates: 'Darčekové certifikáty',
      consumables: 'Spotrebný materiál',
      equipment: 'Vybavenie',
    },
    price_block: {
      title: 'Cenník',
      loyalty_program: {
        title: 'Vernostný program',
      },
      actions: {
        book_now: 'Rezervovať',
        calculate_cost: 'Vypočítať cenu',
      },
    },
    top_works: {
      title: 'Top práce',
      from_the_world: 'z celého sveta',
    },
    why_vean: {
      title: 'Prečo VEAN',
      individual_approach: 'Individuálny prístup',
      trust: 'Dôvera',
      safety_guarantee: 'Záruka bezpečnosti',
      convenient_location: 'Pohodlné umiestnenie',
      visited_studio: 'Ľudí navštívilo toto štúdio',
    },
  },
  mystery_shopper: {
    title: 'Tajný nakupujúci',
    description:
      'Tajný nakupujúci je osoba, ktorá kontroluje prácu salónu ako bežný zákazník. Hodnotí úroveň služieb v salóne.',
    offer: 'Ak sa rozhodnete stať tajným nakupujúcim na toto sedenie, darujeme vám VeanCoins',
    instructionTitle: 'Inštrukcie',
    instructionDetails:
      'Počas sedenia budete musieť hodnotiť salón a personál podľa rôznych kritérií. Po sedení vám príde SMS s odkazom na dotazník, ktorý budete musieť vyplniť (len úprimne!)',
    question: 'Chcete sa stať tajným nakupujúcim?',
    yesButton: 'Áno',
    noButton: 'Nie, ďakujem',
    successMessage: 'Budete musieť hodnotiť štúdio podľa nasledujúcich kritérií:',
    criteria1: 'Konzultácia po telefóne a v sociálnych sieťach',
    criteria2: 'Konzultácia v štúdiu po príchode',
    criteria3: 'Cenová politika štúdia',
    criteria4: 'Exteriér: Fasáda štúdia, vývesky, umiestnenie atď.',
    criteria5: 'Interiér štúdia, prostredie',
    criteria6: 'Sortiment produktov, suvenírov a hojenia (vitrína)',
    criteria7: 'Atmosféra: TV, hudba, kolektív',
    criteria8: 'Dostupnosť občerstvenia, voľnočasové aktivity, časopisy a katalógy návrhov',
    criteria9: 'Komunikácia s majstrom',
    criteria10: 'Komunikácia s administrátorom',
  },
  cookies: {
    text: `Používame súbory cookie na zlepšenie vášho prehliadania, 
      zobrazovanie personalizovanej reklamy alebo obsahu, 
      ako aj na analýzu našej návštevnosti. Kliknutím na „Prijať“, 
      súhlasíte s používaním súborov cookie.`,
    confirm: 'Prijať',
    more: 'Viac informácií',
    manage: 'Nastaviť',
    config: {
      necessary: 'Povinné',
      preferences: 'Preferencie',
      statistics: 'Štatistiky',
      marketing: 'Marketingové',
    },
  },
  header_menu: {
    logout: 'Odhlásiť sa',
    main: 'Hlavná',
    about_us: 'O nás',
    discount_page: 'Akcie',
    loyalty_page: 'Vernostný systém',
    ai_page_link: 'Vygenerovať návrh',
    fonts_page_link: 'Tetovanie písma',
    quality_certificate: 'Certifikáty kvality',
    complaints: 'Sťažnosti a návrhy',
    public_offer_link: 'Verejná ponuka',
    confidence: 'Politika dôvernosti',
    work: 'Chcem pracovať vo VEAN',
    dockuments_page_link: 'Dokumenty',
    login: 'Prihlásenie',
    account: 'Osobný účet',
    studying: 'Chcem sa učiť!',
    buy_certificate: 'Kúpte si certifikát',
    feedback: 'Spätná väzba',
    loan: 'Plaťte na splátky!',
    services: 'Služby',
    parlor_social_links: 'Sociálne odkazy salónu a kontaktné informácie',
    global_social_links: 'Globálne sociálne odkazy а kontaktné informácie',
  },
  post: {
    welcome: 'Dobrý deň. Boli ste u nás na sedení',
    no_answer_questions: 'Neodpovedali ste na tieto otázky:',
    point: 'Prosím, ohodnoťte kvalitu služieb a získajte VeanCoin!',
    send: 'Poslať',
    error: 'Došlo k chybe!',
    not_found: 'Sedenie nebolo nájdené!',
    not_call: 'Nepotrebujem spätný hovor',
    thank: 'Ďakujeme za hodnotenie!',
    bot: 'O 21 dní vám zavolá náš bot z neznámeho čísla, aby potvrdil informácie o poskytnutej službe a hojení.',
    certificate: 'Pre vás bol vygenerovaný propagačný certifikát v hodnote %{gift_price} VeanCoin',
    social_networks: 'Prosím, zanechajte nám recenziu na sociálnych sieťach:',
    month: 'Využite to do jedného mesiaca, nezabudnite pripomenúť administrátorovi!',
    dissatisfied: 'Nie ste spokojní s poskytnutou službou? Vašu žiadosť spracujeme čo najskôr.',
    help_us_improve:
      'Pomôžte nám zlepšiť sa odpovedaním na otázky a získajte <span class="text-warning">{bonusAmount}</span> VEANCOINS!',
    session_price: 'Zaplatené za sedenie',
    session_additional: 'Zaplatené za dodatočné nákupy',
    session_tips: 'Sprepitné',
    session_total: 'Spolu',
    loyalty_help: 'Registrácia do vernostného programu je k dispozícii na nasledujúcej stránke. Pripojte sa, aby ste využili špeciálne ponuky a systém získavania bonusov.',
    scores: {
      variants: {
        great: 'Skvelé',
        good: 'Dobré',
        satisfactorily: 'Spokojne',
        bad: 'Zlé',
        fast: 'Rýchlo',
        orally: 'Ústne',
        in_writing: 'Písomne',
        orally_in_writing: 'Ústne a písomne',
        yes: 'Áno',
        no: 'Nie',
        didnt_listen: 'Neslyšal(a)',
        not_now: 'Nie teraz',
        book_by_operator: 'Rezervovať cez operátora',
        book_by_yourself: 'Rezervovať si sami s bonusmi',
      },
      administrator:
        'Ako hodnotíte konzultáciu administrátora štúdia ohľadom služieb, majstrov a starostlivosti?',
      recommendations: 'Dostali ste jasné odporúčania na starostlivosť?',
      master_contact: 'Poskytol vám majster svoje kontaktné údaje?',
      holl: 'Ako hodnotíte komfort čakania na recepcii (prostredie, čistota, pohodlie)?',
      price: 'Ako hodnotíte cenu služieb v štúdiu?',
      callback: 'Ako rýchlo ste dostali spätnú väzbu zo štúdia pri registrácii na sedenie?',
      master: 'Ako hodnotíte majstra v štúdiu?',
      care: 'Ponúkli vám nákup profesionálnych starostlivostných prostriedkov alebo šperkov?',
      buy_other: 'Kúpili ste šperky alebo starostlivostné prostriedky?',
      need_contact: 'Potrebujete, aby sme sa s vami skontaktovali?',
      loyalty_program: 'Účastníte sa už vernostného programu?',
      sketch: 'Ponúkli vám objednať individuálny náčrt/projekt?',
      souvenir: 'Ponúkli vám kúpiť suveníry alebo tovar s logom?',
      repeat_record: 'Chcete sa znovu zaregistrovať?',
      price_correct: 'Zaplatili ste uvedenú celkovú sumu?',
      price_correct_amount: 'Zadajte sumu, ktorú ste zaplatili',
      tips: 'Zanechali ste sprepitné?',
      studio: 'Ako hodnotíte prácu štúdia?',
      master_note: 'Popíšte problém',
      record_date: 'Dátum, kedy vám bude pohodlne kontaktovať',
      record_time: 'Čas, kedy vám bude pohodlne kontaktovať',
      record_comment: 'Váš komentár k rezervácii',
    },
    scores_mystery_shopper: {
      instagram: 'Hodnotenie nášho INSTAGRAMU',
      site: 'Hodnotenie webovej stránky: vean-tattoo.%{locale}',
      phone_consultation: 'Konzultácia po telefóne a na sociálnych sieťach',
      consultation: 'Konzultácia v štúdiu pri príchode',
      price: 'Cenová politika štúdia podľa vášho názoru',
      exterior: 'Exteriér: Fasáda štúdia, tabule, umiestnenie atď.',
      interior: 'Interiér štúdia, zariadenie',
      assortment: 'Sortiment suvenírov a liečivých produktov',
      atmosphere: 'Atmosféra: TV, hudba, tím',
      leisure: 'Ponuka občerstvenia, voľný čas, časopisy a katalógy náčrtov',
      master: 'Komunikácia s majstrom',
      admin: 'Komunikácia s administrátorom',
      master_one_place: 'Majster pracuje len v tomto štúdiu? (áno/nie)',
      master_contact: 'Požiadali ste o osobné kontaktné údaje od majstra? (áno/nie)',
      care: 'Ponúkli vám nákup liečebných prostriedkov a našich suvenírov? Dostali ste malý darček (magnet, nálepka alebo zľavový kupón na 10 % z dokončenej služby na ďalšiu)? (áno/nie)',
      conclusion: 'Záverečné hodnotenie kontroly',
    },
    inputs: {
      cost: 'Zadajte sumu, ktorú ste zaplatili za službu',
      cost_other: 'Koľko ste minuli na šperky alebo starostlivostné prostriedky?',
      review: 'Máte nejaké návrhy na vylepšenie štúdia?',
      photo: 'Fotografia štúdia',
    },
    inputs_mystery_shopper: {
      recommendation: 'Vaše odporúčania pre nás',
      why_vean: 'Prečo ste si vybrali VEAN?',
    },
  },
  header: {
    online_appointment: 'Online rezervácia',
    post_session: 'Hodnotenie kvality',
    get_price: 'Konzultácia',
    order_consultation: 'Objednať konzultáciu',
    consultation: '(Konzultácia)',
  },
  index: {
    parlor: 'Štúdio',
    master: 'Majster',
    session_type: 'Typ služby',
    session_location: 'Miesto služby',
    datetime: 'Dátum a čas',
    create_appointment: 'Rezervovať',
    bonus: '* BONUS %{customer_bonus} VeanCoins ZA SAMOSTATNÚ REZERVÁCIU',
    sketch: 'Návrh (nie je povinné)',
  },
  session_types: {
    tattoo: 'Tetovanie',
    tatouage: 'Permanentný make-up',
    piercing: 'Piercing',
    stroke: 'Odstránenie',
    training: 'Výučba',
    training_tattoo: 'Výučba tetovania',
    training_piercing: 'Výučba piercingu',
    training_tattouage: 'Výučba permanentného make-upu',
    training_udder: 'Výučba odstránenia',
  },
  price_form: {
    client_name: 'Meno',
    client_phone: 'Telefón',
    client_comm: 'Vaša služba',
    sketch_desc: 'Pridať návrh ( * Nepovinné )',
    sketch_button: 'Vybrať súbor',
    send: 'Odoslať',
    or: 'Alebo',
    country: 'Krajina',
    city: 'Mesto',
    comment: 'Komentár',
    val_error: 'Skontrolujte zadané údaje!',
    ths_message: 'Ďakujeme! Čoskoro vás budeme kontaktovať',
    go_back: 'Vrátiť sa',
    file_size: 'Veľkosť súboru nesmie presiahnuť 5 MB!',
    apr_time: 'Approximate session time',
  },
  selectors: {
    parlor: {
      placeholder: 'Vyhľadávanie podľa názvu štúdia...',
    },
    master: {
      placeholder: 'Vyhľadávanie podľa mena, služby…',
    },
    select_type: {
      select: 'Vybrať',
      next: 'Ďalej',
      no_price: 'Dohodou',
      ad: 'od',
      da: 'do',
      selected: 'Vybrané',
      info: 'Cena môže závisieť od veľkosti a miesta',
    },
    datetime: {
      select_date: 'Vyberte dátum, ktorý vás zaujíma',
      no_active_times: 'Na tento dátum už nie sú dostupné voľné termíny. Vyberte prosím iný dátum!',
    },
  },
  verify: {
    verify_account: 'Overiť účet',
    type: 'Spôsob overenia',
    check_code: 'Kontrola kódu',
    status: 'Stav',
    input_phone: 'Zadajte telefónne číslo',
    email_help: 'Môžete tiež použiť email na overenie',
    sms_sent: 'Bola vám odoslaná SMS s kódom, zadajte ho nižšie',
    resend_sms_after: 'Opätovné odoslanie bude možné o %{seconds} sekúnd',
    resend_sms: 'Odoslať znova',
    buttons: {
      back: 'Späť',
      next: 'Ďalej',
      create_appointment: 'Rezervovať',
    },
  },
  success: {
    h1: 'Ďakujeme! Rezervácia bola úspešne vytvorená!',
    h2: 'Po schválení rezervácie vám bude odoslaná SMS.',
    mystery: 'Stať sa tajným nakupujúcim',
  },
  about: {
    header: 'Informácie o vás',
    birth_info:
      'Uvedením dátumu narodenia sa uistíme, že máte povolený vek, a tiež je to potrebné pre zľavu na narodeniny :)',
    inputs: {
      name: 'Meno a priezvisko',
      find_out: 'Odkiaľ ste sa dozvedeli',
      birth_date: 'Dátum narodenia',
    },
    buttons: {
      skip: 'Preskočiť',
      back: 'Späť',
      next: 'Ďalej',
    },
  },
  footer: {
    header: 'Sledujte nás na sociálnych sieťach, aby ste boli v obraze o všetkých novinkách!',
    site_navigation: 'Navigácia',
    interest: 'Zaujímavé',
    other: 'Iné',
  },
  subscribe_modal: {
    header: 'Prihlásiť sa na novinky za bonus',
    submit: 'Odoslať',
    wrong_data: 'Skontrolujte správnosť zadaných údajov!',
    wrong_email: 'Nesprávny email!',
    thanks: 'Ďakujeme za prihlásenie!',
    error: 'Nastala chyba!',
  },
  chat_bot: {
    call: 'Zavolať',
    online_record: 'Online rezervácia',
    online_consultation: 'Online konzultácia',
    need_help: 'Potrebujete pomoc s návrhom?',
    order_sketch: 'Objednať návrh',
    dress_sketch: 'Vyskúšať návrh',
  },
  client_page: {
    problem_card: {
      problem_situations: 'Problémové situácie',
      id: 'Číslo',
      status: 'Stav',
    },
    reset_password: {
      title: 'Zabudnuté heslo',
      submit: 'odoslať',
      forgot_password: 'Zabudnuté heslo',
      phone: 'Zadajte telefónne číslo',
      success_message: 'Bolo vám odoslané dočasné heslo na váš email/telefón',
    },
    registration: {
      name: 'Meno',
      surname: 'Priezvisko',
      registered: 'Registrácia',
      congratulations: 'Gratulujeme!',
      successfully_registered: 'Úspešne ste sa zaregistrovali!',
      log_in: 'Prihlásenie',
    },
    options: {
      no_interest_in_promotions: 'Nemám záujem o akcie a bonusy',
      dislike_frequent_sms: 'Nepáčia sa mi časté SMS správy',
      already_have_tattoo: 'Už mám tetovanie a neplánujem si robiť ďalšie v blízkej budúcnosti',
      other: 'Iné',
    },
    login_page: {
      log_in_page: 'Prihlásenie do osobného účtu',
      number_missing: 'Váš číslo nie je?',
      register: 'Zaregistrujte sa!',
      steps: {
        sign_up: 'Zaregistrujte sa do vernostného programu alebo PRIHLÁSTE SA',
        check_coins: 'Skontrolujte svoje VeanCoin, registráciou do vernostného programu',
        get_coins: 'Získajte svoje VeanCoins na konci každého sedenia',
        accumulate: 'Zbierajte a zhromažďujte VeanCoin vo svojom profile',
        book_session: 'Rezervujte si sedenie a príďte do Vean na tetovanie',
        exchange_coins: 'Vymieňajte VeanCoins a získavajte zľavy',
      },
    },
    find_customer: {
      continue: 'Pokračovať',
      select_type: 'Vyberte typ prihlásenia',
      no_password: 'Nemáte zadané heslo!',
      not_found: 'Klient nenájdený!',
      found: 'Nájdené!',
      check: 'Kontrola...',
      invalid_phone_or_password: 'Nesprávne prihlasovacie údaje',
      error_login: 'Chyba pri prihlásení',
      phone: 'Telefón',
      temporary_password_message: 'Používate dočasné heslo',
      permanent_password_message: 'Používate trvalé heslo',
    },
    product_card: {
      availability: 'Dostupnosť v štúdiách:',
      unavailable: 'Nedostupné',
    },
    store_body: {
      full_points_products: 'Produkty, ktoré je možné kúpiť za VeanCoins',
      half_points_products: 'Produkty, ktoré je možné kúpiť so zľavou 50%',
      no_products: 'Žiadne produkty',
    },
    description_block: {
      loyalty_system: 'Vernostný systém VEAN',
      show_more: 'Zobraziť viac',
    },
    profile_card: {
      my_sketches: 'Moje návrhy',
      share_link: 'Podeľte sa o tento odkaz',
      tips: 'Sprepitné',
      send_certificate: 'Odoslať certifikát',
      vip: 'VIP',
      not_vip: 'Stať sa VIP',
      veancoins: 'VeanCoins',
      balance_buy: 'Peniaze',
      active_certificates: 'Aktivované VeanCoins',
      balance: 'Zostatok',
      sessions: 'Sedenia',
      points_alltime: 'Získané VeanCoins za celú dobu',
      available_certificates: 'Dostupné certifikáty',
      certificates: 'Pekelné certifikáty',
      bought_certificates: 'Aktivované VeanCoins',
      certificate_number: 'Číslo certifikátu',
      available_for: 'Platné do:',
      no_active_certificates: 'Žiadne pekelné certifikáty',
      no_active_bought_certificates: 'Žiadne aktivované VeanCoins',
      coming_sessions: 'Nadchádzajúce sedenia',
      complete_agreement: 'Vyplniť súhlas',
      date: 'Dátum:',
      you_was: 'Boli ste na %{sessions} sedeniach',
      edit: 'Upraviť',
      not_email: 'Na odoslanie certifikátu je potrebné vyplniť email',
      exist_application: 'Existuje aplikácia',
      need_exist_application: 'Žiadna aplikácia',
      need_confirm_account: 'Je potrebné potvrdiť účet',
      verified: 'Overený',
      choose_payment_method: 'Vyberte spôsob platby',
      parlor: 'Štúdio',
      sign_up_online: 'Rezervovať sedenie',
      mystery_shopper: 'Stať sa tajným nakupujúcim',
      sign_up_secret_buyer: 'Rezervujte si u nás sedenie a staňte sa tajným nakupujúcim',
      pay: 'Zaplatiť',
      prepayment: 'Predplatba',
      questionnaire: 'Dotazník',
      agreement: 'Súhlas',
      fill_required_information: 'Je potrebné vyplniť informácie',
      buy: 'Dobit zostatok',
      archive: 'Archív',
      support_service: 'Zákaznícky servis',
      balance_buy_history: 'História kúpených VeanCoins',
      balance_history: 'História získaných VeanCoins',
      buy_history: 'História nákupov',
      сost: 'Cena',
      payment_link: 'Odkaz na platbu',
      attributes: 'Atribúty',
      invite_friend: 'Pozvať priateľa',
      phone_or_email: 'Zadajte telefónne číslo alebo email',
      report_problem: 'Nahlásiť problém',
      note: 'Zadajte komentár',
      verify_account_status: 'Stav účtu',
      security_percent: 'Bezpečnosť:',
      tooltip_coins:
        'Je potrebné aktivovať svoje nahromadené VeanCoins pre zvolenú službu alebo na nákup produktov, aby ste predišli ich prepadnutiu v priebehu 1 roka',
      tooltip_certificate:
        'Počet vašich kúpených alebo darovaných pekelných certifikátov, ktoré je možné využiť do 2 mesiacov',
      tooltip_сonfirmed: 'Pri úprave zmeňte „Meno a priezvisko“ na „Meno a Priezvisko“',
      tooltip_missing:
        'Pridajte informácie o sebe (meno a priezvisko, dátum narodenia, odkaz na vaše sociálne siete, odkiaľ ste sa o nás dozvedeli, telefónne číslo a email na overenie identity)',
      tooltip_verified:
        'Je potrebné potvrdiť svoj účet pomocou telefónneho čísla alebo emailu na ochranu získaných a nahromadených VeanCoins.',
      tooltip_vip:
        'VIP client is a status that can be obtained by any client who spends more than %{amount} on services. VIP status provides certain benefits to its owner, such as fast priority booking for sessions out of turn, access to unique goods and services. Upon reaching VIP status, the client immediately receives %{cashback} VeanCoins credited to their account.',
      earn_veancoins: 'Zarob veancoins',
    },
    profile_edit: {
      additional_information: 'Dodatočné informácie za VeanCoins',
      parlors: 'Štúdio (ktoré je bližšie alebo hlavné)',
      client_card: 'Karta klienta',
      full_name: 'Meno a priezvisko alebo užívateľské meno',
      birth_date: 'Dátum narodenia (na gratuláciu)',
      social_media_link: 'Odkaz na sociálnu sieť',
      find_out: 'Odkiaľ ste sa dozvedeli',
      or: 'alebo',
      phone_placeholder: 'Telefón',
      save: 'Uložiť',
      email_taken: 'Tento email je už obsadený!',
      phone_taken: 'Toto telefónne číslo je už obsadené!',
      simple_password: 'Príliš jednoduché heslo!',
      invalid_password: 'Neplatné heslo!',
      password_for_application: 'Heslo pre aplikáciu (Nepovinné)',
      congratulate: 'Gratulujeme!',
      successfully_registered: 'Úspešne ste sa zaregistrovali!',
      need_confirm_account: 'Je potrebné potvrdiť účet',
      yes: 'Áno',
      phone_confirmed: 'Telefón potvrdený',
      email_confirmed: 'Email potvrdený',
      change_password: 'Zmeniť heslo',
      old_password: 'Staré heslo',
      new_password: 'Nové heslo',
      confirm_password: 'Zopakovať heslo',
      invalid_phone_or_password: 'Neplatné telefónne číslo alebo heslo',
      error_login: 'Nastala chyba pri prihlásení',
      years_old: 'Vek',
      up_18_years_old: '16-21',
      for18_24: '21-27',
      for25: '27-35',
      for35_45: '35-45',
      up_45_years_old: '45+',
      gender: 'Pohlavie',
      man: 'Muž',
      woman: 'Žena',
      gender_hidden: 'Radšej neuvádzam',
      language: 'Jazyk',
      preferred_style_status: 'Preferované štýly',
      classic: 'Klasika / realizmus',
      freak: 'Šialenec / Trash / Anime',
      fashion: 'Móda / Umenie / Minimalizmus',
      rock: 'Rock / Hard',
      pop: 'Pop / Mas',
      accommodation_status: 'Miesto pobytu',
      local: 'Miesto',
      tourist: 'Turista',
      guest: 'Hosť',
      lifestyle_status: 'Životný štýl',
      extreme: 'Extrém',
      dance: 'Tanec',
      anime: 'Anime',
      game: 'Hra',
      music: 'Hudba',
      lgbt: 'LGBT',
      art: 'Umenie / Modernosť',
      family: 'Rodina / láska',
      beauty: 'Krása',
      sexuality: 'Sexualita',
      masculinity: 'Mužnosť',
      tattoo_count_status: 'Počet tetovaní',
      pierce_count_status: 'Počet piercingov',
      no: 'Nie',
      one: 'Jedno',
      two: 'Dve alebo viac',
      inc: 'Veľa',
      change_information: 'Uložiť informácie',
    },
    verification: {
      sms: 'Overiť cez SMS',
      email: 'Overiť cez Email',
      google: 'Overiť cez Google',
      send_code: 'Odoslať kód',
      code: 'Overovací kód',
    },
    loyalty: {
      submit_button: 'Odoslať',
      bonus_instruction:
        'Urobte jednu z uvedených činností, pridajte dôkazy (snímky obrazovky, fotografie atď.) a získajte bonus',
      bonus_amount: 'VeanCoins!',
      review_option_1: 'Zanechať podrobnú recenziu v profile majstra na webe',
      review_option_2: 'Ohodnotiť štúdio alebo majstra na webe',
      review_option_3: 'Ohodnotiť štúdio a recenziu na mape',
      review_option_4: 'Zdieľať naše štúdio na sociálnych sieťach',
      return:
        'Ak fotografia, ktorú ste odoslali, nespadá do žiadnej kategórie, za ktorú priraďujeme bonusy, získané bonusy budú pri kúpe certifikátu anulované.',
      evidence_label: 'Dôkazy',
    },
    buy_certificate: {
      payment_type_title: 'Typ nákupu',
      buy_balance: 'Dobitie zostatku',
      buy_certificate: 'Kúpa certifikátu',
      payment_type_subtitle: 'Čo chcete urobiť',
      amount_title: 'Suma',
      amount_subtitle: 'Zadajte sumu',
      payment_method_title: 'Spôsob platby',
      payment_method_subtitle: 'Vyberte spôsob platby',
      payment_title: 'Platba',
      forming_payment: 'Príprava platby...',
      pay: 'Zaplatiť',
      continue: 'Pokračovať',
      back: 'Späť',
      submit_to_email: 'Odoslať certifikát na e-mail',
      get_vean_card: 'Získať kartu VEAN v štúdiu',
      vean_card_description:
        'VEAN CARD je vaša karta, na ktorej sa automaticky aktivujú certifikáty VEAN TATTOO, čo zaručuje bezpečnosť a pohodlie. Všetky vaše darčeky a zľavy máte vždy po ruke a používanie karty je maximálne jednoduché a výhodné. Získajte svoju kartu v štúdiách VEAN TATTOO a objavte všetky výhody už dnes!',
      total_balance: 'Ravnovesje',
      certificate: 'Certifikát',
      customer_balance: 'Zostatok zákazníka',
      certificate_type: 'Typ certifikátu',
      promotion_certificate: 'Akčný certifikát',
      buy_for_myself: 'Kúpiť pre seba',
      promotion_certificate_message:
        'Fyzické certifikáty budú vydávané do 31. decembra 2024. Od 1. januára 2025 budú certifikáty prevedené na body a zobrazené v osobnom účte používateľa.',
    },
  },
  parlor_page: {
    header: {
      parlors_count: 'Viac ako 160 štúdií',
      clients_count: 'Viac ako 1 500 000 klientov',
      largest_company: 'Najväčšia sieť tetovacích štúdií na svete',
    },
    master_block: {
      our_masters: 'Naši majstri',
    },
    master_card: {
      works_count: 'Počet prác: ',
    },
    no_admin_block: {
      leisure: 'Voľnočasové aktivity',
      session_record: 'Rezervácia sedenia',
      about_company: 'O spoločnosti',
      care: 'Starostlivosť',
      quality_certificates: 'Certifikáty kvality',
      public_buyout_offer: 'Verejná ponuka',
      complaints_offers: 'Sťažnosti a návrhy',
      loyalty_system: 'Vernostný systém',
      discount_page: 'Zľavy',
    },
    peculiarities_block: {
      about_studio: 'O tomto štúdiu',
      clients: 'Klienti',
      sessions: 'Sedenia',
      studio_hours: 'Otváracie hodiny: ',
      studio_phone: 'Telefónne číslo štúdia: ',
      wifi: 'Wi-fi: ',
      password: 'Heslo: ',
      from_to: 'od %{time_start} do %{time_end}',
    },
    photos_block: {
      photos: 'Fotografie štúdia',
    },
    top_works: 'Práce',
    care_important: 'Najdôležitejšie pri piercingu a tetovaní je hojenie a starostlivosť.',
    cabinet: 'Osobný účet',
    complete_agreemnent: 'Check-in',
    is_blanket: 'Je tu deka, ktorou sa môžete zahriať',
    is_water: 'Sú tu teplé nápoje',
    is_hot_drinks: 'Sú tu sladkosti',
    is_treats: 'Sú tu studené nápoje',
  },
  promotion: {
    submit: 'Odoslať',
    warning: 'Pozor! Skontrolujte správnosť zadaných údajov!',
    name: 'Meno / Prezývka',
    required: 'Toto pole je povinné!',
    max_value: 'Môže mať maximálne 100 znakov!',
    phone: 'Telefón',
    social_link: 'Odkaz na sociálnu sieť',
    wrong_format: 'Nesprávny formát!',
    terms: 'Oboznámil som sa s politikou spracovania osobných údajov',
    know_bonus: 'Viem, že mi budú pripočítané bonusové body',
    error: 'Nastala chyba',
    thanks: 'Ďakujeme!',
    bonus: 'Bolo vám pripočítaných %{gift_price} bodov',
    do_not_disturb: 'Neobťažovať (vypnúť SMS a hovory od VeanTattoo)',
    first_time_veancoins_notification:
      'Aby ste mohli prvýkrát použiť VeanCoins, musíte sa obrátiť na administrátora štúdia. VeanCoins a peniaze majú rôzne pravidlá používania',
    get_promo: 'Chcem dostávať novinky o zľavách a akciách',
  },
  another_studio_drawler: {
    another: 'Iné štúdiá',
  },
  landing: {
    best_studio: 'Najlepšie tetovacie štúdio vo vašom meste',
    vean_wide: 'SIEŤ VEAN TATTOO',
    studio_count: 'Viac ako 160 tetovacích štúdií',
    quality: 'Záruka kvality a bezpečnosti',
    contact_us: 'Kontaktujte nás',
    get_price: 'ZISTIŤ CENU',
    advantage: 'Výhody',
    top_works: 'Top práce',
    prices: 'Ceny',
    studio_photo: 'Fotografie štúdia',
    prices_services: 'Ceny a služby',
    about: 'O nás',
    loyalty_system: 'Vernostný program',
    why_vean: 'Prečo VEAN',
    visit_count: 'Ľudí navštívilo toto štúdio',
    description:
      'Sieť tetovacích štúdií VeAn Tattoo je dnes najväčšia na svete: máme viac ako 160 salónov v 14 krajinách Európy. Vyviedli sme túto nišu na úplne novú, vyššiu úroveň štandardov. Špecializácia siete je dosť široká, ale hlavným smerom je tetovanie a piercing priemysel. Kľúčové princípy spoločnosti VeAn Tattoo: Odbornosť a dostupnosť: Snažíme sa, aby sa každý klient cítil pohodlne na každom kroku procesu, preto sú naši odborníci pripravení odpovedať na všetky vaše otázky online. Reputácia a orientácia na zákazníka: Každý klient je príležitosťou vytvoriť jedinečné umelecké dielo na ich koži. Oceňujeme váš čas a snažíme sa, aby odišiel z nášho štúdia s lepšou náladou a úžasným tetovaním. Starostlivosť o reputáciu a vážime si každého klienta siete. Hodnota a dostupnosť: Ponúkame ideálny pomer ceny a kvality. Naše služby sú dostupné pre široké publikum, a pritom neobetujeme kvalitu. Certifikované a kvalitné vybavenie: Naše materiály spĺňajú najvyššie štandardy bezpečnosti a kvality. Zaručujeme, že naši klienti dostávajú len to najlepšie. Vysokokvalifikovaní odborníci a široká ponuka služieb: V štúdiách VeAn Tattoo pracujú iba certifikovaní majstri, ktorých oceňujú a rešpektujú po celom svete. Ponúkame všetky existujúce štýly a techniky tetovania, vypracovanie bezplatného individuálneho návrhu podľa vašich požiadaviek. Taktiež vám ponúkame, aby ste sa prihlásili na všetky naše oficiálne stránky na sociálnych sieťach. To vám umožní byť v obraze o špeciálnych ponukách, akciách, súťažiach, prácach majstrov, posledných novinkách a aktualizáciách spoločnosti VeAn Tattoo.',
    advantages: {},
    our_masters: 'Naši majstri',
    from: 'od',
    no_price: 'Dohodou',
    form: {
      get_price_consultation: 'Zistiť cenu / Konzultácia',
      name: 'Meno',
      phone: 'Telefón',
      place: 'Vaše miesto',
      subscribe_news: 'Prihlásiť sa na novinky za bonus',
    },
  },
  form_messages: {
    submit: 'Odoslať',
    warning: 'Pozor! Skontrolujte správnosť zadaných údajov!',
    required: 'Toto pole je povinné!',
    length: 'Nie menej ako %{length} znakov!',
    file_length: 'Nie viac ako %{length} súborov!',
    wrong_format: 'Nesprávny formát!',
    error: 'Nastala chyba',
    thanks: 'Ďakujeme!',
    required_label: 'Povinné',
    not_required_label: 'Nepovinné',
  },

  training: {
    training: 'ŠKOLENIE',
    tattoo_school: 'ŠKOLA TATTOO',
    biggest_school: 'najväčšia sieť tetovacích štúdií na svete',
    ne_otkladivaj: 'Ne<br/>odkladaj<br/>seba na neskôr',
    learn: 'Nauč sa robiť tetovania<br/>a staň sa top majstrom',
    reviews: 'Recenzie absolventov',
    record: 'Prihlásiť sa',
    video_text: 'S čím pomôžeme,<br/>čo ťa naučíme?',
    ded: {
      consultation: 'konzultácia',
      get_more: 'Zisti viac informácií',
    },

    header_bar: {
      main: 'Hlavná',
      get: 'Čo získaš',
      program: 'Program kurzu',
      about: 'O nás',
      reviews: 'Recenzie',
    },

    our_course: {
      head: 'Naše<br/>kurzy',
      description_1: 'je to miesto, kde vždy dostaneš odpoveď na otázky a naučíš sa niečo nové',
      description_2: 'A aj keď nemáš umelecké vzdelanie, výsledok na seba nenechá dlho čakať',
      label: '[ s nami je teplo, pohodlne a zážitkovo ]',
      description_3:
        'Milujeme svojich študentov a snažíme sa, aby proces učenia bol čo najkomfortnejší',
    },

    banner: {
      we_teach: 'Naučíme ťa robiť tetovania',
      good_make: 'ROBÍME KVALITNÉ TETOVANIA',
      our_masters: 'NAŠI MAJSTRI SÚ PRIPRAVENÍ ZDIEĽAŤ SVOJE ZNALOSTI, TAJOMSTVÁ, TECHNIKY',
      learn_too: 'A naučíme ťa tiež',
    },
    modal: {
      thanks: 'Ďakujeme!',
      error: 'Nastala chyba!',
      complete: 'Vyplň formulár a my ti zavoláme',
      full_name: 'Meno a priezvisko',
      phone: 'Telefónne číslo',
      submit: 'Odoslať',
      cancel: 'Zrušiť',
    },

    paragraph: {
      header_1: 'Určite ťa naučíme',
      content_1: 'Nepristupujeme k vzdelávaniu povrchne',
      header_2: 'vedieť kresliť',
      content_2: 'Všetko ťa naučíme, nemáš umelecké vzdelanie, to vôbec nevadí',
      header_3: 'Certifikát',
      content_3:
        'Po skončení kurzov dostaneš certifikát medzinárodného štandardu. Schválené Global Tattoo ACC',
      header_4: 'Zamestnáme ťa',
      content_4:
        'OMG, to myslíte vážne? Áno, najlepší absolventi sa môžu stať súčasťou nášho tímu a pracovať kdekoľvek v Európe',
      header_5: 'Dohoda',
      content_5: 'Nepracujeme na „dobré slovo“. Všetko na základe dohody a oficiálne',
      no_sleep: {
        header: 'ne prospatú',
        rect_1: 'Ako?',
        rect_2: 'ŽIVOT',
        main_label: 'Začni sa učiť práve teraz',
        label_1: '1000+ absolventov',
        label_2: '100% dokončili vzdelanie',
        label_3: '80% sa zamestnalo',
        label_4: '300+ partnerov v tetovacej priemysle',
      },
    },
    principies: {
      head: 'Záruky',
      label_1: '1. Kultúra',
      label_2: '2. Kvalita',
      label_3: '3. Metódy',
      heading: 'Všetko je postavené na princípoch',
      description_1:
        'Pred 11 rokmi sme sa rozhodli rozvíjať kultúru tetovacieho priemyslu, nie ako ostatní, po svojom - na základe znalostí a skúseností',
      description_2:
        'Dnes sme vyškolili viac ako 1000 skvelých tetovacích majstrov. 80% sa stalo súčasťou nášho tímu. Môžeme s istotou povedať, že umeniu tetovania sa môže naučiť každý',
      description_3:
        'Všetko spočíva v metodike, ktorú používame a neustále zlepšujeme spolu s tímom, umelcami a našimi absolventmi, ktorí pravidelne poskytujú spätnú väzbu',
    },
    packages: {
      head: 'Programy kurzov',
      from: 'od',
      plus: 'Výhody',
    },
    facilities: {
      head: 'Možnosti a výhody',
      subhead: 'Vyberte si naše kurzy a zažite magiu tetovacieho priemyslu',
      card: {
        head_1: 'Každý náš absolvent získa certifikát medzinárodného vzoru',
        label_1: '- tvoj lístok do sveta možností',
        head_2: 'Odporúčanie a podpora od skupiny profesionálnych majstrov',
        label_2: 'Tvoj úspech - naša hrdosť!',
        head_3: 'Komunita tetovania, získavajte rady a podporu od našich expertov',
        label_3: 'Tvoj nový život vo svete tetovania začína teraz!',
      },
    },
    review: {
      head_1: 'Prípady majstrov',
      head_2: 'Recenzie',
      comment_1:
        'Vzdelávanie v tejto škole tetovania prekonalo všetky moje očakávania! Inštruktori sú profesionáli vo svojom obore, každý krok výučby je podrobne vysvetlený, atmosféra je skvelá. Teraz môžem s istotou realizovať svoje tvorivé nápady na koži klientov!',
      comment_2:
        'Ďakujem škole za kvalitné vzdelávanie v tetovaní! Trochu som sa obával začať s týmto, ale vďaka pozorným a trpezlivým inštruktorom som sa cítil istý. Teraz moje práce získavajú uznanie a obdiv',
      comment_3:
        'Bolo to úžasné putovanie do sveta tetovania! Škola poskytuje všetky potrebné vedomosti a zručnosti, aby ste sa stali skutočným profesionálom. Som vďačný za podporu a inšpiráciu, ktoré som tu získal',
    },
  },
  questionary: {
    first: {
      heading: 'SÚHLAS S PREVEDENÍM PROCEDÚRY V ŠTÚDIU VEAN TATTOO',
      textbox:
        'Ak klient štúdia nemá 18 rokov, súhlas vyplňujú rodičia alebo zákonní zástupcovia (v Britskej Kolumbii, Kanada, je osoba mladšia ako 19 rokov považovaná za neplnoletú. Ak máte menej ako 19 rokov, vyberte DRUHÚ možnosť)',
      has_18: 'ÁNO, mám 18',
      no_18: 'NIE, nemám 18',
    },
    second: {
      warning: 'Pozor! V deň sedenia je potrebné prísť s opatrovníkmi!',
      parent_data: 'Údaje jedného z rodičov:',
      child_data: 'Údaje jedného NEPLNOLETÉHO:',
      child_field_placeholder: 'Syn, dcéra atď.',
      child_field_label: 'Pre koho sa procedúra vykonáva',
      confirm:
        'Ja, ako zákonný zástupca neplnoletého, dávam svoj súhlas na vykonanie nasledujúcej procedúry svojmu dieťaťu.',
      name: 'Meno a Priezvisko',
      birthdate: 'Dátum narodenia',
      address: 'Adresa',
      email: 'Email',
      sex: 'Vaše pohlavie',
      heading: 'PROCEDÚRA, KTORÁ BUDE VYKONANÁ',
      man: 'Muž',
      woman: 'Žena',
      other: 'Nešpecifikované',
    },
    third: {
      heading: 'VŠETKO, ČO POTREBUJETE VEDIEŤ O OBDOBÍ HOJENIA:',
    },
    fourth: {
      nothing: 'Nič z uvedeného',
      has_diseases: 'Máte nejaké choroby?',
      has_contraindications: 'Máte nejaké kontraindikácie?',
      heading1:
        'AK MÁTE AKÉKOĽVEK Z UVEDENÝCH OCHORENÍ, MUSÍME O TOM VEDIEŤ, PROSÍM, OZNAČTE PRÍSLUŠNÉ POLIA:',
      heading2: 'AK SÚ KONTRAINDIKÁCIE, PROSÍM, UVEDŤTE PRÍSLUŠNÉ INFORMÁCIE:',
    },
    fifth: {
      heading: 'PO PROCEDÚRE JE ZAKÁZANÉ:',
    },
    sixth: {
      heading: 'ODKIAĽ STE SA DOZVEDELI O NÁS:',
      label:
        'Po 21 dňoch sa s vami môže skontaktovať jeden z našich zamestnancov v rámci kontroly kvality poskytnutých služieb. Okrem toho po relácii dostanete SMS oznámenia a je možné, že náš automatizovaný robot uskutoční telefonát. Vo svojom osobnom účte sa môžete odhlásiť z notifikačných služieb prostredníctvom telefonátov a SMS a môžete tiež odstrániť všetky svoje údaje a fotografie po 21 dňoch od dátumu služby.',
    },
    seventh: {
      heading: 'VYHLÁSENIE A SÚHLAS',
    },
    signature: {
      heading:
        'Ja, nižšie podpísaný (%{name}), súhlasím s prevedením dnešnej operácie\n' +
        '%{session_type} na mojom tele a súhlasím s podmienkami obsiahnutými v tomto dokumente.',
    },
    next: 'Ďalej',
    submit: 'Odoslať',
    no_responsibility: 'Štúdio nenesie zodpovednosť za toto sedenie',
    acquaint: 'Oboznámil som sa s poskytnutými informáciami',
    acquaint_2:
      'Chápem, že Vean Tattoo nezodpovedá za proces hojenia.\n' +
      'V prípade opravy vyššie uvedeného tetovania alebo piercingu bude cena opravy 30 % z ceny samotnej služby.\n' +
      'Ak nie ste spokojní s výsledkom opravy, v tomto prípade sa peniaze za službu nevracajú, pretože vyššie uvedené oblasti nie sú priaznivé pre vykonávanie samotnej služby.',
  },

  choices: {
    problem_situation: {
      no_solution: 'Bez riešenia',
      in_check: 'V kontrole',
      problem_solved: 'Problém vyriešený',
      wait: 'Čakanie',
      small: 'Malá situácia',
      unanswered_check: 'V kontrole bez odpovede',
    },
  },
  master_page: {
    card: {
      active: 'Aktívny',
      not_active: 'Neaktívny',
      master_since: 'Majster VEAN od',
      services: 'Služby',
      styles: 'Štýly',
      book: 'Rezervovať',
    },
    information: {
      top_works: 'Top práce',
      awards_and_diplomas: 'Ocenenia a diplomy',
    },
  },
  stripe_warning:
    'Pozor! Minimálna suma platby cez stripe je 20 €. Ak je potrebná nižšia suma - kontaktujte štúdio pre platobné údaje',
  check_in: {
    rate: {
      upload_photo: 'Nahrajte fotografiu svojej práce',
      upload_photo_desc:
        'Môžete si urobiť peknú fotografiu svojej práce pre naše sociálne siete a webovú stránku. Administrátor tiež robí fotografiu na kontrolu kvality a na zaručenie vykonanej práce. Vždy sa môžete rozhodnúť nezverejniť a nezdieľať fotografiu a vaše údaje budú po zahojení úplne vymazané.',
      bad_comment: 'Prosím, vysvetlite, prečo ste dali nízke hodnotenie',
      review: 'Zanechajte recenziu!',
      review_desc: 'Budeme veľmi vďační, ak zanecháte recenziu. Váš názor je pre nás dôležitý!',
      cashback: 'x2 cashback na váš účet v aplikácii',
      send_invoice: 'Poslať faktúru na e-mail',
      influencer: 'Máte viac ako 2000 sledovateľov na sociálnych sieťach?',
    },
    welcome_message: 'ŽELÁME PRÍJEMNÉ SEDENIE!',
    session_started: 'VAŠE SEDENIE ZAČALO',
    next_steps: 'Po dokončení sedenia vám budú dostupné ďalšie kroky',
    buy_healing_product: 'PONÚKAME VÁM KÚPU PRODUKTU NA HOJENIE',
    skip: 'Preskočiť',
    leave_review: 'Prosím, zanechajte recenziu',
    your_impression: 'Aké sú vaše celkové dojmy?',
    add_photo: 'Pridajte fotografiu práce, aby ste získali ďalšie 1 Vean Coins',
    send: 'Odoslať',
    payment_success: 'Platba bola úspešná',
    get_invoice: 'Chcete získať faktúru?',
    invite_friend: 'Pozvať priateľa',
    recommend_us: 'Odporúčajte nás svojim priateľom a zarábajte spolu s nimi',
    thank_employees: 'Taktiež, ponúkame možnosť odmeniť našich zamestnancov',
    you_can_tip:
      'Drahý %{name}, môžete taktiež odmeniť našich zamestnancov určitou sumou sprepitného',
    own_amount: 'Vlastná suma',
    who_to_tip: 'Komu by ste chceli priradiť sprepitné?',
    payment_type: 'Typ platby',
    continue: 'Pokračovať',
    summary: 'Zhrnutie',
    healing_agent: 'Liečivý prostriedok',
    tips: 'Sprepitné',
    session_payment: 'Platba za sedenie',
    total: 'Celkom',
    waiting: 'Čakanie...',
    available_funds: 'Dostupné prostriedky',
    refill_balance: 'Dobitie zostatku',
    go_to_payment: 'Prejsť k platbe',
  },
  session: {
    check_in: 'Check-in',
    tips: 'Sprepitné',
    prepayment: 'Predplatba',
    have_questions: 'Máte otázky?',
    title: 'Sedenia',
    selectStatus: 'Vyberte stav',
    selectParlor: 'Vyberte štúdio',
    loading: 'Načítava sa...',
    fromDate: 'Od dátumu',
    toDate: 'Do dátumu',
    search: 'Hľadať',
    all: 'Všetko',
    status: {
      100: 'Čakanie',
      200: 'Nový',
      300: 'V práci',
      400: 'Dokončený',
      500: 'Zrušený',
      600: 'Čaká na platbu',
    },
    details: 'Detaily relácie',
  },
  master: {
    rank: 'Hodnotenie',
    works_count: 'Počet prác',
    address: 'Adresa',
  },
  modalPhone: {
    title: 'Pozor!',
    info: 'Vážený zákazník, pre vašu bezpečnosť a efektívne používanie našich služieb je povinné pridať všetky údaje. Pomôže nám to včas reagovať na dôležité otázky a zabezpečiť vašu ochranu.',
    reminder: 'Pripomeň mi neskôr',
    go_to_settings: 'Prejsť na nastavenia',
    roughly_price: 'Približná cena závisí od presnosti vyplnených údajov',
    success_message: 'Ďakujeme!',
  },
  studyingRegistration: {
    become_student: 'Staňte sa študentom',
    step_1: 'Všeobecné údaje',
    step_2: 'Osobné informácie',
    step_3: 'Podrobnosti o štúdiu',
    city: 'Mesto',
    city_placeholder: 'Zadajte vaše mesto',
    name: 'Meno',
    name_placeholder: 'Zadajte vaše meno',
    last_name: 'Priezvisko',
    last_name_placeholder: 'Zadajte vaše priezvisko',
    date_of_birth: 'Dátum narodenia',
    date_of_birth_placeholder: 'Zadajte váš dátum narodenia',
    phone: 'Telefónne číslo',
    email: 'E-mail (nepovinné)',
    email_placeholder: 'Zadajte váš e-mail',
    social_media: 'Sociálne siete',
    social_media_placeholder: 'Odkaz na váš profil',
    referral_source: 'Ako ste sa dozvedeli o nás?',
    referral_source_placeholder: 'Vyberte zdroj',
    study_type: 'Typ štúdia',
    study_type_placeholder: 'Vyberte typ štúdia',
    start_date: 'Odhadovaný dátum začatia',
    start_date_help: 'Zadajte odhadovaný dátum začatia',
    hours_number: 'Počet hodín',
    practice_number: 'Počet praxí',
    details: 'Detaily',
    education_price: 'Cena vzdelávania',
    moto: 'Metóda výučby overená desaťročiami',
    description: 'Opis',
    material: 'Materiál',
    duplicating_phone_number: "Študent s takýmto telefónnym číslom je registrovaný"
  },
  studying_main: {
    become_student: 'Staňte sa študentom!',
    consultation: 'Konzultácia',
    want_to_study: 'Chcem študovať!',
    consult_with_our_advisor: 'Neviete, kde začať? Kontaktujte nášho poradcu!',
  },
  errorTicket: {
    error_description: 'Nahláste problém aplikácie a my ho rýchlo vyriešime',
    error_button: 'Nahláste nefunkčnosť aplikácie',
    problem_situation_description: 'Vyskytol sa problém po relácii? Dajte nám vedieť',
    problem_session_button: 'Nahlásiť problém po sedení',
    problem_session: 'Nahlásiť problém po sedení',
    sidebar_title: 'Nahlásiť problém',
    main_title: 'Vytvorenie hlásenia o chybe',
    basic_info:
      'Pri vytváraní tiketu je dôležité poskytnúť všetky potrebné informácie na rýchle a efektívne spracovanie vašej žiadosti. Tu je príklad štruktúry správy, ktorá vám môže pomôcť:',
    ticket_title: 'Názov tiketu:',
    short_description: "Krátky popis problému (napríklad 'Problémy s vytváraním relácií')",
    first_title: 'Popis problému',
    detailed_description: 'Podrobný popis:',
    problem_description: 'Popíšte podstatu problému, čo presne sa deje a v čom je závada.',
    reproduction_steps: 'Kroky na reprodukciu problému:',
    reproduction_steps_description: 'Uveďte kroky, ktoré vedú k problému.',
    expected_result: 'Očakávaný výsledok:',
    expected_result_description: 'Čo ste očakávali získať alebo vidieť?',
    actual_result: 'Skutočný výsledok:',
    actual_result_description: 'Čo sa stalo namiesto očakávaného výsledku?',
    important_data: 'Dôležité údaje',
    links: 'Odkazy:',
    links_description: 'Uveďte odkazy na problémové stránky alebo dokumenty.',
    photos_and_screenshots: 'Fotografie a snímky obrazovky:',
    photos_and_screenshots_description: 'Priložte obrázky, ktoré môžu pomôcť vizualizovať problém.',
    session_numbers: 'Čísla relácií:',
    session_numbers_description: 'Uveďte čísla relácií, počas ktorých sa problém vyskytol.',
    additional_info: 'Doplňujúce informácie:',
    additional_info_description: 'Zahrňte doplňujúce informácie, ktoré môžu byť užitočné.',
    priority_and_urgency: 'Priorita a naliehavosť:',
    priority_and_urgency_description:
      'Ak problém podstatne ovplyvňuje vašu prácu, uveďte to tu a požiadajte o vysokú prioritu.',
    issue_title: 'Názov',
    issue_title_placeholder: 'Zadajte krátky popis problému',
    description: 'Popis',
    description_placeholder: 'Podrobnejšie popíšte problém',
    files: 'Súbory',
    drag_and_drop: 'Pretiahnite súbor sem alebo vyberte',
    uploaded_files: 'Nahraté súbory',
    create_button: 'Vytvoriť',
    problem_type: 'Typ problému',
    select_problem_type: 'Vyberte typ problému',
    technical: 'Technický',
    non_technical: 'Netechnický',
    loading: 'Načítava sa...',
    main_page: 'Prejsť na hlavnú stránku',
    checkbox: 'Musíte potvrdiť súhlas so všetkými bodmi.',
  },
  multistep: {
    basic_info: 'Základné Údaje',
    type: 'Typ',
    summary: 'Zhrnutie',
    details_rent: 'Podrobnosti o prenájme',
    confirmations: 'Potvrdenia',
    open_google: 'Otvoriť v Google Maps',
  },
  check_in_view: {
    important: 'Dôležité',
    log_in_state_greet: 'Prihláste sa do svojho účtu a sledujte svoje bonusy a zľavy.',
    log_in: 'Prihlásiť sa',
    download_app_greet: 'Stiahnite si našu aplikáciu a získajte bonus',
    download: 'Stiahnuť',
    welcome: 'Vitajte',
    studio_info: 'Informácie o štúdiu',
    recommended_products: 'Odporúčané produkty',
  },
  toast: {
    certificate_success: 'Váš certifikát bol odoslaný na váš e-mail',
  },
  tattoo_removal: {
    title: 'LASER / ZOSVETLENIE',
    registration_description:
      'Vyberte si vhodný dátum a čas na objednávku v priebehu niekoľkých kliknutí',
    registration_button: 'Rýchla registrácia na zosvetľovaciu reláciu',
    consultation_description: 'Chcete upresniť podrobnosti? Radi vám pomôžeme!',
    consultation_button: 'Konzultácia',
    header_nav_title: 'Zosvetlenie tetovania',
    choose_service: 'Typ služby',
    choose_service_placeholder: 'Vyberte typ služby',
    paste_tattoo: 'Pripojte fotografiu odstránenia / zosvetlenia (nepovinné)',
    choose_size: 'Veľkosť',
    choose_size_placeholder: 'Vyberte veľkosť',
  },
  success_form: {
    go_personal_acc: 'Prejdite na svoj osobný účet',
    dont_have_acc: 'Nemáte účet?',
    sign_up: 'Zaregistrujte sa!',
  },
  loyalty_card: {
    client_card: 'Karta klienta',
    card_number: 'Číslo karty',
    card_photo: 'Fotografia karty',
  },
  discount: {
    first_session: 'zľava na prvú schôdzku',
    second_session: 'zľava na druhú schôdzku',
  },
  days: {
    MON: 'PO',
    TUE: 'UT',
    WED: 'ST',
    THU: 'ŠT',
    FRI: 'PI',
    SAT: 'SO',
    SUN: 'NE',
  },
  calculate_price: {
    label: 'Pre zistenie ceny',
    color_options: {
      black_and_white: 'Čierno-biele',
      colored: 'Farebné',
    },
    additional: 'Ďalšie',
    select_location: 'Vyberte lokalitu',
    select_style: 'Vyberte štýl',
    select_size: 'Vyberte veľkosť',
    select_colored: 'Farba',
    select_parlor: 'Vyberte salón',
    select_service: 'Vyberte službu',
    cost: 'Cena',
    from: 'Od',
    to: 'Do',
    book: 'Rezervovať termín',
    no_info: 'Žiadne informácie',
  },
};
