import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import InputFileS3, { InputFileS3ModelValue } from '@/components/InputFileS3.vue';
import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormKitFileS3',
  props: {
  context: Object,
},
  setup(__props) {


const props = __props


const input = ref<InputFileS3ModelValue>([])

watch(input, (value) => {
  props.context.node.input(value)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(InputFileS3, {
    accept: __props.context?.attrs?.accept,
    multiple: __props.context?.attrs?.multiple,
    modelValue: input.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((input).value = $event))
  }, null, 8, ["accept", "multiple", "modelValue"]))
}
}

})