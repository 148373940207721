export const getAddr = () => {
  const addr = window.location.host.split('.').slice(-2);
  if (window.location.hostname === 'localhost') {
    return 'localhost:8000';
  }
  addr.unshift(addr?.includes('pl') || addr?.includes('lv') ? 'cr' : 'crm');
  return addr.join('.');
};
export const getDomainLocale = (defaultLocale = 'com') => {
  const domainLocale = window.location.hostname.split('.').slice(-2).pop();
  return domainLocale === 'localhost' || domainLocale === 'com' ? defaultLocale : domainLocale;
};

export const getOrigin = () => window.location.origin;

export function getHomePageUrl(locale, localeList) {
  if (locale !== 'ru' && locale !== 'uk' && locale !== 'en' && localeList.includes(locale)) {
    return `https://vean-tattoo.${locale}`;
  }
  return 'https://vean-tattoo.com';
}

export const getProtocol = () => {
  const addr = window.location.hostname;
  if (addr === 'localhost') {
    return 'http';
  }
  return 'https';
};

export const getMainSiteDomain = () => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:8000';
  } else {
    return `vean-tattoo.${getDomainLocale()}`;
  }
};

export const getCRMDomain = (defaultHostName = 'http://localhost:8000') => {
  const { hostname } = window.location;
  if (hostname === 'localhost') return defaultHostName;
  const splited = hostname.split('.').slice(-2).pop();
  if (splited === 'pl' || splited === 'lv') {
    return `https://cr.vean-tattoo.${splited}`;
  }
  return `https://crm.vean-tattoo.${splited}`;
};

export const getConvertedImageUrl = (image) => {
  return `${getCRMDomain('https://cr.vean-tattoo.pl') + '/api/convert/' + image}`;
};

export const getHasuraImageUrl = (image) => {
  return image?.url ?? '';
};

// export const getImageUrl = (image) => {
//   return image ? `${getCRMDomain('https://cr.vean-tattoo.pl')}/uploads/${image}` : '';
// };

export const getSelfUrl = () => window.location.origin;

export const getQRCode = (str, options = { width: 250, height: 250 }) =>
  `https://api.qrserver.com/v1/create-qr-code/?size=${options.width}x${options.height}&data=${str}`;

export const APIURL = `${getProtocol()}://${getAddr()}/api`;
export const bitrixURL = 'https://vean-tattoo.bitrix24.eu/rest/1/3ph50ndl6wi4h0rv/crm.lead.add/';
