import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';

const validatePromoCode = async (code: string): Promise<void> => {
  await axios.post(`${APIURL}/v2/prices/validate-promo-code/`, {code})
}

// TODO: Refactor to GET

export {
  validatePromoCode,
};