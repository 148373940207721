export const toBase64 = (image) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      resolve(e.target.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(image);
  });
};

export const textToBase64 = (text) => {
  return window.btoa(text);
};

export const base64ToText = (base64) => {
  return window.atob(base64);
};



export function base64ToFile(base64String, filename) {
  // Разбиваем строку на префикс и данные
  const parts = base64String.split(',');
  let b64Data = '';
  let mimeType = 'application/octet-stream';

  // Если есть префикс (data URL)
  if (parts.length > 1) {
    // Извлекаем MIME-тип из префикса, например: data:image/png;base64
    const mimeMatch = parts[0].match(/data:([^;]+);base64/);
    if (mimeMatch && mimeMatch[1]) {
      mimeType = mimeMatch[1];
    }
    b64Data = parts[1];
  } else {
    b64Data = parts[0];
  }

  // Декодируем Base64 в бинарную строку
  const byteCharacters = atob(b64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Преобразуем в Uint8Array и создаём объект File
  const byteArray = new Uint8Array(byteNumbers);
  return new File([byteArray], filename, { type: mimeType });
}

// Пример использования:
//
// const base64Data = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...';
// const file = base64ToFile(base64Data, 'image.png');
// console.log(file);