<script setup lang="ts">
import { defineProps, defineEmits, watch } from 'vue';
import useS3Storage from '@/composables/useS3Storage'

export type InputFileS3ModelValue = string[]

interface Props {
  modelValue?: InputFileS3ModelValue
  multiple?: boolean,
  accept?: string
}

defineProps<Props>()
const emit = defineEmits(['update:modelValue'])

const { result, isLoading, upload } = useS3Storage()

const handleChange = async (e: Event): Promise<void> => {
  const target = e.target as HTMLInputElement
  const files = target.files
  if (files && files.length) {
    await upload(files)
  }
}

watch([result], ([result]) => {
  emit('update:modelValue', result)
}, {immediate: true})

</script>

<template>
  <div>
    <input :accept :disabled='isLoading' :multiple type="file" @change="handleChange" />
    <p v-if="isLoading">Uploading files...</p>
  </div>
</template>

<style scoped lang="scss">
/* Ваши стили */
</style>
