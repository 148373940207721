import { customerApi } from '@/api/customer';
import { CustomerAppliedBonus } from '@/types/types';
import { BonusLocalStorage } from '@/utils/localStorageConstants';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export default defineStore('bonusStore', () => {
  const bonus = ref<CustomerAppliedBonus | null>(
    JSON.parse(window.localStorage.getItem(BonusLocalStorage) || 'null'),
  );

  const setBonus = (selectedBonus: CustomerAppliedBonus | null) => {
    bonus.value = selectedBonus;
    localStorage.setItem(BonusLocalStorage, JSON.stringify(selectedBonus));
  };

  const createAppliedBonus = async (): Promise<null | number> => {
    if (!bonus.value) {
      return null;
    }

    try {
      const { id } = await customerApi.bonus.createAppliedBonus(bonus.value!);
      setBonus(null);
      return id;
    } catch (e) {
      return null;
    }
  };

  return {
    bonus,
    createAppliedBonus,
    setBonus,
  };
});
