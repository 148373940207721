import axios from "@/plugins/axios";
import { getAddr, getProtocol } from "@/utils/url";

const API_URL = `${getProtocol()}://${getAddr()}/api/v1/auth/customer/jwt/`;

export const getTokens = async (phoneNumber, password) => {
  try {
    const response = await axios.post(`${API_URL}obtain/`, {
      username: phoneNumber,
      password: password,
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const refreshAccessToken = async (refreshToken) => {
  try {
    const response = await axios.post(`${API_URL}refresh/`, {
      refresh: refreshToken,
    });

    return response.data.access;
  } catch (error) {
    return null;
  }
};
