<script setup>
import { VueSignaturePad } from 'vue-signature-pad';
import { ref } from 'vue';

defineProps({
  label: String,
  modelValue: {
    type: Object,
    default() {
      return { isEmpty: true, data: '' };
    },
  },
});

const emit = defineEmits(['update:modelValue']);

const signpad = ref();

const onEnd = () => {
  const { isEmpty, data } = signpad.value.saveSignature();
  emit('update:modelValue', { isEmpty, data });
};

const clear = () => {
  signpad.value.clearSignature();
  emit('update:modelValue', { isEmpty: true, data: '' });
};

const resize = () => {
  signpad.value.resizeCanvas();
};
</script>

<template>
  <div>
    <p v-if='label' class='h2 mb-2 text-center'>{{ label }}</p>
    <div
      class='w-100'
      @pointerdown='resize'
      style='position: relative;'
    >
      <vue-signature-pad
        style='
          background-color: #787878;
          border-radius: 0.5rem;
          border: 1px solid white;
        '
        v-bind='$attrs'
        :options="{
          onEnd,
          penColor: '#00448f',
          backgroundColor: 'transparent',
        }"
        ref='signpad'
      />
      <p
        style='
          font-size: 40px;
          top: 0;
          margin: auto;
          position: absolute;
          text-align: center;
          left: 0;
          right: 0;
        '
      >
        Sign there
      </p>
      <i
        style='
          font-size: 40px;
          position: absolute;
          top: 5px;
          right: 10px;
          cursor: pointer;
        '
        class='bi bi-arrow-counterclockwise'
        @click='clear()'
      >
      </i>
    </div>
  </div>
</template>

<style scoped lang='scss'></style>
