import { ref, shallowRef } from 'vue';
import { uploadFilesToS3 } from '@/utils/s3';


export default () => {
  const isLoading = shallowRef<boolean>(false);
  const result = ref<string[] | null>(null)

  const upload = async (files: FileList) => {
    isLoading.value = true
    const data = await uploadFilesToS3(files)

    result.value = data?.map((item) => item?.url).filter((url) => !!url)
    isLoading.value = false
  }

  return {
    isLoading,
    result,
    upload,
  }
}