<script setup lang='ts'>

import InputFileS3, { InputFileS3ModelValue } from '@/components/InputFileS3.vue';
import { ref, watch } from 'vue';

const props = defineProps({
  context: Object,
})


const input = ref<InputFileS3ModelValue>([])

watch(input, (value) => {
  props.context.node.input(value)
})
</script>

<template>
  <InputFileS3 :accept='context?.attrs?.accept' :multiple='context?.attrs?.multiple' v-model='input'/>
</template>

<style scoped lang='scss'>

</style>