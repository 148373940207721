import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
const MAX_RETRIES = 5;
const MAX_PARALLEL_UPLOADS = 5; // Ограничение количества частей загружаемых одновременно
const MAX_CONCURRENT_FILES = 2; // Ограничение количества одновременно загружаемых файлов

export const startMultipartUpload = async (fileName) => {
  try {
    const response = await axios.post(`${APIURL}/v2/core/s3-multipart-upload/`, { file_name: fileName });
    return response.data;
  } catch (error) {
    console.error('Error starting multipart upload:', error);
    throw new Error(`Failed to initiate multipart upload for file: ${fileName}`);
  }
};

const uploadChunk = async (chunk, partNumber, uploadId, fileName) => {
  for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
    try {
      const { data: partResponse } = await axios.get(`${APIURL}/v2/core/s3-multipart-upload/`, {
        params: { upload_id: uploadId, file_name: fileName, part_number: partNumber },
      });

      if (!partResponse.upload_part_url) {
        throw new Error(`Missing upload URL for part ${partNumber}`);
      }

      const uploadResponse = await axios.put(partResponse.upload_part_url, chunk, {
        timeout: 30000,
      });
      return { PartNumber: partNumber, ETag: uploadResponse.headers.etag.replace(/"/g, '') };
    } catch (error) {
      console.warn(`Retrying part ${partNumber}, attempt ${attempt}...`);
      if (attempt === MAX_RETRIES) {
        console.error(`Failed to upload part ${partNumber} after ${MAX_RETRIES} attempts.`, error);
        throw new Error(`Failed to upload part ${partNumber}.`);
      }
    }
  }
};

const uploadParts = async (file, uploadId, fileName,  onProgress = () => {}, onAbort = () => true, isPaused = () => false) => {
  const chunks = Math.ceil(file.size / CHUNK_SIZE);
  let parts = [];
  let queue = [];
  let uploadedChunks = 0;

  for (let i = 0; i < chunks; i++) {
    if (!onAbort(file.name)) {
      console.warn(`Upload aborted by user: ${fileName}`);
      return { parts, error: `Upload aborted: ${fileName}` }; // Return error if aborted
    }

    while (isPaused(file.name)) {
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!isPaused(file.name)) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });

      if (!onAbort(file.name)) return { parts, error: `Upload aborted: ${fileName}` };
    }

    const start = i * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, file.size);
    const chunk = file.slice(start, end);

    const uploadPromise = uploadChunk(chunk, i + 1, uploadId, fileName)
      .then(part => {
        parts.push(part);
        uploadedChunks++;
        const totalProgress = Math.round((uploadedChunks / chunks) * 100);
        onProgress(totalProgress, file.name);
      })
      .catch(error => {
        console.error(`Error uploading part ${i + 1} of ${fileName}:`, error);
        return { parts, error: `Error uploading part ${i + 1} of ${fileName}` }; // Return error for this part
      });

    queue.push(uploadPromise);

    if (queue.length >= MAX_PARALLEL_UPLOADS) {
      await Promise.all(queue.splice(0, MAX_PARALLEL_UPLOADS));
    }
  }

  await Promise.all(queue);

  return { parts };
};

const completeMultipartUpload = async (fileName, uploadId, parts) => {
  try {
    const response = await axios.put(`${APIURL}/v2/core/s3-multipart-upload/`, {
      upload_id: uploadId,
      file_name: fileName,
      parts,
    });
    return response.data.location;
  } catch (error) {
    console.error('Error completing multipart upload:', error);
    throw new Error(`Failed to complete multipart upload for file: ${fileName}`);
  }
};

export const uploadFilesToS3 = async (files, onProgress = () => {}, onAbort = () => true, isPaused = () => false) => {
  const uploadQueue = [];
  const results = [];

  for (const file of files) {
    if (uploadQueue.length >= MAX_CONCURRENT_FILES) {
      await Promise.all(uploadQueue.splice(0, MAX_CONCURRENT_FILES));
    }

    const uploadPromise = (async () => {
      try {
        const { upload_id: uploadId, file_name: fileName } = await startMultipartUpload(file.name);

        const { parts, error: uploadPartsError } = await uploadParts(file, uploadId, fileName, (progress) => {
          file.progress = progress;
          file.error = null;
          file.url = null;
          onProgress(file);
        }, onAbort, isPaused);

        if (uploadPartsError) {
          file.progress = 0;
          file.error = uploadPartsError;
          file.url = null;
          onProgress(file);
          return file;
        }

        const fileUrl = await completeMultipartUpload(fileName, uploadId, parts);

        file.progress = 100;
        file.error = null;
        file.url = fileUrl;
        onProgress(file); // Send success progress
        return file;

      } catch (error) {
        file.progress = 0; // Устанавливаем прогресс в 0 в случае ошибки
        file.error = error.message || 'Unknown error'; // Добавляем сообщение об ошибке
        file.url = null; // URL остается null, если произошла ошибка
        onProgress(file); // Send error progress
        return file; // Return error status
      }
    })();

    uploadQueue.push(uploadPromise);
    results.push(uploadPromise);
  }

  // Wait for all uploads to finish
  const allResults = await Promise.all(results);

  // Return all results, including those with errors
  return allResults;
};