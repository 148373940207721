import { createStore } from "vuex";
import axios from "@/plugins/axios";
import { getAddr, getProtocol } from "../utils/url";

export default createStore({
  strict: process.env.NODE_ENV !== "production", //todo: сделать ограничение полинтервала в компоненте
  state: {
    parlors: [],
    isLoading: false,
    masters: [],
    prices: [],
    session_prices: [],
    find_outs: [],
    social_media: {
      sales_phone_number: null,
      facebook_url: null,
      instagram_url: null,
      youtube_url: null,
      twitter_url: null,
      pinterest_url: null,
      tiktok_url: null,
    },
    parlor_social_media: {
      id: null,
      name: null,
      instagram_url: null,
      facebook_url: null,
      google_maps_url: null,
      email: null,
      phone_number: null,

    },
    find_out: null,
    parlor: null,
    master: null,
    type: null,
    location: null,
    datetime: null,
    customer_note: null,
    sketch: null,
    vip_gift: null,
    session_id: null,
    customer_bonus: null,
    created_by: null,
    customer: {
      id: null,
      phone_number: "",
      code: null,
      verification_code: "",
      full_name: "",
      find_out: "",
      email: "",
      birth_date: "",
    },

    questionary: JSON.parse(localStorage.getItem('questionary')) || {},

    site_links: null,

    training: {
      active_page_index: null,
    },
    userId: null,
  },
  mutations: {
    setUserId(state, payload) {
      state.userId = payload;
    },
    setSiteLinks(state, payload) {
      state.site_links = payload;
    },
    setActivePageIndex(state, payload) {
      state.training.active_page_index = payload;
    },
    setCustomerData(state, payload) {
      state.customer = { ...state.customer, ...payload };
    },
    setQuestionaryData(state, payload) {
      state.questionary = { ...state.questionary, ...payload };
      localStorage.setItem('questionary', JSON.stringify(state.questionary));
    },
    clearQuestionaryData(state) {
      state.questionary = {};
    },
    setCustomerBonus(state, payload) {
      state.customer_bonus = payload;
    },
    setCustomerNote(state, payload) {
      state.customer_note = payload;
    },
    setSessionId(state, payload) {
      state.session_id = payload;
    },
    setParlorsData(state, payload) {
      state.parlors = payload;
    },
    setMastersData(state, payload) {
      state.masters = payload;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    setPriceList(state, payload) {
      state.prices = payload;
    },
    setSketch(state, payload) {
      state.sketch = payload;
    },
    setParlor(state, payload) {
      if (!state.parlor || state.parlor.id !== payload.parlor.id) {
        state.parlor = payload.parlor;
        state.master = null;
        state.type = null;
        state.datetime = null;
      }
    },
    setMaster(state, payload) {
      if (!state.master || state.master.id !== payload.master.id) {
        state.master = payload.master;
        state.type = null;
        state.datetime = null;
      }
    },
    setType(state, payload) {
      if (!state.type || state.type.id !== payload.type.id) {
        state.type = payload.type;
        state.location = null;
      }
    },
    setLocation(state, payload) {
      state.location = payload.location;
    },
    setSocialMedia(state, payload) {
      state.social_media = payload;
    },
    setParlorSocialMedia(state, payload) {
      if (payload === null) {
        for (const key of Object.keys(state.parlor_social_media)) {
          state.parlor_social_media[key] = null;
        }
      } else {
        state.parlor_social_media = payload;
      }
    },
    setDateTime(state, payload) {
      state.datetime = payload.datetime;
    },
    setSessionPrices(state, payload) {
      state.session_prices = payload;
    },
    setFindOuts(state, payload) {
      state.find_outs = payload;
    },
    setFindOut(state, payload) {
      state.find_out = payload;
    },
    setCreatedBy(state, payload) {
      state.created_by = payload;
    },
  },
  actions: {
    async fetchParlors({ dispatch, commit }, payload) {
      const response = await axios.get(
        `${getProtocol()}://${getAddr()}/api/v3/parlors/`,
      );
      await commit("setParlorsData", response.data);
      if (payload.parlor) {
        const parlor =
          response.data.find((el) => el.id === payload.parlor) || null;
        await commit("setParlor", { parlor });
        const masters = await dispatch("fetchMasters", { parlor });
        if (payload.master) {
          const master =
            masters.find((el) => el.user.id === payload.master) || null;
          await commit("setMaster", { master });
        }
      }
    },
    async fetchSiteLinks({ commit }) {
      const response = await axios.get(
        `${getProtocol()}://${getAddr()}/api/v2/core/site-parlor-page/`,
      );
      await commit("setSiteLinks", response.data.results[0]);
    },
    async fetchVipGift({ commit }) {
      const response = await axios.get(
        `${getProtocol()}://${getAddr()}/api/v1/core/customer-bonus/`,
      );
      await commit("setCustomerBonus", response.data.results[0].customer_bonus);
    },
    async fetchMasters({ commit }, payload) {
      const response = await axios.get(
        `${getProtocol()}://${getAddr()}/api/v3/parlors/${payload.parlor?.id}/`,
      );
      await commit("setMastersData", response.data.profiles);
      await commit("setPriceList", response.data.price_list);
      return response.data.profiles;
    },
    async fetchSessionPrices({ commit }, payload) {
      await commit("setMaster", payload);
      const response = await axios.get(
        `${getProtocol()}://${getAddr()}/api/v3/prices/${
          payload.master.user.id
        }/`,
      );
      await commit("setSessionPrices", response.data);
      return response.data;
    },
    async fetchSocialMedia({ commit }) {
      const response = await axios.get(
        `${getProtocol()}://${getAddr()}/api/v1/core/social-media/`,
      );
      await commit("setSocialMedia", response.data.results[0]);
      return response.data;
    },
    async fetchParlorSocialMedia({ commit }, payload) {
      if (payload.parlorId) {
        const response = await axios.get(
          `${getProtocol()}://${getAddr()}/api/v2/prices/parlors/?parlor_id=${payload.parlorId}`,
        );
        commit("setParlorSocialMedia", response.data[0].parlor);
        return response.data[0].parlor;
      } else {
        return null
      }
    },
    async fetchFindOuts({ commit }) {
      const response = await axios.get(
        `${getProtocol()}://${getAddr()}/api/v1/customers/find-out/`,
      );
      await commit("setFindOuts", response.data.results);
      return response.data;
    },
  },
  modules: {},
});
